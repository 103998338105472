import { Component, OnInit, Input } from '@angular/core';
import { Module } from 'src/app/Models/module';
import { ApiService } from 'src/app/Service/api.service';
import { NzNotificationService } from 'ng-zorro-antd';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-module',
  templateUrl: './module.component.html',
  styleUrls: ['./module.component.css'],
  providers: [DatePipe]
})
export class ModuleComponent implements OnInit {

  @Input() drawerClose: Function;
  @Input() data: Module;
  isSpinning = false
  fileData:File=null
  imgUrl
  fKey=""
  date = new Date();
  date1 = this.datePipe.transform(this.date, 'yyyyMMddHHmmss')

  constructor(private api: ApiService,private datePipe: DatePipe, private message: NzNotificationService) {
  }

  ngOnInit() {
  }
  close(): void {
    this.drawerClose();
  }

  save(addNew: boolean): void {
    this.isSpinning = true;
    if(this.data.NAME!=undefined && this.data.NAME!="")
    {
    if (this.data.ID) {

      if(this.data.LOGO_URL=="")
      {
          var number= Math.floor(100000 + Math.random() * 900000)
          var fileExt = this.fileData.name.split('.').pop();
          url=this.date1+number+"."+fileExt
          this.api.onUpload("moduleIcon",this.fileData,url)
          this.data.LOGO_URL=this.api.retriveimgUrl+"moduleIcon/"+url;
      }
      if(this.imgUrl!=undefined)
      {
      var str=this.data.LOGO_URL.substring(this.data.LOGO_URL.lastIndexOf('/')+1).split('.')
      var fileExt = this.fileData.name.split('.').pop();
      var url=str[0]+"."+fileExt;
      console.log("filedata : "+this.fileData)
      this.api.onUpload("moduleIcon",this.fileData,url)
      this.data.LOGO_URL=this.api.retriveimgUrl+"moduleIcon/"+url;
      } 
      this.api.updateModule(this.data)
        .subscribe(successCode => {
          if (successCode['code'] == "200") {
            this.message.success("Module information updated Successfully...", "");
            if (!addNew)
              this.drawerClose();
            this.isSpinning = false;
          }
          else {
            this.message.error("Failed to update module information...", "");
            this.isSpinning = false;
          }
        });
    }
    else {
      if(this.imgUrl!=undefined)
      {
      var number= Math.floor(100000 + Math.random() * 900000)
      var fileExt = this.fileData.name.split('.').pop();
      url=this.date1+number+"."+fileExt
      this.api.onUpload("moduleIcon",this.fileData,url)
      this.data.LOGO_URL=this.api.retriveimgUrl+"moduleIcon/"+url;
    }
    else
    {
      this.data.LOGO_URL="";
    }

     
      this.api.createModule(this.data)
        .subscribe(successCode => {
          if (successCode['code'] == "200") {
            this.message.success("Module information added successfully...", "");
            if (!addNew)
              this.drawerClose();
            else {
              this.data = new Module();
            }
            this.isSpinning = false;
          }
          else {
            this.message.error("Failed to add module information...", "");
            this.isSpinning = false;
          }
        });
    }
  }
  else
  {
    this.message.error("Please Fill All Required Fields...","");
    this.isSpinning = false;
  }
  }



  onFileSelectedLOGO_URL(event)
  {
    this.imgUrl=event.target.value
    this.fileData=<File>event.target.files[0];
    console.log(this.fileData)
    var fileExt = this.fileData.name.split('.').pop();
    console.log("extesion: "+fileExt) 
  }

}