import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sendnotifications',
  templateUrl: './sendnotifications.component.html',
  styleUrls: ['./sendnotifications.component.css']
})
export class SendnotificationsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
