import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/Models/user';
import { Role } from 'src/app/Models/role';
import { ApiService } from 'src/app/Service/api.service';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-userrolemapping',
  templateUrl: './userrolemapping.component.html',
  styleUrls: ['./userrolemapping.component.css']
})
export class UserrolemappingComponent implements OnInit {

  formTitle="Assign"
  roleLoading=false
  userLoading=false
  filterRoleValue
  isSpinning=false
  users: User[];
  roles: Role[];
  values
  USER_ID
  applicationId=this.cookie.get('applicationId')
  moduleId=this.cookie.get('moduleId')
  roleId=this.cookie.get('roleId')
  userId = this.cookie.get('userId')
  STATUS=true
  constructor(private api: ApiService,private cookie:CookieService,private message:NzNotificationService) { }

  ngOnInit() {
    this.loadData()
      }
    
      loadData()
      {
        console.log(this.roleId)
        this.roleLoading = true;
        this.userLoading=true
        var filterQuery="AND PARENT_ROLE_ID="+this.roleId
        this.api.getAllRoles(0, 0, '', '', filterQuery).subscribe(roles => {
          this.roles = roles['data'];
       
          this.roleLoading = false;
        }, err => {
          console.log(err);
          this.roleLoading = false;
        });
    
        var userFilterQuery = "AND ID NOT IN ( " + this.userId + " )"
        this.api.getAllUsers(0, 0, '', '', userFilterQuery).subscribe(users => {
          this.users = users['data'];
          this.USER_ID = users['data'][0]['ID']
          this.userLoading = false;
        }, err => {
          console.log(err);
          this.userLoading = false;
        });
      }

      save()
      {
        this.isSpinning = true;
        var userModuleMapping = []
        console.log(this.filterRoleValue)
        this.filterRoleValue.map((item) => {
          var v = { "USER_ID": this.USER_ID, "ROLE_ID": item, "APPLICATION_ID": this.applicationId, "DEPARTMENT_ID": 0, "MODULE_ID": this.moduleId, "STATUS": this.STATUS, "CLIENT_ID": this.api.clientId }
          userModuleMapping.push(v)
        })
        console.log(this.filterRoleValue)
        this.api.AssignApplicationsData(userModuleMapping)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
              this.message.success("Application Assigned Successfully ...", "");
              this.isSpinning = false;
            }
            else {
              this.message.error("Failed to Assign Applications...", "");
              this.isSpinning = false;
            }
          });
      }

      userSelectChange(userId:number)
      {
        var filterQuery = " AND USER_ID=" + userId + " "
        this.api.getAllUserAccessMapping(0, 0, '', '', filterQuery).subscribe(data => {
          console.log(data['data'])
          this.values = ""
          data['data'].forEach(element => {
            this.values = element['ROLE_ID'] + "," + this.values
          });
      
          if (this.values.match("undefined")) {
            this.values = this.values.substring(0, this.values.length - 10)
            console.log(this.values)
          }
          else {
            this.values = this.values.substring(0, this.values.length - 1)
            console.log(this.values)
          }
          if (this.values != []) {
            var roleIds = this.values.split(',').map(function (item) {
              return parseInt(item, 10);
            });
          }
          this.filterRoleValue = roleIds
          if (this.filterRoleValue == [NaN]) {
            this.filterRoleValue = undefined
          }
        }, err => {
          console.log(err);
        });
      }

}
