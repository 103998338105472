import { Component, OnInit,Input } from '@angular/core';
import { Role } from 'src/app/Models/role';
import { ApiService } from 'src/app/Service/api.service';
import { NzNotificationService } from 'ng-zorro-antd';

@Component({
  selector: 'app-rolemodulemapping',
  templateUrl: './rolemodulemapping.component.html',
  styleUrls: ['./rolemodulemapping.component.css']
})
export class RolemodulemappingComponent implements OnInit {

  @Input() drawerClose: Function;
  @Input() data: Role;
  @Input() roleModuleData: string[];
  @Input() drawerVisible: boolean;

  isSpinning = false
  formTitle = "Map Modules";
  loadingRecords = true;
  
  constructor(private api:ApiService,private message:NzNotificationService) { }

  ngOnInit() {
  }

  close(): void {
    this.drawerClose();
  }

  save()
  {
    this.isSpinning = true;
    
    console.log(this.roleModuleData);
      this.api.addRoleModuleDetails(this.data.ID,this.roleModuleData)
      .subscribe(successCode => {
        if(successCode['code']=="200")
        { 
            this.message.success("Role Modules added Successfully ...", "");
            this.drawerClose();
            this.isSpinning = false;
        }
        else
        {
          this.message.error("Role Modules assigning Failed...", "");
          this.isSpinning = false;
        }
      });
  }
}
