import { Component, OnInit,Input,ViewChild } from '@angular/core';
import { ApiService } from 'src/app/Service/api.service';
import { Role } from 'src/app/Models/role';
import { CookieService } from 'ngx-cookie-service';
import { User } from 'src/app/Models/user';
import { Application } from 'src/app/Models/application';
import { NzNotificationService } from 'ng-zorro-antd';
import { Roledetails } from 'src/app/Models/roledetails';
import {AppComponent} from '../../../../app.component'

@Component({
  selector: 'app-userapplicationmapping',
  templateUrl: './userapplicationmapping.component.html',
  styleUrls: ['./userapplicationmapping.component.css']
})
export class UserapplicationmappingComponent implements OnInit {


  formTitle = "Assign Applications"
  roleLoading = false
  userLoading = false
  applicationLoading = false
  isSpinning = false
  applications: Application[];
  values
  applicationIds: number[]
  users: User[];
  roles: Role[];
  filterApplicationValue
  USER_ID: number
  ROLE_ID: number
  STATUS = true
  roleId = this.cookie.get('roleId')
  userId = this.cookie.get('userId')


  constructor(private api: ApiService, private cookie: CookieService, private message: NzNotificationService) { }

  ngOnInit() {
    this.loadData()
  }



  loadData() {
    console.log(this.roleId)
    this.roleLoading = true;
    this.userLoading = true
    this.applicationLoading = true
    var roleFilterQuery = "AND PARENT_ROLE_ID=" + this.roleId
    this.api.getAllRoles(0, 0, '', '', roleFilterQuery).subscribe(roles => {
      console.log(roles['data'][0]['ID'])
      this.ROLE_ID = roles['data'][0]['ID']
      console.log("Role ID:" + this.ROLE_ID)
      this.roles = roles['data'];
      this.roleLoading = false;
    }, err => {
      console.log(err);
      this.roleLoading = false;
    });


    var userFilterQuery = "AND ID NOT IN ( " + this.userId + " )"
    this.api.getAllUsers(0, 0, '', '', userFilterQuery).subscribe(users => {
      console.log("USER_ID:" + users['data'][0]['ID'])
      this.users = users['data'];
      this.USER_ID = users['data'][0]['ID']
      this.userLoading = false;
      
      if(this.USER_ID)
      this.userSelectChange(this.USER_ID)
    }, err => {
      console.log(err);
      this.userLoading = false;
    });
    this.api.getApplicationsAssigned(0, 0).subscribe(applications => {
      this.applications = applications['data'];
      this.applicationLoading = false;
    }, err => {
      console.log(err);
      this.applicationLoading = false;
    });
  }


  save() {

    this.isSpinning = true;
    var userApplicationMapping = []
    this.filterApplicationValue.map((item) => {
      var v = { "USER_ID": this.USER_ID, "ROLE_ID": this.ROLE_ID, "APPLICATION_ID": item, "DEPARTMENT_ID": 0, "MODULE_ID": 0, "STATUS": this.STATUS, "CLIENT_ID": this.api.clientId }
      userApplicationMapping.push(v)
    })
    this.api.AssignApplicationsData(userApplicationMapping)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          this.message.success("Application Assigned Successfully ...", "");
          this.isSpinning = false;
        }
        else {
          this.message.error("Failed to Assign Applications...", "");
          this.isSpinning = false;
        }
      });
  }

  userSelectChange(userId: number) {
    var filterQuery = " AND ROLE_ID=" + this.ROLE_ID + " AND USER_ID=" + userId + " "
    this.api.getAllUserAccessMapping(0, 0, '', '', filterQuery).subscribe(data => {
      console.log(data['data'])
      this.values = ""
      data['data'].forEach(element => {
        this.values = element['APPLICATION_ID'] + "," + this.values
      });

      if (this.values.match("undefined")) {
        this.values = this.values.substring(0, this.values.length - 10)
        console.log(this.values)
      }
      else {
        this.values = this.values.substring(0, this.values.length - 1)
        console.log(this.values)
      }
      if (this.values != []) {
        var applicationIds = this.values.split(',').map(function (item) {
          return parseInt(item, 10);
        });
      }
      this.filterApplicationValue = applicationIds
      if (this.filterApplicationValue == [NaN]) {
        this.filterApplicationValue = undefined
      }

    }, err => {
      console.log(err);
    });

    // this.api.getApplicationsAssigned(this.ROLE_ID,userId).subscribe(data => {
    //   console.log(data['data'])
    //   data['data'].forEach(element => {
    //    this.values = this.values + element['APPLICATION_ID'] + ","
    //   });

    //   console.log(this.values.substring(4,this.values.length-1))
    //   this.values=this.values.substring(4,this.values.length-1)

    //   this.applicationIds= this.values.split(",");
    //   console.log(this.applicationIds)
    //  // this.filterApplicationValue= this.applicationIds;
    //   }, err => {
    //   console.log(err);
    // });
  }


}
