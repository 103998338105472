import { Component, OnInit } from '@angular/core';
import { Application } from 'src/app/Models/application';
import { ApiService } from 'src/app/Service/api.service';
import { NzNotificationService } from 'ng-zorro-antd';

@Component({
  selector: 'app-applications',
  templateUrl: './applications.component.html',
  styleUrls: ['./applications.component.css']
})
export class ApplicationsComponent implements OnInit {


  formTitle = "Manage Applications";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] =[ ["NAME","Name"],["ABBREVIATION","Abbrevation"],["DESCRIPTION","Description"],["APPLICATION_KEY","Application Key"],["API_KEY","Api Key"],["LOGO_URL","Url"],["IS_ACTIVE","Status"]]
  mappedCount=0
  unmappedCount=0
  imageurl:any
  //drawer Variables
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: Application = new Application();

  drawerVisible1: boolean;
  drawerTitle1: string;
  drawerData1: Application = new Application();

  drawerData2: string[];
  constructor(private api: ApiService,private message: NzNotificationService) { }
  ngOnInit() {
    this.search();
  }
  // Basic Methods
  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }
  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }
    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }
    console.log("search text:" + this.searchText);
    if (this.searchText != "") {
      var likeQuery = " AND";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
      console.log("likeQuery" + likeQuery);
    }
    this.api.getAllApplications(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery).subscribe(data => {
     console.log(data)
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];
    }, err => {
      console.log(err);
      if(err['ok']==false)
      this.message.error("Server Not Found", "");
    });
  }
  //Drawer Methods
  get closeCallback() {
    return this.drawerClose.bind(this);
  }
  get closeCallback1() {
    return this.drawerClose1.bind(this);
  }
  add(): void {
    this.drawerTitle = "Create New Application";
    this.drawerData = new  Application();
    this.drawerData.STATUS=true
    this.drawerData.DESCRIPTION="No Description"
    this.drawerVisible = true;
  }
  edit(data: Application): void {
    let TYPED_ARRAY
    console.log(data.LOGO_URL)
    this.api.getFile(data.LOGO_URL).subscribe(data => {
      console.log(data);
    TYPED_ARRAY = new Uint8Array(data['data']['FILE_DATA']['data']);
    const STRING_CHAR = TYPED_ARRAY.reduce((data, byte)=> {
      return data + String.fromCharCode(byte);
      }, '');
      let base64String = btoa(STRING_CHAR);
      this.imageurl = 'data:image/jpg;base64,' + base64String;
      localStorage.setItem('URL',this.imageurl);
      }, err => {
      console.log(err);
    });

    this.drawerTitle = "Update Application";
    data.LOGO_URL=localStorage.getItem('URL')
    this.drawerData = Object.assign({}, data);
  
    this.drawerVisible = true;
  }

  MapModules(data:Application)
{
  
  this.api.getMappingModules(data.ID).subscribe(data => {
    console.log(data);
    data['data'].forEach(element => {
      if(element['IS_SELECTED']==true)
      this.mappedCount++
      else
      this.unmappedCount++
    });
  
console.log("mapped count " + this.mappedCount)
console.log("unmapped count " + this.unmappedCount)
   this.drawerData2 =data['data'];
    }, err => {
    console.log(err);
  });
  this.drawerTitle1 = "Map Modules for " + data.NAME + "";
  this.drawerData1 = Object.assign({}, data);
  this.drawerVisible1 = true;
}
  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }

  drawerClose1(): void {
    this.drawerVisible1 = false;
  }
}

