export class Emailhistory {
    ID:number;
    CLIENT_ID:number;
    EMAIL_SERVER_ID:number;
    TO:string;
    SUBJECT:string;
    BODY:string;
    ATTACHMENT_COUNT:number;
    STATUS:string;
    UNIQUE_KEY:string;
    PREVIOUS_KEY:string;
    SCHEDULED_DATE_TIME:Date;
    SENDER_APPLICATION_REFERENCE_ID:number;
    
}
