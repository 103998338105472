import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/Service/api.service';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';

interface DataItem {
  LOG_TEXT:string
  LOG_TIME:string
}

@Component({
  selector: 'app-logmodule',
  templateUrl: './logmodule.component.html',
  styleUrls: ['./logmodule.component.css']
})
export class LogmoduleComponent implements OnInit {

  formTitle = "View Log History";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList : DataItem[];
  isSpinning=false
  loadingRecords = false;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  filterTypeValue:string[]=['AL']
  SUPPORT_KEY:string
  //columns: string[][] =[ ["USER_NAME","User"],["MOBILE_NO","Mobile No"],["EMAIL_ID","Email Id"],["LOG_TYPE","Log Type"],["MESSAGE","Message"]]
  applicationId=Number(this.cookie.get('applicationId'))
  //drawer Variables
  date=new Date().toLocaleDateString() +" "+ new Date().toLocaleTimeString()
  ViewofData:string="E"
  placeholder:string=""
 
  constructor(private api: ApiService,private cookie:CookieService,private message:NzNotificationService) { }
  ngOnInit() {
    this.search();
  }

 
  // Basic Methods
  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }
  search(reset: boolean = false) {
    // console.log("api callll")
    // if (reset) {
    //   this.pageIndex = 1;
    // }
    // //this.loadingRecords = true;
    // var sort: string;
    // try {
    //   sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    // } catch (error) {
    //   sort = "";
    // }
    // console.log("search text:" + this.searchText);
    // if (this.searchText != "") {
    //   var likeQuery = " AND";
    //   this.columns.forEach(column => {
    //     likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
    //   });
    //   likeQuery = likeQuery.substring(0, likeQuery.length - 2)
    //   console.log("likeQuery" + likeQuery);
    //   this.api.getAllLogUserHistory(this.SUPPORT_KEY,this.applicationId).subscribe(data => {
    //     console.log(data)
    //     this.loadingRecords = false;
    //     this.totalRecords = data['count'];
    //     this.dataList = data['data'];
    //   }, err => {
    //     console.log(err);
    //   });
    // }
  
  }

  isValidEmail(email)
    {
      
        const expression = /^[_a-zA-Z0-9]+(\.[_a-zA-Z0-9]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,4})$/;
        return expression.test(String(email).toLowerCase())
    }

  applyFilter()
  {
    
    this.isSpinning=true
    this.loadingRecords=true
    if(this.SUPPORT_KEY==""||this.filterTypeValue==undefined || this.SUPPORT_KEY==undefined)
    {
      this.message.error("Please Select Fields", "");
    }
    else
    {
      console.log(this.ViewofData)
        this.api.getAllLogUserHistory(this.ViewofData,this.SUPPORT_KEY,this.applicationId).subscribe(data => {
          console.log(data)
          if(data['data'])
          this.dataList = data['data'].sort(this.sortFunction).reverse();
         
          this.isSpinning=false
          this.loadingRecords=false
        }, err => {
          console.log(err);
          this.isSpinning=false
        });
    }
  }

  getTime(text)
  {
    
    return text.substring(text.length-27)
  }


  getText(text)
  {
    return text.substring(0,text.length-27)
  }

  sortByKeyDesc(array, key) {
    return array.sort(function (a, b) {
        var x = a[key]; var y = b[key];
        return ((x > y) ? -1 : ((x < y) ? 1 : 0));
    });
}

sortFunction(a,b){  
  var dateA = new Date(a.LOG_TIME)
  var dateB = new Date(b.LOG_TIME)
  return dateA > dateB ? 1 : -1;  
};
 
}
