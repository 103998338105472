import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { Ticket } from 'src/app/Models/ticket';
import { ApiService } from 'src/app/Service/api.service';
import { ExportService } from 'src/app/Service/export.service';
import * as XLSX from 'xlsx';
import { ChattdetailsicketComponent } from '../chattdetailsicket/chattdetailsicket.component';
import { differenceInCalendarDays, setHours } from 'date-fns';

@Component({
  selector: 'app-support-user-wise-ticket-details',
  templateUrl: './support-user-wise-ticket-details.component.html',
  styleUrls: ['./support-user-wise-ticket-details.component.css']
})

export class SupportUserWiseTicketDetailsComponent implements OnInit {
  formTitle = "Employee Wise Details";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  dataListForExport = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] = [["CREATOR_EMPLOYEE_NAME", "Employee Name (Created By)"], ["TICKET_NO", "Ticket No."], ["DATE", "Created On"], ["QUESTION", "Question"], ["IS_TAKEN_STATUS", "Is Taken"], ["TICKET_TAKEN_EMPLOYEE", "Taken By/ Transfer To"], ["LAST_RESPONDED", "Last Responded Date"], ['PRIORITY', 'Priority'], ["STATUS", "Status"]];
  SUPPORT_USERS = [];
  supportUsers = [];
  SUPPORT_AGENTS = [];
  supportAgents = [];
  STATUS = [];
  CREATION_DATE: Date[] = [];
  isSpinning = false;
  filterClass: string = "filter-visible";
  applicationId = Number(this.cookie.get('applicationId'));
  departmentId = Number(this.cookie.get('departmentId'));
  dateFormat = 'dd/MM/yyyy';
  data1 = [];
  index = 0;
  ticketQuestion = {};
  fileName = 'Tickets.xlsx';
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: Ticket = new Ticket();
  uniqueDateArry = [];
  newData2 = [];
  userId = Number(this.cookie.get('userId'));
  roleId = Number(this.cookie.get('roleId'));
  orgId = Number(this.cookie.get('orgId'));
  deptId = Number(this.cookie.get('deptId'));
  branchId = Number(this.cookie.get('branchId'));
  designationId = Number(this.cookie.get('designationId'));
  CREATION_DATE1 = new Date();
  CREATION_DATE2 = new Date();
  today = new Date();
  orgName: string = this.api.ORGANIZATION_NAME;

  constructor(private api: ApiService, private datePipe: DatePipe, private cookie: CookieService, private _exportService: ExportService, private message: NzNotificationService) { }

  disabledToDate = (current: Date): boolean =>
    differenceInCalendarDays(current, this.CREATION_DATE1 == null ? this.today : this.CREATION_DATE1) < 0;

  onFromDateChange(fromDate) {
    if (fromDate == null)
      this.CREATION_DATE2 = new Date();

    else
      this.CREATION_DATE2 = new Date(fromDate);
  }

  setDateForDeptWiseFilter() {
    this.CREATION_DATE = [];
    let currentDate = new Date();
    let previous6thDayDate = currentDate.setDate(currentDate.getDate() + (-6));
    this.CREATION_DATE1 = new Date(previous6thDayDate);
    this.CREATION_DATE2 = new Date();
  }

  ngOnInit() {
    this.setDateForDeptWiseFilter();
    this.getSupportUsers();
    this.getSupportAgents();

    if (this.roleId == 6)
      this.getDepartmentToShowReport();

    if (this.roleId == 4)
      this.getDepartmentSupportAgentWise();

    if ((this.roleId != 4) && (this.roleId != 6))
      this.search(true);

    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
  }

  getSupportUsers() {
    this.supportUsers = [];

    this.api.getAllemployeeMaster(0, 0, 'NAME', 'asc', ' AND ORG_ID=' + this.cookie.get('orgId') + ' AND ID!=1').subscribe(data => {
      if (data['code'] == 200) {
        this.supportUsers = data['data'];
      }

    }, err => {
      console.log(err);
    });
  }

  getSupportAgents() {
    this.supportAgents = [];

    this.api.getAllemployeeMaster(0, 0, 'NAME', 'asc', ' AND ID!=1 AND ID IN (SELECT EMPLOYEE_ID from view_employee_role_mapping where ORG_ID=' + this.cookie.get('orgId') + ' AND ROLE_ID=4)').subscribe(data => {
      if (data['code'] == 200) {
        this.supportAgents = data['data'];
      }

    }, err => {
      console.log(err);
    });
  }

  supportAgentWiseDeptArray = [];

  getDepartmentSupportAgentWise() {
    this.supportAgentWiseDeptArray = [];

    this.api.gettickdeskSupportUserMapping(0, 0, 'ID', "ASC", ' AND EMPLOYEE_ID=' + this.userId).subscribe(data => {
      if (data['code'] == 200) {
        var supportAgentWiseDept = data['data'];
        console.log(supportAgentWiseDept);

        for (var i = 0; i < supportAgentWiseDept.length; i++) {
          this.supportAgentWiseDeptArray.push(supportAgentWiseDept[i]['DEPARTMENT_ID']);
        }

        if (this.roleId == 4) {
          this.search(true);
        }
      }
    });
  }

  deptWiseReport = [];

  getDepartmentToShowReport() {
    this.deptWiseReport = [];

    this.api.gettickdeskDepartmentAdminMapping(0, 0, 'ID', "ASC", ' AND EMPLOYEE_ID=' + this.userId).subscribe(data => {
      if (data['code'] == 200) {
        var departments = data['data'];
        console.log(departments);

        for (var i = 0; i < departments.length; i++) {
          this.deptWiseReport.push(departments[i]['DEPARTMENT_ID']);
        }

        if (this.roleId == 6) {
          this.search(true);
        }
      }
    });
  }

  exportexcel(): void {
    let element = document.getElementById('summer');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, this.fileName);
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }

  search(reset: boolean = false, exportToExcel: boolean = false, exportToPDF: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    if (this.searchText != "") {
      likeQuery = " AND (";

      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ')';
    }

    var supportAgentWiseDept = "";
    if (this.roleId == 4) {
      if (this.supportAgentWiseDeptArray.length > 0)
        supportAgentWiseDept = " AND DEPARTMENT_ID IN (" + this.supportAgentWiseDeptArray + ")";

      else
        supportAgentWiseDept = "";
    }

    var deptAdminWiseDept = "";
    if (this.roleId == 6) {
      if (this.deptWiseReport.length > 0)
        deptAdminWiseDept = " AND DEPARTMENT_ID IN (" + this.deptWiseReport + ")";

      else
        deptAdminWiseDept = "";
    }

    var creationDateFilter = "";
    if ((this.CREATION_DATE1 != undefined) && (this.CREATION_DATE2 != undefined)) {
      creationDateFilter = " AND (DATE BETWEEN '" + this.datePipe.transform(this.CREATION_DATE1, 'yyyy-MM-dd 00:00:00') + "' AND '" + this.datePipe.transform(this.CREATION_DATE2, 'yyyy-MM-dd 23:59:59') + "')";
    }

    var supportUserFilter = "";
    if (this.SUPPORT_USERS.length > 0)
      supportUserFilter = " AND CREATOR_EMPLOYEE_ID IN (" + this.SUPPORT_USERS + ")";

    var supportAgentFilter = "";
    if (this.SUPPORT_AGENTS.length > 0)
      supportAgentFilter = " AND TAKEN_BY_USER_ID IN (" + this.SUPPORT_AGENTS + ")";

    var statusFilter = "";
    if (this.STATUS.length > 0)
      statusFilter = " AND STATUS IN (" + this.STATUS + ")";

    if (exportToExcel) {
      this.exportLoading = true;

      this.api.getSupportAgentWiseTicketDetails(0, 0, this.sortKey, sort, likeQuery + creationDateFilter + supportUserFilter + supportAgentFilter + supportAgentWiseDept + deptAdminWiseDept + statusFilter + ' AND ORG_ID=' + this.cookie.get('orgId')).subscribe(data => {
        if (data['code'] == 200) {
          this.exportLoading = false;
          this.dataListForExport = data['data'];
          this.convertInExcel();
        }

      }, err => {
        if (err['ok'] == false)
          this.message.error("Server Not Found", "");
      });

    } else if (exportToPDF) {
      this.exportInPDFLoading = true;

      this.api.getSupportAgentWiseTicketDetails(0, 0, this.sortKey, sort, likeQuery + creationDateFilter + supportUserFilter + supportAgentFilter + supportAgentWiseDept + deptAdminWiseDept + statusFilter + ' AND ORG_ID=' + this.cookie.get('orgId')).subscribe(data => {
        if (data['code'] == 200) {
          this.exportInPDFLoading = false;
          this.dataListForExport = data['data'];
          this.isPDFModalVisible = true;
        }

      }, err => {
        if (err['ok'] == false)
          this.message.error("Server Not Found", "");
      });

    } else {
      this.loadingRecords = true;

      this.api.getSupportAgentWiseTicketDetails(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery + creationDateFilter + supportUserFilter + supportAgentFilter + supportAgentWiseDept + deptAdminWiseDept + statusFilter + ' AND ORG_ID=' + this.cookie.get('orgId')).subscribe(data => {
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.totalRecords = data['count'];
          this.dataList = data['data'];
        }

      }, err => {
        if (err['ok'] == false)
          this.message.error("Server Not Found", "");
      });
    }
  }

  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";

    else
      this.filterClass = "filter-visible";
  }

  applyFilter() {
    if (((this.CREATION_DATE1 != null) && (this.CREATION_DATE2 != null)) || (this.SUPPORT_USERS.length > 0) || (this.SUPPORT_AGENTS.length > 0))
      this.isFilterApplied = "primary";

    else
      this.isFilterApplied = "default";

    this.search(true);
    this.filterClass = "filter-invisible";
  }

  clearFilter() {
    this.SUPPORT_USERS = [];
    this.SUPPORT_AGENTS = [];
    this.filterQuery = "";
    this.CREATION_DATE = [];
    this.STATUS = [];
    this.setDateForDeptWiseFilter();
    this.search(true);
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
  }

  @ViewChild(ChattdetailsicketComponent, { static: false }) ChattdetailsicketComponentVar: ChattdetailsicketComponent;
  grpid = 0;
  bread = [];
  newstr: string;
  GRPNAME = '';

  viewTicketData(data: Ticket) {
    this.newData2 = [];
    this.data1 = [];
    this.ChattdetailsicketComponentVar.loading = true;
    this.drawerTitle = "Ticket No. " + data.TICKET_NO;
    this.drawerData = Object.assign({}, data);
    var filterQuery1 = "AND TICKET_MASTER_ID = " + data.ID + "";

    this.api.getAllTicketDetails(0, 0, "CREATED_MODIFIED_DATE", "asc", filterQuery1).subscribe(data => {
      if (data['code'] == 200) {
        this.ChattdetailsicketComponentVar.loading = false;
        this.totalRecords = data['count'];
        this.data1 = data['data'];
        this.grpid = this.data1[0]['TICKET_GROUP_ID'];

        // Getting Unique dates
        for (var i = 0; i < this.data1.length; i++) {
          this.uniqueDateArry.push(this.datePipe.transform(this.data1[i]['CREATED_MODIFIED_DATE'], "yyyy-MM-dd"));
        }

        this.uniqueDateArry = [...new Set(this.uniqueDateArry)];
        this.uniqueDateArry.sort();

        this.uniqueDateArry.forEach(d1 => {
          this.newData2.push({
            key: d1, data: this.data1.filter(data =>
              this.datePipe.transform(data.CREATED_MODIFIED_DATE, "yyyy-MM-dd") == d1
            )
          });
        });

        this.data1 = this.newData2;
        this.ChattdetailsicketComponentVar.scrollIntoViewFunction();

        this.api.getBreadInChat(0, 0, 'ID', 'desc', '', '', this.grpid).subscribe(data => {
          if (data['code'] == 200) {
            this.bread = data['data'];
            this.newstr = '';
            this.GRPNAME = '';

            for (var i = 0; i < this.bread.length; i++) {
              this.GRPNAME = this.GRPNAME + '>' + this.bread[i]['VALUE'];
              var str = this.GRPNAME;
              this.newstr = str.replace('>', '');
            }
          }

        }, err => {
          console.log(err);
        });
      }

    }, err => {
      console.log(err);
    });

    this.drawerVisible = true;
  }

  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  onEnterKeyPress() {
    document.getElementById("searchBtn").focus();
  }

  exportLoading: boolean = false;

  importInExcel() {
    this.search(true, true);
  }

  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();

    for (var i = 0; i < this.dataListForExport.length; i++) {
      obj1['Support User (Created By)'] = this.dataListForExport[i]['CREATOR_EMPLOYEE_NAME'];
      obj1['Ticket No.'] = this.dataListForExport[i]['TICKET_NO'];
      obj1['Created On'] = this.datePipe.transform(this.dataListForExport[i]['DATE'], 'dd MMM yyyy hh:mm a');
      obj1['Question'] = this.dataListForExport[i]['QUESTION'];
      obj1['Is Taken'] = this.dataListForExport[i]['IS_TAKEN_STATUS'];
      obj1['Taken By/ Transfer To'] = this.dataListForExport[i]['TICKET_TAKEN_EMPLOYEE'];
      obj1['Last Responded Date'] = this.datePipe.transform(this.dataListForExport[i]['LAST_RESPONDED'], 'dd MMM yyyy hh:mm a');
      obj1['Priority'] = this.getPriorityInFullForm(this.dataListForExport[i]['PRIORITY']);
      obj1['Status'] = this.getStatusInFullForm(this.dataListForExport[i]['STATUS']);

      arry1.push(Object.assign({}, obj1));

      if (i == this.dataListForExport.length - 1) {
        this._exportService.exportExcel(arry1, 'Support User Wise Ticket Details ' + this.datePipe.transform(new Date(), 'dd-MMM-yy, hh mm ss a'));
      }
    }
  }

  getStatusInFullForm(status) {
    if (status == "P")
      return "Pending";

    else if (status == "C")
      return "Closed";

    else if (status == "S")
      return "Assigned";

    else if (status == "R")
      return "Answered";

    else if (status == "O")
      return "Re - Open";

    else if (status == "B")
      return "Banned";

    else if (status == "H")
      return "On - Hold";
  }

  getPriorityInFullForm(status) {
    if (status == "V")
      return "Very High";

    else if (status == "H")
      return "High";

    else if (status == "M")
      return "Medium";

    else if (status == "L")
      return "Low";

    else if (status == "O")
      return "Very Low";
  }

  isPDFModalVisible: boolean = false;
  PDFModalTitle: string = "Export in PDF";
  exportInPDFLoading: boolean = false;
  employeeNameToPrint: string = "";
  supportAgentNameToPrint: string = "";
  statusToPrint: string = "";

  importInPDF(employeeID, supportAgentID, status) {
    this.employeeNameToPrint = "";
    this.supportAgentNameToPrint = "";
    this.statusToPrint = "";
    this.search(false, false, true);
    let tempEmployeeName = "";
    let tempSupportAgentName = "";
    let tempStatus = "";

    for (var i = 0; i < employeeID.length; i++) {
      let supportUser = this.supportUsers.filter(obj1 => {
        return obj1.ID == employeeID[i];
      });

      tempEmployeeName = tempEmployeeName + supportUser[0]["NAME"] + ", ";
    }

    for (var i = 0; i < supportAgentID.length; i++) {
      let supportAgent = this.supportAgents.filter(obj1 => {
        return obj1.ID == supportAgentID[i];
      });

      tempSupportAgentName = tempSupportAgentName + supportAgent[0]["NAME"] + ", ";
    }

    for (var i = 0; i < status.length; i++) {
      let a = "";
      if (status[i] == "'P'")
        a = "Pending";

      else if (status[i] == "'C'")
        a = "Closed";

      else if (status[i] == "'S'")
        a = "Assigned";

      else if (status[i] == "'R'")
        a = "Answered";

      else if (status[i] == "'O'")
        a = "Re-Open";

      else if (status[i] == "'B'")
        a = "Banned";

      else if (status[i] == "'H'")
        a = "On-Hold";

      tempStatus = tempStatus + a + ", ";
    }

    this.employeeNameToPrint = tempEmployeeName.substring(0, tempEmployeeName.length - 2);
    this.supportAgentNameToPrint = tempSupportAgentName.substring(0, tempSupportAgentName.length - 2);
    this.statusToPrint = tempStatus.substring(0, tempStatus.length - 2);
  }

  handlePDFModalCancel() {
    this.isPDFModalVisible = false;
  }

  getCurrentDateTime() {
    return new Date();
  }

  getUserName() {
    return this.api.userName;
  }

  getEmployeeToShow() {
    if (this.employeeNameToPrint == "")
      return "All";

    else
      return this.employeeNameToPrint;
  }

  getSupportAgentToShow() {
    if (this.supportAgentNameToPrint == "")
      return "All";

    else
      return this.supportAgentNameToPrint;
  }

  getStatusToShow() {
    if (this.statusToPrint == "")
      return "All";

    else
      return this.statusToPrint;
  }
}
