export class Folder {
    ID:number;
    CLIENT_ID:number;
    APPLICATION_ID:number;
    NAME:string;
    DESCRIPTION:string;
    F_KEY:string;
    FOLDER_URL_KEY:string;
    CREATOR_ID:number;
}
