import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/Models/user';
import { ApiService } from 'src/app/Service/api.service';
import { NzNotificationService } from 'ng-zorro-antd';
import { Useraccessmapping } from 'src/app/Models/useraccessmapping';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})

export class UsersComponent implements OnInit {
  formTitle = "Manage Users";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] = [["NAME", "Name"], ["EMAIL_ID", "Email"], ["WHATS_APP_NO", "Whats app No"], ["MOBILE", "Mobile"], ["PASSWORD", "Password"], ["IS_ACTIVE", "Is Active"]]

  drawerVisible1: boolean;
  drawerTitle1: string;
  drawerData1: User = new User();

  drawerData2: string[];
  //drawer Variables
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: User = new User();
  constructor(private api: ApiService, private message: NzNotificationService) { }

  ngOnInit() {
    this.search();
  }

  // Basic Methods
  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }
    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    console.log("search text:" + this.searchText);

    if (this.searchText != "") {
      var likeQuery = " AND";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
      console.log("likeQuery" + likeQuery);
    }

    this.api.getAllUsers(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery).subscribe(data => {
      console.log(data);
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];

    }, err => {
      console.log(err);
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  //Drawer Methods
  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  get closeCallback1() {
    return this.drawerClose1.bind(this);
  }

  add(): void {
    this.drawerTitle = "Create New User";
    this.drawerData = new User();
    this.drawerData.IS_ACTIVE = true
    this.drawerVisible = true;
  }

  // MapApplicationModules(data:User)
  // {
  //   this.api.getApplicationModuleMapping(data.ID).subscribe(data => {
  //     console.log(data);
  //    this.drawerData2 =data['data'];
  //     }, err => {
  //     console.log(err);
  //   });
  //   this.drawerTitle1 = "Map Application Modules for " + data.NAME + "";
  //   this.drawerData1 = Object.assign({}, data);
  //   this.drawerVisible1 = true;
  // }

  edit(data: User): void {
    this.drawerTitle = "Update User";
    this.drawerData = Object.assign({}, data);
    this.drawerVisible = true;
  }

  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }

  drawerClose1(): void {
    this.drawerVisible1 = false;
  }
}
