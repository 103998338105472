import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Ticketgroup } from 'src/app/Models/ticketgroup';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-faqresponsereport',
  templateUrl: './faqresponsereport.component.html',
  styleUrls: ['./faqresponsereport.component.css']
})
export class FaqresponsereportComponent implements OnInit {

  formTitle = "FAQ Response Report";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  userId = this.cookie.get('userId')
  roleId = this.cookie.get('roleId')
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  
  // columns: string[][] =[ ["USER_MOBILE","Mobile Number"],["USER_EMAIL_ID","Email ID"],["SUGGESTION","Suggestion"],["STATUS","Status"]]
  
  columns: string[][] = [["USER_MOBILE","Mobile Number"],["USER_EMAIL_ID","Email ID"],["SUGGESTION","Suggestion"],["STATUS","Status"]]

  QUESTION = '';
  HEAD = '';

  isSpinning = false
  filterClass: string = "filter-visible";
  applicationId = Number(this.cookie.get('applicationId'))
  departmentId = Number(this.cookie.get('departmentId'))

  selectedDate: Date[] = []
  dateFormat = 'dd/MM/yyyy';

  data1 = [];
  ticketGroups: Ticketgroup[] = []
  index = 0;
  ticketQuestion = {};
  value1: string = ""
  value2: string = ""
  questions = []
  heads = []
  FAQ_MASTER_ID:Number

  constructor(private api: ApiService, private datePipe: DatePipe, private cookie: CookieService) { }

  ngOnInit() {
    this.search();

    this.api.getAllFaqHeads(0, 0, '', 'ASC', 'AND STATUS = 1  AND ORG_ID= '+this.cookie.get('orgId')).subscribe(data => {
      this.heads = data['data'];
      }, err => {
        console.log(err);
      });

    
    // this.api.getAllFaqResponses(0,0,'NAME','ASC',' AND ORG_ID= '+this.cookie.get('orgId')).subscribe(data => {
    //   this.questions = data['data'];
    //   }, err => {
    //     console.log(err);
    //   });
    
  }

  getquestion(event){
      this.api.getAllFaqResponses(0, 0, '', 'ASC', 'AND FAQ_HEAD_ID='+event).subscribe(data => {
      this.questions = data['data'];
      }, err => {
        console.log(err);
      });

  }

 
  // Basic Methods
  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }


  clearFilter() { 
    this.QUESTION = '';
    this.filterQuery = ""
    this.selectedDate = null;
   
  
    this.value1 = ''
    this.value2 = ''
    this.filterClass = "filter-invisible";
    this.isFilterApplied='default'
    this.search()
    this.HEAD=''
    console.log(this.HEAD)
  }

  
  changeDate(value) {
    this.value1 = this.datePipe.transform(value[0], "yyyy-MM-dd")
    this.value2 = this.datePipe.transform(value[1], "yyyy-MM-dd");
  }
  search(reset: boolean = false) {

    if (reset) {
      this.pageIndex = 1;
    }
    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }
    console.log(sort)
    console.log("search text:" + this.searchText);
    if (this.searchText != "") {
      this.filterQuery += " AND (";
      var likeQuery = " ";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like ('%" + this.searchText + "%') OR";
      });
      this.filterQuery += likeQuery.substring(0, likeQuery.length - 3) + ")";
    }
// else
// {
//   this.filterQuery=""
//   this.applyFilter()
// }

    this.api.getAllFaqResponses(0, 0, this.sortKey, sort, this.filterQuery).subscribe(data => {
     console.log(data)
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];
     
    }, err => {
      console.log(err);
    });
  
  }

  
  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else
      this.filterClass = "filter-visible";

  }

  applyFilter()
{
//  this.FAQ_MASTER_ID=id
//   this.isSpinning=true
//   var sort: string;
//   try {
//     sort = this.sortValue.startsWith("a") ? "asc" : "desc";
//   } catch (error) {
//     sort = "";
//   }
//   this.filterQuery="AND FAQ_MASTER_ID="+this.FAQ_MASTER_ID

// if(this.HEAD!='')
// {
  if (this.QUESTION == 'AL') {
    this.filterQuery = ''
  }
  else {  
    // this.filterQuery += " AND QUESTION='" + this.QUESTION+"'"
    this.filterQuery="AND FAQ_MASTER_ID="+this.QUESTION
  }
this.api.getAllFaqResponses(0, 0, '', '', this.filterQuery).subscribe(data => {
  console.log(data)
   this.totalRecords = data['count'];
   this.dataList = data['data'];
   this.loadingRecords = false;
   this.filterClass = "filter-invisible";
   this.isFilterApplied="primary"
   this.isSpinning=false
 }, err => {
   console.log(err);
 });


// else{
//   this.filterQuery=''
//   this.filterClass = "filter-invisible";
//   this.isFilterApplied="default"
//   // this.search()
// }


//     if (this.HEAD == 'AL') {
//       this.filterQuery = this.filterQuery
//     }
//     else {  
//       this.filterQuery += " AND HEAD='" + this.HEAD+"'"
//     }
}

  // applyFilter() {
  //   var sort: string;
  //   try {
  //     sort = this.sortValue.startsWith("a") ? "asc" : "desc";
  //   } catch (error) {
  //     sort = "";
  //   }
  //   this.filterQuery = ''
  //   // if (this.value1 != undefined && this.value2 != undefined) {
  //   //   if (this.value1 != null && this.value2 == null) {
  //   //     this.message.error("Please Select End Date", "");
  //   //   }
  //   //   else if (this.value1 == null && this.value2 != null) {
  //   //     this.message.error("Please Select Start Date", "");
  //   //   }
  //   //   else {
  //   //     this.filterQuery += " AND ( OUTWARD_DATE BETWEEN '" + this.value1 + ":00:00:00" + "' AND '" + this.value2 + ":23:59:59" + "' ) "
  //   //   }
  //   // }

  //   if (this.QUESTION == 'AL') {
  //     this.filterQuery = this.filterQuery
  //   }
  //   else {  
  //     this.filterQuery += " AND QUESTION='" + this.QUESTION+"'"
  //   }

  //   if (this.HEAD == 'AL') {
  //     this.filterQuery = this.filterQuery
  //   }
  //   else {  
  //     this.filterQuery += " AND HEAD='" + this.HEAD+"'"
  //   }

  //   this.pageIndex= 1
  //   this.dataList=[]
  //   this.search(true)

  // }

}





