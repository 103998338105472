export class ClusterMaster {
    ID:number=0;
    ORG_ID:number=0;
    NAME:string='';
    SHORT_CODE:string=''; 
    STATUS:boolean=true;
    SEQUENCE_NO:number=0; 
     
}

