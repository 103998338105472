import { Component, OnInit, ViewChild } from '@angular/core';
import { Ticket } from 'src/app/Models/ticket';
import { ApiService } from 'src/app/Service/api.service';
import { Ticketdetails } from 'src/app/Models/ticketdetails';
import { CookieService } from 'ngx-cookie-service';
import { TicketdetailsComponent } from '../../SupportModule/Tickets/ticketdetails/ticketdetails.component';
import { Ticketgroup } from 'src/app/Models/ticketgroup';
import { DatePipe } from '@angular/common';
import { ViewchatticketComponent } from '../viewchatticket/viewchatticket.component';
import { NzNotificationService } from 'ng-zorro-antd';
import { CreateticketComponent } from '../createticket/createticket.component';

@Component({
  selector: 'app-myticket',
  templateUrl: './myticket.component.html',
  styleUrls: ['./myticket.component.css'],
  providers: [DatePipe]
})

export class MyticketComponent implements OnInit {
  formTitle = "Manage Tickets";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  userId = this.cookie.get('userId');
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "LAST_RESPONDED";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] = [["DEPARTMENT_NAME", "Department"], ["TICKET_NO", "Ticket No."], ["QUESTION", "Question"], ["IS_TAKEN_STATUS", "Is Taken"], ["TICKET_TAKEN_EMPLOYEE", "Taken By"], ["LAST_RESPONDED", "Last Responded Date"], ["STATUS", "Status"]]
  STATUS = "U";
  isSpinning = false;
  filterClass: string = "filter-visible";
  applicationId = 3;
  departmentId = Number(this.cookie.get('departmentId'));
  selectedDate: Date[] = [];
  dateFormat = 'dd/MM/yyyy';
  @ViewChild(TicketdetailsComponent, { static: false }) details: TicketdetailsComponent;
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: Ticket = new Ticket();
  drawerVisible2: boolean;
  drawerTitle2: string;
  drawerData2: Ticket = new Ticket();
  drawerData1: Ticketdetails = new Ticketdetails();
  data1 = [];
  ticketGroups: Ticketgroup[] = [];
  index = 0;
  ticketQuestion = {};
  value1: string = "";
  value2: string = "";
  Grpname: string;
  newstr: string;
  newData2 = [];
  grpid = 0;
  GRPNAME = '';
  bread = [];

  constructor(private api: ApiService, private datePipe: DatePipe, private cookie: CookieService, private message: NzNotificationService) { }

  ngOnInit() {
    this.search();
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }

  clearFilter() {
    this.STATUS = "U";
    this.filterQuery = "";
    this.selectedDate = null;
    this.value1 = '';
    this.value2 = '';
    this.filterClass = "filter-invisible";
    this.applyFilter();
  }

  changeDate(value) {
    this.value1 = this.datePipe.transform(value[0], "yyyy-MM-dd")
    this.value2 = this.datePipe.transform(value[1], "yyyy-MM-dd");
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    console.log("search text:" + this.searchText);

    if (this.searchText != "") {
      var likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ')';
      var filterQuery = " AND USER_ID=" + this.userId + likeQuery
      if (this.STATUS == 'AL') {
        filterQuery = filterQuery;

      } else {
        filterQuery += " AND STATUS='" + this.STATUS + "'";
      }

      this.loadingRecords = true;
      this.api.getAllTickets(this.pageIndex, this.pageSize, this.sortKey, sort, filterQuery + ' AND  ORG_ID =' + this.cookie.get('orgId')).subscribe(data => {
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.totalRecords = data['count'];
          this.dataList = data['data'];
        }

      }, err => {
        console.log(err);
      });

    } else {
      this.applyFilter();
    }
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  searchSet() {
    document.getElementById('button1').focus();
    this.search(true);
  }

  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";

    else
      this.filterClass = "filter-visible";
  }

  applyFilter() {
    this.isSpinning = true;
    this.loadingRecords = true;
    var sort: string;

    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    this.filterQuery = "";
    //var filterQuery="AND IS_TAKEN='0' AND STATUS = '"+this.STATUS+"' AND APPLICATION_ID="+this.applicationId + " AND DEPARTMENT_ID="+this.departmentId
    if (this.selectedDate == undefined || this.selectedDate.length == 0) {
      this.filterQuery = "";

    } else {
      this.filterQuery = " AND (DATE BETWEEN '" + this.value1 + ":00:00:00" + "' AND '" + this.value2 + ":23:59:59" + "') ";
    }

    if (this.STATUS == 'AL') {
      this.filterQuery = this.filterQuery;

    } else {
      if (this.STATUS == 'U') {
        this.filterQuery += " AND STATUS in ('P','S','O','B','H')";

      } else if (this.STATUS == 'A') {
        this.filterQuery += " AND STATUS ='R'";

      } else if (this.STATUS == 'C') {
        this.filterQuery += " AND STATUS ='C'";
      }
    }

    var filterQuery = this.filterQuery + " AND USER_ID=" + this.userId;

    this.api.getAllTickets(this.pageIndex, this.pageSize, this.sortKey, sort, filterQuery + ' AND  ORG_ID =' + this.cookie.get('orgId')).subscribe(data => {
      if (data['code'] == 200) {
        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];
        this.isSpinning = false;
        this.isFilterApplied = "primary";
        this.filterClass = "filter-invisible";
      }

    }, err => {
      console.log(err);
    });
  }

  uniqueDateArry = [];

  @ViewChild(ViewchatticketComponent, { static: false }) ViewchatticketComponentVar: ViewchatticketComponent;

  ViewDetails(data: Ticket) {
    this.newData2 = [];
    this.data1 = [];
    this.drawerTitle = "Ticket No. " + data.TICKET_NO;
    this.drawerData = Object.assign({}, data);
    var filterQuery1 = "AND TICKET_MASTER_ID = " + data.ID + "";

    this.ViewchatticketComponentVar.isSpinning = true;
    this.api.getAllTicketDetails(0, 0, "CREATED_MODIFIED_DATE", "asc", filterQuery1).subscribe(data => {
      if (data['code'] == 200) {
        this.ViewchatticketComponentVar.isSpinning = false;
        this.totalRecords = data['count'];
        this.data1 = data['data'];
        this.grpid = this.data1[0]['TICKET_GROUP_ID'];

        // Getting Unique dates
        for (var i = 0; i < this.data1.length; i++) {
          this.uniqueDateArry.push(this.datePipe.transform(this.data1[i]['CREATED_MODIFIED_DATE'], "yyyy-MM-dd"));
        }

        this.uniqueDateArry = [...new Set(this.uniqueDateArry)];
        this.uniqueDateArry.sort();

        this.uniqueDateArry.forEach(d1 => {
          this.newData2.push({
            key: d1, data: this.data1.filter(data =>
              this.datePipe.transform(data.CREATED_MODIFIED_DATE, "yyyy-MM-dd") == d1
            )
          });
        });

        this.data1 = this.newData2;
        this.ViewchatticketComponentVar.scrollIntoViewFunction();

        this.api.getBreadInChat(0, 0, 'ID', 'desc', '', '', this.grpid).subscribe(data => {
          if (data['code'] == 200) {
            this.bread = data['data'];
            this.newstr = '';
            this.GRPNAME = '';

            for (var i = 0; i < this.bread.length; i++) {
              this.GRPNAME = this.GRPNAME + '>' + this.bread[i]['VALUE'];
              var str = this.GRPNAME;
              this.newstr = str.replace('>', '');
            }
          }

        }, err => {
          console.log(err);
        });
      }

    }, err => {
      console.log(err);
    });

    this.drawerVisible = true;
  }

  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }

  @ViewChild(CreateticketComponent, { static: false }) CreateticketComponentVar: CreateticketComponent;

  add() {
    this.index = 0;
    var filterQuery = " AND PARENT_ID=0 AND TYPE='Q'";

    this.CreateticketComponentVar.isSpinning = true;
    this.api.getAllTicketGroups(0, 0, 'SEQ_NO', 'ASC', filterQuery + ' AND ORG_ID=' + this.cookie.get('orgId') + " AND STATUS=1").subscribe(ticketGroups => {
      if (ticketGroups['code'] == 200) {
        this.CreateticketComponentVar.isSpinning = false;

        if (ticketGroups['data'].length == 0) {
          this.ticketQuestion = {};
          this.ticketGroups = [];

        } else {
          this.ticketQuestion = ticketGroups['data'][0];
          var filterQuery2 = " AND PARENT_ID=" + ticketGroups['data'][0]['ID'] + " AND TYPE='O'";

          this.api.getAllTicketGroups(0, 0, 'SEQ_NO', 'ASC', filterQuery2 + ' AND ORG_ID=' + this.cookie.get('orgId') +" AND STATUS=1").subscribe(ticketGroups => {
            this.ticketGroups = ticketGroups['data'];
          });
        }
      }
    });

    this.drawerVisible2 = true;
    this.drawerTitle2 = "Create New Ticket";
    this.drawerData2 = new Ticket();
  }

  drawerClose1(): void {
    this.search();
    this.drawerVisible2 = false;
  }

  get closeCallback1() {
    return this.drawerClose1.bind(this);
  }

  changeRadioButton(event) {
    this.pageIndex = 1;
    this.pageSize = 10;
    this.applyFilter();
  }

  onTicketDeleteCancel() { }

  onTicketDeleteConfirm(data: Ticket) {
    data.STATUS = "C";

    this.api.updateTicket(data).subscribe(successCode => {
      if (successCode['code'] == 200) {
        this.message.success("Ticket Closed successfully", "");
        this.STATUS = 'U';
        this.applyFilter();

      } else {
        this.STATUS = 'U';
        this.applyFilter();
        this.message.error("Failed to Ticket Close", "");
      }

    }, err => {
      this.STATUS = 'U';
      this.applyFilter();
    });
  }
}
