import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/Service/api.service';
import { NzNotificationService } from 'ng-zorro-antd';
import { Role } from 'src/app/Models/role';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.css']
})
export class RolesComponent implements OnInit {

  formTitle = "Manage Roles";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] =[['PARENT_ROLE_NAME','Parent Role Name'], ["NAME","Name"],["DESCRIPTION","Description"],["IS_ACTIVE","Status"]]
  drawerData2: string[];
  drawerData3: string[];
  //drawer Variables
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: Role = new Role();
  drawerVisible4: boolean;
  drawerTitle4: string;
  drawerData4: Role = new Role();
  drawerVisible1: boolean;
  drawerTitle1: string;
  drawerData1: Role = new Role();

  constructor(private api: ApiService,private message: NzNotificationService) { }
  ngOnInit() {
    this.search();
  }
  // Basic Methods
  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }
  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }
    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }
    console.log("search text:" + this.searchText);
    if (this.searchText != "") {
      var likeQuery = " AND";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
      console.log("likeQuery" + likeQuery);
    }
    this.api.getAllRoles(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery).subscribe(data => {
    console.log(data)
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];
    }, err => {
      console.log(err);
      if(err['ok']==false)
      this.message.error("Server Not Found", "");
    });
  }
  //Drawer Methods
  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  get closeCallback1() {
    return this.drawerClose1.bind(this);
  }
  get closeCallback2() {
    return this.drawerClose2.bind(this);
  }


  add(): void {
    this.drawerTitle = "Create New Role";
    this.drawerData = new  Role();
    this.drawerData.STATUS=true
    this.drawerData.REQUIRED_DEPARTMENT_ID=true
    this.drawerData.REQUIRED_APPLICATION_ID=true
    this.drawerData.DESCRIPTION="No Description"
    this.drawerVisible = true;
  }
  edit(data:  Role): void {
    this.drawerTitle = "Update Role";
    this.drawerData = Object.assign({}, data);
    this.drawerVisible = true;
  }
  MapModules(data:Role)
  {
    // this.api.getRoleModules(data.ID).subscribe(data => {
    //   console.log(data['data']);
    //  this.drawerData2 =data['data'];
    //   }, err => {
    //   console.log(err);
    // });
    this.drawerTitle1 = "Map Modules for " + data.NAME + "";
    this.drawerData1 = Object.assign({}, data);
    this.drawerVisible1 = true;
  }
  

  MapForms(data: Role): void {
    
    this.api.getRoleDetails(data.ID).subscribe(data => {
      console.log("data");
      console.log(data['data']);
     this.drawerData3 =data['data'];
      }, err => {
      console.log(err);
    });
    this.drawerTitle4 = "Role Details for " + data.NAME + "";
    this.drawerData4 = Object.assign({}, data);
    this.drawerVisible4 = true;
  }

  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }
  drawerClose1(): void {
    this.search();
    this.drawerVisible1 = false;
  }
  drawerClose2(): void {
    this.search();
    this.drawerVisible4 = false;
  }
}