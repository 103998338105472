import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd';
import { folderMaster } from 'src/app/Models/folderMaster';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-folder-drawer',
  templateUrl: './folder-drawer.component.html',
  styleUrls: ['./folder-drawer.component.css'],
  providers: [DatePipe]
})

export class FolderDrawerComponent implements OnInit {
  @Input() drawerClose: Function;
  @Input() data: folderMaster;
  @Input() folderID: any;

  isSpinning = false;
  forms: folderMaster[];

  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe) { }

  ngOnInit() {
    this.getFolders();
  }

  parentFolder = [];

  getFolders() {
    this.api.getAllMyFolders(0, 0, 'ID', 'desc', '').subscribe(data => {
      if (data['code'] == 200) {
        // console.log(data['data']);
        this.parentFolder = data['data'];
      }

    }, err => {
      console.log(err);
    });
  }

  close(myForm: NgForm): void {
    this.drawerClose();
    this.getFolders();
    this.reset(myForm);
  }

  reset(myForm: NgForm) {
    myForm.form.reset();
    this.data.PARENT_ID = String(this.folderID);
  }

  save(addNew: boolean, myForm: NgForm): void {
    var isOk = true;

    if (this.data.PARENT_ID == undefined) {
      isOk = false;
      this.message.error("Please Select Valid Parent Folder", "");
    }

    if (this.data.NAME != undefined) {
      if (this.data.NAME.trim() != '') {
        if (!this.api.checkTextBoxIsValid(this.data.NAME)) {
          isOk = false;
          this.message.error("Please Enter Valid Folder Name", "");
        }
      } else {
        isOk = false;
        this.message.error("Please Enter Valid Folder Name", "");
      }
    } else {
      isOk = false;
      this.message.error("Please Enter Valid Folder Name", "");
    }

    if (isOk) {
      this.isSpinning = true;

      this.data.CREATED_DATE_TIME = this.datePipe.transform(new Date(), "yyyy-MM-dd HH:mm") + ":00";
      this.data.OWNER_ID = this.api.userId;

      console.log(this.data);

      if (this.data.ID) {
        this.api.updateExistingMyFolder(this.data).subscribe(successCode => {
          if (successCode['code'] == "200") {
            this.isSpinning = false;
            this.message.success("Folder Updated Successfully", "");

            if (!addNew) {
              this.close(myForm);
            }

          } else {
            this.message.error("Failed to Update Folder", "");
          }

        }, err => {
          if (err['ok'] == false)
            this.message.error("Failed to Update Folder", "");
        });

      } else {
        this.api.createNewMyFolder(this.data).subscribe(successCode => {
          if (successCode['code'] == "200") {
            this.isSpinning = false;
            this.message.success("New Folder Added Successfully", "");

            if (!addNew) {
              this.close(myForm);

            } else {
              this.reset(myForm);
              this.data = new folderMaster();
              this.data.PARENT_ID = String(this.folderID);
            }

          } else {
            this.message.error("Failed to Add New Folder", "");
          }

        }, err => {
          if (err['ok'] == false)
            this.message.error("Failed to Add New Folder", "");
        });
      }
    }
  }
}
