import { Component, OnInit,Input,ViewChild } from '@angular/core';
import { Faq } from 'src/app/Models/faq';
import { ApiService } from 'src/app/Service/api.service';
import { NzNotificationService } from 'ng-zorro-antd';

@Component({
  selector: 'app-faqresponses',
  templateUrl: './faqresponses.component.html',
  styleUrls: ['./faqresponses.component.css']
})
export class FaqresponsesComponent implements OnInit {
  @Input() drawerClose: Function;
  @Input() data: Faq;
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterClass: string = "filter-visible";
  initFilter = true;
  isFilterApplied: string = "default";
  columns: string[][] =[ ["USER_MOBILE","Mobile Number"],["USER_EMAIL_ID","Email ID"],["SUGGESTION","Suggestion"],["STATUS","Status"]]
  
  FAQ_MASTER_ID:Number
  STATUS="P"
  filterQuery:string=""
  
  //drawer Variables
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: Faq = new Faq();
  isSpinning=false
 
  constructor(private api: ApiService,private message: NzNotificationService) { }
  ngOnInit() {
    this.search();
   
  }
  // Basic Methods

  getFAQID(id?)
  {
    if(id==undefined)
    this.FAQ_MASTER_ID=0
    else
    this.FAQ_MASTER_ID=id
  }
  
 

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }
  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }
    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }
    console.log(sort)
    console.log("search text:" + this.searchText);
    if (this.searchText != "") {
      this.filterQuery += " AND (";
      var likeQuery = " ";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like ('%" + this.searchText + "%') OR";
      });
      this.filterQuery += likeQuery.substring(0, likeQuery.length - 3) + ")";
    }
else
{
  this.filterQuery=""
  this.applyFilter(this.FAQ_MASTER_ID)
}
if(this.FAQ_MASTER_ID!=undefined)
{
    this.api.getAllFaqResponses(0, 0, this.sortKey, sort, this.filterQuery).subscribe(data => {
     console.log(data)
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];
     
    }, err => {
      console.log(err);
    });
  }
  }

  showFilter()
  {
    if (this.filterClass === "filter-visible")
    this.filterClass = "filter-invisible";
  else
    this.filterClass = "filter-visible";

  }

  applyFilter(id?)
{
 this.FAQ_MASTER_ID=id
  this.isSpinning=true
  var sort: string;
  try {
    sort = this.sortValue.startsWith("a") ? "asc" : "desc";
  } catch (error) {
    sort = "";
  }
  this.filterQuery="AND FAQ_MASTER_ID="+this.FAQ_MASTER_ID+" AND STATUS='"+this.STATUS+"' "

if(this.FAQ_MASTER_ID!=undefined)
{
this.api.getAllFaqResponses(0, 0, this.sortKey, sort, this.filterQuery).subscribe(data => {
  console.log(data)
   this.totalRecords = data['count'];
   this.dataList = data['data'];
   this.loadingRecords = false;
   this.filterClass = "filter-invisible";
   this.isFilterApplied="primary"
   this.isSpinning=false
 }, err => {
   console.log(err);
 });
}
}


  clearFilter()
  {
    this.STATUS="P"
    this.isFilterApplied="default"
    this.filterClass = "filter-invisible";
    this.applyFilter(this.FAQ_MASTER_ID)
  }

  getName(status)
{
if(status=="P" )
return "Change"
else 
return ""

}


edit(data:Faq,status,id)
{
  console.log(status)
  if(status=="P")
{
  console.log(data)
  this.drawerTitle = "Update Faq";
  try {
    data.TAGS_STRING= data.TAGS.split(",");
 } catch (error) {
  data.TAGS_STRING= [];    
 }
  this.drawerData = Object.assign({}, data);
  this.drawerVisible = true;
}

}

get closeCallback() {
  return this.drawerCloseFaq.bind(this);
}


drawerCloseFaq()
{
  this.applyFilter(this.FAQ_MASTER_ID);
  this.drawerVisible = false;
}

}
