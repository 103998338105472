export class Smsserver {
    ID:number;
    CLIENT_ID:number;
    APPLICATION_ID:number;
    API_URL:string;
    USER_ID:string;
    PASSWORD:string;
    API_KEY:string;
    MODE:string;
    TYPE:string;
    SENDER_ID:number;
    PRIORITY:number;
    BALANCE:number;
    STATUS:string;
    KEY:string;
    PRE_REPLY:string;
    POST_REPLY:string;
    BALANCE_CHECK_URL:string;
    PRE_BALANCE:number;
    POST_BALANCE:number;
    STATUS_CHECK_URL:string;
    PRE_STATUS_FORMAT:string;
    POST_STATUS_FORMAT:string;
    PRE_TIME_FORMAT:string;
    POST_TIME_FORMAT:string;
    RESEND_ATTEMPT_TIME :number;
    HISTORY_CLEAR_TIME :number;
}
