import { Component, OnInit } from '@angular/core';
import { Folder } from 'src/app/Models/folder';
import { ApiService } from 'src/app/Service/api.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-folders',
  templateUrl: './folders.component.html',
  styleUrls: ['./folders.component.css']
})
export class FoldersComponent implements OnInit {

  formTitle = "Manage Folders";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  applicationId=this.cookie.get('applicationId')

  columns: string[][] =[ ["NAME","Name"],["DESCRIPTION","Description"],["F_KEY","F-Key"]]

  //drawer Variables
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: Folder = new Folder();
  constructor(private api: ApiService,private cookie:CookieService,) { }
  ngOnInit() {
    this.search();
  }
  // Basic Methods
  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }
  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }
    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }
    console.log("search text:" + this.searchText);
    if (this.searchText != "") {
      var likeQuery = " AND APPLICATION_ID="+this.applicationId+" AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2) +")"
      console.log("likeQuery" + likeQuery);
    }
    else
    {
      likeQuery=" AND APPLICATION_ID="+this.applicationId
    }
    this.api.getAllFolders(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery).subscribe(data => {
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];
    }, err => {
      console.log(err);
    });
  }
  //Drawer Methods
  get closeCallback() {
    return this.drawerClose.bind(this);
  }
  add(): void {
    this.drawerTitle = "Create New Folder";
    this.drawerData = new  Folder();
    this.drawerVisible = true;
    this.drawerData.DESCRIPTION="No Description"
  }
  edit(data:  Folder): void {
    this.drawerTitle = "Update Folder";
    this.drawerData = Object.assign({}, data);
    this.drawerVisible = true;
    console.log(data)
  }
  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }
}
