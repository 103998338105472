import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/Service/api.service';
import { NzNotificationService } from 'ng-zorro-antd';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.css']
})
export class TestComponent implements OnInit {

  url:string
  constructor(private api: ApiService,private message: NzNotificationService) { }

  ngOnInit() {
  }

  
  getfile()
  {
   
    let url1="Oq6hGiWENLAt7XbuvM9C"
    this.api.getFile(url1).subscribe(data => {
      console.log(data)
     
      if (data['code'] == 200) {
        // const TYPED_ARRAY = new Uint8Array(data['data']['FILE_DATA']['data']);
        // const STRING_CHAR = TYPED_ARRAY.reduce((data, byte) => {
        //   return data + String.fromCharCode(byte);
        // }, '');
        // let base64String = btoa(STRING_CHAR);

        // let pdfWindow = window.open("")

        
       
      }
      else {
        this.message.error(data['message'], "");
      }
    }, err => {
      console.log(err);
    });
  }

}
