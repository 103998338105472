// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  firebase: {
    // UVtectSoft Server
    // apiKey: "AIzaSyBJ1Pd-a62U1JLV6Vjm2ptVYd1qdTfb65E",
    // authDomain: "uvtechsoft-tickdesk.firebaseapp.com",
    // projectId: "uvtechsoft-tickdesk",
    // storageBucket: "uvtechsoft-tickdesk.appspot.com",
    // messagingSenderId: "284550366271",
    // appId: "1:284550366271:web:8831157f2abc8b32aee48a",
    // measurementId: "G-VFVQD42FWG",

    apiKey: "AIzaSyC8GTc_BRs94oVOyMxRIeLBF5FB2DEJXaI",
    authDomain: "kbp-tickdesk.firebaseapp.com",
    projectId: "kbp-tickdesk",
    storageBucket: "kbp-tickdesk.appspot.com",
    messagingSenderId: "272729313737",
    appId: "1:272729313737:web:220fbdb006849799305818",
    measurementId: "G-4P4WEE874V",

    appVersion1: require('../../package.json').version + '-dev',
    appVersion2: require('../../package.json').version,
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
