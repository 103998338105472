import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/Service/api.service';
import { AddNewNotificationDrawerComponent } from '../add-new-notification-drawer/add-new-notification-drawer.component';
import { SendEmailDrawerComponent } from '../send-email-drawer/send-email-drawer.component';

@Component({
  selector: 'app-view-notification-drawer',
  templateUrl: './view-notification-drawer.component.html',
  styleUrls: ['./view-notification-drawer.component.css']
})

export class ViewNotificationDrawerComponent implements OnInit {
  constructor(public api: ApiService, private message: NzNotificationService, private datePipe: DatePipe, private cookie: CookieService) { }

  ngOnInit() { }

  pageIndex = 1;
  pageSize = 8;
  totalRecords: number;
  notificationData = [];
  btnLoadMoreStatus = false;
  isSpinning = false;

  userId = Number(this.cookie.get('userId'));
  roleId = Number(this.cookie.get('roleId'));
  orgId = Number(this.cookie.get('orgId'));
  deptId = Number(this.cookie.get('deptId'));
  branchId = Number(this.cookie.get('branchId'));
  designationId = Number(this.cookie.get('designationId'));

  getNotifications() {
    this.isSpinning = true;
    this.notificationData = [];

    this.api.getAllMyNotications(this.pageIndex, this.pageSize, this.userId, this.deptId, this.branchId, this.designationId, this.orgId).subscribe(data => {
      if (data['code'] == 200) {
        console.log(data);
        this.isSpinning = false;
        this.totalRecords = data['count'];
        this.notificationData = data['data'];

        if (this.totalRecords == this.notificationData.length) {
          this.btnLoadMoreStatus = false;

        } else {
          this.btnLoadMoreStatus = true;
        }
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  onLoadMoreClick() {
    this.pageSize = this.pageSize + 8;
    this.getNotifications();
  }

  sendNotiDrawerVisible = false;
  sendNotiDrawerTitle: string;

  @ViewChild(AddNewNotificationDrawerComponent, { static: false }) AddNewNotificationDrawerComponentVar: AddNewNotificationDrawerComponent;

  openSendNotiDrawer() {
    this.sendNotiDrawerVisible = true;
    this.sendNotiDrawerTitle = "Send Notification";
    this.AddNewNotificationDrawerComponentVar.sharingMode = "I";
    this.AddNewNotificationDrawerComponentVar.disableRadioButtons();
    this.AddNewNotificationDrawerComponentVar.changeRadioButton('I');
  }

  sendNotiDrawerClose() {
    this.sendNotiDrawerVisible = false;
    this.pageSize = 8;
    this.getNotifications();
  }

  get sendNotiDrawerCloseCallback() {
    return this.sendNotiDrawerClose.bind(this);
  }

  sendEmailDrawerVisible = false;
  sendEmailDrawerTitle: string;

  @ViewChild(SendEmailDrawerComponent, { static: false }) SendEmailDrawerComponentVar: SendEmailDrawerComponent;

  openSendEmailDrawer() {
    this.sendEmailDrawerVisible = true;
    this.sendEmailDrawerTitle = "Send Email";

    this.SendEmailDrawerComponentVar.FROM_DATE = this.datePipe.transform(new Date(), "yyyy-MM-dd");
    this.SendEmailDrawerComponentVar.TO_DATE = this.datePipe.transform(new Date(), "yyyy-MM-dd");

    this.SendEmailDrawerComponentVar.getCount();
  }

  sendEmailDrawerClose() {
    this.sendEmailDrawerVisible = false;
  }

  get sendEmailDrawerCloseCallback() {
    return this.sendEmailDrawerClose.bind(this);
  }
}
