export class Ticketdetails {
    ID:number
    TICKET_MASTER_ID:number
    CLIENT_ID:number
    SENDER:string
    SENDER_ID:number
    DESCRIPTION:string
    URL:string
}

