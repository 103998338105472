import { Component, OnInit,Input } from '@angular/core';
import { Task } from 'src/app/Models/task';
import { ApiService } from 'src/app/Service/api.service';
import { NzNotificationService } from 'ng-zorro-antd';

@Component({
  selector: 'app-task',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.css']
})
export class TaskComponent implements OnInit {

  @Input() drawerClose: Function;
  @Input() data: Task;
  isSpinning = false


  constructor(private api: ApiService, private message: NzNotificationService) {
  }

  ngOnInit() {
  
  }
  


  close(): void {
    this.drawerClose();
  }

  save(addNew: boolean): void {
    this.isSpinning = true;
      if (this.data.ID) {
        this.api.updateTask(this.data)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
              this.message.success("Task information updated Successfully...", "");
              if (!addNew)
                this.drawerClose();
              this.isSpinning = false;
            }
            else {
              this.message.error("Failed to update task information...", "");
              this.isSpinning = false;
            }
          });
      }
      else {

        this.api.createTask(this.data)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
              this.message.success("Task information added successfully...", "");
              if (!addNew)
                this.drawerClose();
              else {
                this.data = new Task();
              }
              this.isSpinning = false;
            }
            else {
              this.message.error("Failed to add task information...", "");
              this.isSpinning = false;
            }
          });
      }
  }
}
