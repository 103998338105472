import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/Service/api.service';
import { NzNotificationService } from 'ng-zorro-antd';
@Component({
  selector: 'app-test',
  templateUrl: './tests.component.html',
  styleUrls: ['./tests.component.css']
})
export class TestsComponent implements OnInit {

  url:string[]=['']
  constructor(private api: ApiService,private message: NzNotificationService) { }

  ngOnInit() {
    this.getfile()
  }

  
  getfile()
  {
    var linkSource = ""
    let url1="yz0VGFbXxRyuBo4oDfoe"
    console.log("call")
    this.api.getFile(url1).subscribe(data => {
      console.log(data)
      if (data['code'] == 200) {
        const TYPED_ARRAY = new Uint8Array(data['data']['FILE_DATA']['data']);
        const STRING_CHAR = TYPED_ARRAY.reduce((data, byte) => {
          return data + String.fromCharCode(byte);
        }, '');
        let base64String = btoa(STRING_CHAR);

       let pdfWindow = window.open("")

        if (data['data']['F_EXT'] == "pdf") {
          linkSource = "data:application/pdf;base64," + base64String;
         // pdfWindow.document.write("<iframe width='100%' height='100%' src='" + linkSource + "'></iframe>")
        }
        else {
          linkSource = "data:image/jpeg;base64," + base64String;
          this.url=[base64String];
          // pdfWindow.document.write("<app-image-viewer [images]='" + this.url + "'></app-image-viewer>")
        }




     
      }
      else {
        this.message.error(data['message'], "");
      }
    
    }, err => {
      console.log(err);
    });
  }

}