export class folderMaster {
    ID: number;
    CLIENT_ID: number;
    PARENT_ID: any;
    NAME: string;
    CREATED_DATE_TIME: string;
    OWNER_ID: number;

    FOLDER_FILE: string;
    URL: string;
    TYPE: string;
    SIZE_KB: number;
    TAGS: string;
    DESCRIPTION: string;
}