import { Component, OnInit ,ViewChild} from '@angular/core';
import { Emailserver } from 'src/app/Models/emailserver';
import { ApiService } from 'src/app/Service/api.service';
import { HistoryComponent } from '../history/history.component';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-emails',
  templateUrl: './emails.component.html',
  styleUrls: ['./emails.component.css']
})
export class EmailsComponent implements OnInit {

  formTitle = "Manage Email Servers";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] =[ ["SENDER_NAME","Sender Name"],["EMAIL_ID","Email ID"],["PASSWORD","Password"],["SMTP_SERVER","SMTP Server"],["PORT","Port"],["IS_SSL_STATUS","Is SSL"],["PRIORITY","Priority"],["DAILY_LIMIT","Daily Limt"],["BALANCE","Balance"],["HISTORY_CLEAR_TIME","Histrory clear Time"],["KEY","Key"]]
 
  @ViewChild(HistoryComponent, { static: false }) history: HistoryComponent;

  //drawer Variables
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: Emailserver = new Emailserver();

  drawerVisible1: boolean;
  drawerTitle1: string;
  drawerData1: Emailserver = new Emailserver();
  applicationId= Number(this.cookie.get('applicationId'))
 // applicationId=1

  constructor(private api: ApiService,private cookie: CookieService) { }
  ngOnInit() {
    this.search();
  }
  // Basic Methods
  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }
  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }
    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }
    console.log("search text:" + this.searchText);
    if (this.searchText != "") {
      var likeQuery = " AND APPLICATION_ID="+this.applicationId+"";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
      console.log("likeQuery" + likeQuery);
    }
    else
    {
      likeQuery=" AND APPLICATION_ID="+this.applicationId+""
    }
    this.api.getAllEmailServers(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery).subscribe(data => {
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];
    }, err => {
      console.log(err);
    });
  }
  //Drawer Methods
  get closeCallback() {
    return this.drawerClose.bind(this);
  }
  get closeCallback1() {
    return this.drawerClose1.bind(this);
  }
  add(): void {
    this.drawerTitle = "Create New Email Server";
    this.drawerData = new Emailserver();
    this.drawerData.IS_SSL=true
    this.drawerData.STATUS="L"
    this.drawerVisible = true;
  }
  edit(data:Emailserver): void {
    
    this.drawerTitle = "Update Email Server";
    this.drawerData = Object.assign({}, data);
    this.drawerVisible = true;
  
  }
  viewHistory(data:Emailserver)
  {
    this.drawerTitle1 = "View Email History";
    this.drawerData1 = Object.assign({}, data);
    this.drawerVisible1 = true;
   this.history.applyFilter(data.ID);
  }

  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }
  drawerClose1(): void {
    this.drawerVisible1 = false;
  }
}