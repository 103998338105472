import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-selectpage',
  templateUrl: './selectpage.component.html',
  styleUrls: ['./selectpage.component.css']
})
export class SelectpageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  Title=""

  chooseTile()
  {
    console.log("chooseFile")
  }
}
