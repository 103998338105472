import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/Service/api.service';
import { NzNotificationService } from 'ng-zorro-antd';

@Component({
  selector: 'app-viewhistory',
  templateUrl: './viewhistory.component.html',
  styleUrls: ['./viewhistory.component.css']
})
export class ViewhistoryComponent implements OnInit {

  formTitle = "View History";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  isSpinning=false
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  filterStatusValue:string[]=['AL']
  SCHEDULAR_KEY:string
  columns: string[][] =[ ["USER_NAME","User"],["TASK_NAME","Task"],["EXECUTED_TIME","Executed Time"],["STATUS","Status"],["MESSAGE","Message"]]

  //drawer Variables
 
  constructor(private api: ApiService,private message:NzNotificationService) { }
  ngOnInit() {
    this.search();
  }
  // Basic Methods
  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }
  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }
    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }
    console.log("search text:" + this.searchText);
    if (this.searchText != "") {
      var likeQuery = " AND";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
      console.log("likeQuery" + likeQuery);
    }
    this.api.getAllScheduleHistory(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery).subscribe(data => {
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];
    }, err => {
      console.log(err);
    });
  }

  applyFilter()
  {
    this.isSpinning=true
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }
    if(this.SCHEDULAR_KEY==undefined||this.filterStatusValue==undefined)
    {
      this.message.error("Please Select Fields", "");
    }
    else
    {
        this.filterQuery="AND SCHEDULER_KEY='"+ this.SCHEDULAR_KEY +"'"
        let statusString: string = "";
        if (this.filterStatusValue != undefined) {
          this.filterStatusValue.forEach(filterStatus => {
            statusString += "'" + filterStatus + "'" + ",";
          }); (this.filterStatusValue)
    
          if (statusString.toString() != "") {
            if(statusString.match("AL"))
            {
              console.log("ALL"+statusString.substring(0, statusString.length - 1))
              this.filterQuery += " AND STATUS IN ('S','F,'C')";
            } 
            else
            { 
              this.filterQuery += " AND STATUS IN (" + statusString.substring(0, statusString.length - 1) + ")";
            }
          }
        }
        console.log(this.filterQuery)
      
        this.api.getAllScheduleHistory(this.pageIndex, this.pageSize, this.sortKey, sort, this.filterQuery).subscribe(data => {
          console.log(data['data'])
          this.loadingRecords = false;
          this.totalRecords = data['count'];
          this.dataList = data['data'];
          this.isSpinning=false
        }, err => {
          console.log(err);
        });
    }
  }



  //Drawer Methods
 
}
