import { Component, OnInit,ViewChild } from '@angular/core';
import { Smsserver } from 'src/app/Models/smsserver';
import { ApiService } from 'src/app/Service/api.service';
import {SMSHistoryComponent} from '../history/SMShistory.component'
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-smss',
  templateUrl: './smss.component.html',
  styleUrls: ['./smss.component.css']
})
export class SmssComponent implements OnInit {

  formTitle = "Manage Sms Servers";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] =[ ["SENDER_ID","Sender Name"],["API_URL","API Url"],["PASSWORD","Password"],["API_KEY","API Key"],["MODE","Mode"],["TYPE","Type"],["PRIORITY","Priority"],["BALANCE","Balance"],["STATUS","Status"],["KEY","Key"],["BALANCE_CHECK_URL","Balance Check Url"],["STATUS_CHECK_URL","Status Check Url"],["RESEND_ATTEMPT_TIME","Resend Attempt Time"],["HISTORY_CLEAR_TIME","Histrory clear Time"]]
  @ViewChild(SMSHistoryComponent, { static: false }) history: SMSHistoryComponent;
  //drawer Variables
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: Smsserver = new Smsserver();
  drawerVisible1: boolean;
  drawerTitle1: string;
  drawerData1: Smsserver = new Smsserver();
  applicationId= Number(this.cookie.get('applicationId'))

  constructor(private api: ApiService,private cookie: CookieService) { }
  ngOnInit() {
    this.search();
  }
  // Basic Methods
  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }
  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }
    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }
    console.log("search text:" + this.searchText);
    if (this.searchText != "") {
      var likeQuery = " AND APPLICATION_ID="+this.applicationId;
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
      console.log("likeQuery" + likeQuery);
    }
    else
  {
    likeQuery = " AND APPLICATION_ID="+this.applicationId;
  }
    this.api.getAllSmsServers(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery).subscribe(data => {
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];
    }, err => {
      console.log(err);
    });
  }
  //Drawer Methods
  get closeCallback() {
    return this.drawerClose.bind(this);
  }
  get closeCallback1() {
    return this.drawerClose1.bind(this);
  }
  add(): void {
    this.drawerTitle = "Create New Sms Server";
    this.drawerData = new  Smsserver();
    this.drawerData.TYPE="0"
    this.drawerData.MODE="P"
    this.drawerData.STATUS="L"
    this.drawerData.PRE_BALANCE=0
    this.drawerData.POST_BALANCE=0
    this.drawerVisible = true;
  }
  edit(data:  Smsserver): void {
    this.drawerTitle = "Update Sms Server";
    this.drawerData = Object.assign({}, data);
    this.drawerVisible = true;
  }

  viewHistory(data:Smsserver)
  {
    this.drawerTitle1 = "View SMS History";
    this.drawerData1 = Object.assign({}, data);
    this.drawerVisible1 = true;
   this.history.applyFilter(data.ID);
  }
  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }
  drawerClose1(): void {
    this.drawerVisible1 = false;
  }
}