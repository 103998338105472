import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { BankMaster } from 'src/app/Models/BankMaster';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-bank-list',
  templateUrl: './bank-list.component.html',
  styleUrls: ['./bank-list.component.css']
})

export class BankListComponent implements OnInit {
  formTitle = "Manage Bank";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortKey: string = "id";
  sortValue: string = "desc";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] = [["NAME", "Name"], ["SHORT_NAME", "Short Name"], ["STATUS", "Status"]];
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: BankMaster = new BankMaster();
  BranchIDS=[]
  BRANCH_ID=[]
  constructor(private api: ApiService, private cookie: CookieService, private message: NzNotificationService) { }

  ngOnInit() {
    this.search(true);
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    var likeQuery = "";

    if (this.searchText != "") {
      likeQuery = " AND (";

      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ")";
    }

    this.loadingRecords = true;

    this.api.getAllBanks(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery).subscribe(data => {
      if (data['code'] == 200) {
        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  add(): void {
    this.drawerTitle = "New Bank";
    this.drawerData = new BankMaster();
    this.drawerData.STATUS = true;
    this.drawerVisible = true;
  }

  edit(data: BankMaster): void {
    this.drawerTitle = "Update Bank Details";
    this.drawerData = Object.assign({}, data);
    this.drawerVisible = true;
  }

  drawerClose(): void {
    this.search(false);
    this.drawerVisible = false;
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }
  drawerVisible1:boolean=false
  drawerTitle1:string
  Branch=[]

  mapbranch(data){
    this.BranchIDS=[]
    this.BRANCH_ID=[]
    this.drawerVisible1=true
    this.drawerData = Object.assign({}, data);
    this.drawerTitle1=this.drawerData.NAME
    
    console.log(this.drawerData,'dr')
    this.api.getAllBranch(0, 0, '', '', ' AND IS_BANK_BRANCH=1').subscribe(data => {
      if(data['code']==200){
        this.Branch = data['data'];

      }

    }, err => {
      console.log(err);
      
    });

    // this.AllBranchIds = data.BRANCH_IDS.split(",")
    // console.log(this.AllBranchIds);
    
    this.api.getAllMappedBranch(0, 0, '', '', ' AND BANK_ID = '+ data.ID + " AND STATUS = 1").subscribe(data1 => {
      if(data1['code']==200){
      this.BranchIDS = data1['data'];
      for (let i = 0; i < this.BranchIDS.length; i++) {
        // this.BRANCH_ID.push(this.BranchIDS[i]['BRANCH_ID'])
        this.BRANCH_ID.push(Number(this.BranchIDS[i]['BRANCH_ID']));
        if (i + 1 == this.BranchIDS.length) {
          this.BRANCH_ID = [...[], ...this.BRANCH_ID];
          console.log(this.BRANCH_ID);
        }
        
      }
      console.log(this.BRANCH_ID);
      
    }

    }, err => {
      console.log(err);
      
    });
  

  }
  get closeCallback1(){
    return this.drawerClose1.bind(this);
  }
  drawerClose1(): void {
    this.search(false);
    
  
    this.drawerVisible1 = false;
  }
}
