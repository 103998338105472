export class Faqhead {
    ID:number;
    CLIENT_ID:number;
    APPLICATION_ID:number=1;
    NAME:string;
    STATUS:any=true;
    PARENT_ID:number;
    IS_PARENT:any=true; 
    PARENT_NAME=''
    ORG_ID= 0
    SEQUENCE_NO = 0
    DESCRIPTION = ''
}

