export class Module {
    ID:number;
    CLIENT_ID:number;
    NAME:string;
    DESCRIPTION:string;
    STATUS:boolean;
    ABBREVIATION:string
    LOGO_URL:string

}
