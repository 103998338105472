export class Application {
    ID:number;
    CLIENT_ID:number;
    NAME:string;
    DESCRIPTION:string;
    STATUS:boolean;
    LOGO_URL:string;
    APPLICATION_KEY:string;
    ABBREVIATION:string
    API_KEY:string
}

