import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { Ticket } from 'src/app/Models/ticket';
import { Ticketdetails } from 'src/app/Models/ticketdetails';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/Service/api.service';
import { NzNotificationService } from 'ng-zorro-antd';
import { DatePipe } from '@angular/common';
import { TransferTicketDrawerComponent } from '../transfer-ticket-drawer/transfer-ticket-drawer.component';

@Component({
  selector: 'app-ticketdetails',
  templateUrl: './ticketdetails.component.html',
  styleUrls: ['./ticketdetails.component.css'],
  providers: [DatePipe]
})

export class TicketdetailsComponent implements OnInit {
  @Input() drawerClose: Function;
  @Input() data: Ticket;
  @Input() data2 = [];
  @Input() newstr = ''
  folderName = "ticket"
  userId = Number(this.cookie.get('userId'))
  ticketDetailsData: Ticketdetails
  DESCRIPTION
  fileDataLOGO_URL: File = null
  ID
  date1: string;
  isSpinning = false;
  imageSrc = '';
  todayDate = new Date();

  constructor(private cookie: CookieService, private datePipe: DatePipe, private api: ApiService, private message: NzNotificationService) { }

  ngOnInit() {
    this.getAllEmployee();
  }

  empList = [];

  getAllEmployee() {
    this.empList = [];

    this.api.getAllemployeeMaster(0, 0, 'ID', 'desc', ' AND  ORG_ID =' + this.cookie.get('orgId')).subscribe(data => {
      if (data['code'] == 200) {
        this.empList = data['data'];
      }

    }, err => {
      console.log(err);
    });
  }

  getSenderEmpName(senderEmpID): any {
    let empName = '';
    this.empList.filter(obj => {
      if (obj.ID == senderEmpID) {
        empName = obj['NAME'];
      }
    });

    return empName;
  }

  send(data1: Ticket) {
    this.date1 = this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss');
    var LOGO_URL = '';

    if (this.DESCRIPTION != undefined && this.DESCRIPTION.trim() != '') {
      this.isSpinning = true;

      if (this.fileDataLOGO_URL) {
        var number = Math.floor(100000 + Math.random() * 900000)
        var fileExt = this.fileDataLOGO_URL.name.split('.').pop();
        var Dates = new Date();
        var formatedDate = this.datePipe.transform(Dates, 'yyyyMMddHHmmss');
        var url = formatedDate + number + "." + fileExt;

        this.api.onUpload2(this.folderName, this.fileDataLOGO_URL, url).subscribe(successCode => {
          if (successCode['code'] == "200") {
            LOGO_URL = url;

            var ticketDetailsData1 = {
              ID: 0,
              SENDER: "S",
              CLIENT_ID: this.api.clientId,
              SENDER_ID: this.userId,
              TICKET_MASTER_ID: data1.ID,
              DESCRIPTION: this.DESCRIPTION,
              URL: LOGO_URL
            }

            this.api.createTicketDetail(ticketDetailsData1).subscribe(successCode => {
              this.isSpinning = false;
              if (successCode['code'] == "200") {
                // this.drawerClose();
                this.isSpinning = false;
                this.DESCRIPTION = "";
                this.fileDataLOGO_URL = null;
                this.imageSrc = '';
                this.message.success("Sent successfully", "");
                this.refreshChat(data1.ID);

                data1.LAST_RESPONDED = this.date1
                if (data1['FIRST_RESOLVED_TIME'] == null)
                  data1['FIRST_RESOLVED_TIME'] = this.date1;

                data1.STATUS = "R";

                this.api.updateTicket(data1).subscribe(successCode => {
                  if (successCode['code'] == "200") {
                    // this.drawerClose();
                    this.fileDataLOGO_URL = null;
                    this.DESCRIPTION = '';
                  }
                });

              } else {
                this.isSpinning = false;
                this.message.error("Failed to Send", "");
              }
            });

          } else {
            this.isSpinning = false;
            this.message.error("Failed to upload file", "");
          }
        });

      } else {
        var ticketDetailsData1 = {
          ID: 0,
          SENDER: "S",
          CLIENT_ID: this.api.clientId,
          SENDER_ID: this.userId,
          TICKET_MASTER_ID: data1.ID,
          DESCRIPTION: this.DESCRIPTION,
          URL: this.genarateKeyLOGO_URL()
        }

        this.api.createTicketDetail(ticketDetailsData1).subscribe(successCode => {
          this.isSpinning = false;
          if (successCode['code'] == "200") {
            // this.drawerClose();
            this.isSpinning = false;
            this.DESCRIPTION = "";
            this.fileDataLOGO_URL = null;
            this.imageSrc = '';
            this.message.success("Sent successfully", "");
            this.refreshChat(data1.ID);

            data1.LAST_RESPONDED = this.date1;
            data1.STATUS = "R";

            this.api.updateTicket(data1).subscribe(successCode => {
              if (successCode['code'] == "200") {
                // this.drawerClose();
                this.fileDataLOGO_URL = null;
                this.DESCRIPTION = '';
              }
            });

          } else {
            this.isSpinning = false;
            this.message.error("Failed to Send", "");
          }
        });
      }

    } else {
      this.message.error("Please mention your solution.", "");
    }
  }

  send2(data1: Ticket) {
    var SENDER, SENDER_ID, TICKET_MASTER_ID, DESCRIPTION, URL
    var ticketDetailsData1 = {
      ID: 0,
      SENDER: "S",
      CLIENT_ID: this.api.clientId,
      SENDER_ID: this.userId,
      TICKET_MASTER_ID: data1.ID,
      DESCRIPTION: this.DESCRIPTION,
      URL: this.genarateKeyLOGO_URL()
    }

    this.isSpinning = true;
    this.api.createTicketDetail(ticketDetailsData1).subscribe(successCode => {
      if (successCode['code'] == "200") {
        this.drawerClose();
        this.isSpinning = false;
        this.DESCRIPTION = "";

      } else {
        this.message.error("Failed", "");
      }
    });

    data1.STATUS = "R";
    data1.LAST_RESPONDED = this.date1;

    this.api.updateTicket(data1).subscribe(successCode => {
      if (successCode['code'] == "200") {
        this.drawerClose();
        this.fileDataLOGO_URL = null;
        this.DESCRIPTION = '';
      }
    });
  }

  takeTicket(data: Ticket) {
    data.IS_TAKEN = true;
    data.STATUS = 'S';
    data.TAKEN_BY_USER_ID = this.userId;

    this.isSpinning = true;
    this.api.updateTicket(data).subscribe(successCode => {
      if (successCode['code'] == "200") {
        this.isSpinning = false;
        this.drawerClose();
        this.fileDataLOGO_URL = null;
        this.DESCRIPTION = '';
      }
    });
  }

  closeTicket(data: Ticket) {
    data.STATUS = "C";

    this.api.updateTicket(data).subscribe(successCode => {
      if (successCode['code'] == "200") {
        this.drawerClose();
        this.fileDataLOGO_URL = null;
        this.DESCRIPTION = '';
      }
    });
  }

  keepOnHold(data: Ticket) {
    data.STATUS = "H";

    this.isSpinning = true;
    this.api.updateTicket(data).subscribe(successCode => {
      if (successCode['code'] == "200") {
        this.isSpinning = false;
        this.drawerClose();
        this.fileDataLOGO_URL = null;
        this.DESCRIPTION = '';
      }
    });
  }

  bannTicket(data: Ticket) {
    data.STATUS = "B";

    this.isSpinning = true;
    this.api.updateTicket(data).subscribe(successCode => {
      if (successCode['code'] == "200") {
        this.isSpinning = false;
        this.drawerClose();
        this.fileDataLOGO_URL = null;
        this.DESCRIPTION = '';
      }
    });
  }

  unbannTicket(data: Ticket) {
    data.STATUS = "O";
    data['ACTION'] = 'UNBANNED';

    this.isSpinning = true;
    this.api.updateTicket(data).subscribe(successCode => {
      if (successCode['code'] == "200") {
        this.isSpinning = false;
        this.drawerClose();
        this.fileDataLOGO_URL = null;
        this.DESCRIPTION = '';
      }
    });
  }

  genarateKeyLOGO_URL() {
    if (this.fileDataLOGO_URL) {
      var number = Math.floor(100000 + Math.random() * 900000);
      var fileExt = this.fileDataLOGO_URL.name.split('.').pop();
      var url = this.date1 + number + "." + fileExt;
      console.log(this.fileDataLOGO_URL);
      this.api.onUpload(this.folderName, this.fileDataLOGO_URL, url);
      var LOGO_URL = this.api.retriveimgUrl + this.folderName + "/" + url;
      return LOGO_URL;

    } else {
      return "";
    }
  }

  getUrl(url) {
    if (url)
      return this.api.baseUrl + "static/ticket/" + url;

    else
      return "";
  }

  urlClick(url) {
    window.open(this.api.baseUrl + "static/ticket/" + url);
  }

  clearImg() {
    this.fileDataLOGO_URL = null
  }

  onFileSelectedLOGO_URL(event) {
    const reader = new FileReader();
    this.fileDataLOGO_URL = event.target.files[0];
    reader.readAsDataURL(this.fileDataLOGO_URL);

    reader.onload = () => {
      this.imageSrc = reader.result as string;
    };
  }

  drawerVisible: boolean;
  drawerTitle: string;
  transferTicketDrawerData = [];

  @ViewChild(TransferTicketDrawerComponent, { static: false }) TransferTicketDrawerComponentVar: TransferTicketDrawerComponent;

  transferTicket(data) {
    this.TransferTicketDrawerComponentVar.EMPLOYEE_ID = undefined;
    this.drawerTitle = "Transfer > " + "Ticket No. " + data.TICKET_NO;

    // Get Department Wise Employee for Transfer Ticket
    this.transferTicketDrawerData = [];
    this.TransferTicketDrawerComponentVar.isLoading = true;
    this.isSpinning = true;

    // this.api.getAllemployeeMaster(0, 0, '', '', ' AND ID<>' + this.userId + ' AND  ID in(SELECT EMPLOYEE_ID from view_employee_role_mapping where ORG_ID = ' + this.cookie.get('orgId') + ' AND ROLE_ID=4) AND STATUS=1 AND DEPARTMENT_ID=' + data.DEPARTMENT_ID).subscribe(data => {
    this.api.getAllemployeeMaster(0, 0, '', '', ' AND ID<>' + this.userId + ' AND  ID in(SELECT EMPLOYEE_ID from view_employee_role_mapping where ORG_ID = ' + this.cookie.get('orgId') + ' AND ROLE_ID=4) AND STATUS=1').subscribe(data => {
      if (data['code'] == 200) {
        this.isSpinning = false;
        this.TransferTicketDrawerComponentVar.isLoading = false;
        this.transferTicketDrawerData = data['data'];
        this.drawerVisible = true;
      }

    }, err => {
      console.log(err);
    });
  }

  transferTicketDrawerClose(): void {
    console.log(this.data);
    this.refreshChat(this.data.ID);
    this.drawerVisible = false;
  }

  get transferTicketCloseCallback() {
    return this.transferTicketDrawerClose.bind(this);
  }

  uniqueDateArry = [];
  newData2 = [];
  @ViewChild('scrollDown', { static: false }) scrollDownVar: ElementRef;

  refreshChat(ticketNo) {
    this.data2 = [];
    this.newData2 = [];
    this.uniqueDateArry = [];
    let filter = " AND TICKET_MASTER_ID=" + ticketNo;

    this.isSpinning = true;
    this.api.getAllTicketDetails(0, 0, "CREATED_MODIFIED_DATE", "asc", filter).subscribe(data => {
      if (data['code'] == 200) {
        this.isSpinning = false;
        var data1 = data['data'];

        // Getting Unique dates
        for (var i = 0; i < data1.length; i++) {
          this.uniqueDateArry.push(this.datePipe.transform(data1[i]['CREATED_MODIFIED_DATE'], "yyyy-MM-dd"));
        }

        this.uniqueDateArry = [...new Set(this.uniqueDateArry)];
        this.uniqueDateArry.sort();

        this.uniqueDateArry.forEach(d1 => {
          this.newData2.push({
            key: d1, data: data1.filter(data =>
              this.datePipe.transform(data.CREATED_MODIFIED_DATE, "yyyy-MM-dd") == d1)
          });
        });

        this.data2 = this.newData2;
        this.scrollIntoViewFunction();
      }

    }, err => {
      console.log(err);
    });
  }

  scrollIntoViewFunction() {
    setTimeout(() => {
      document.getElementById("scrollDown").click();
    }, 500);
  }

  bottom() {
    document.getElementById('bottom').scrollIntoView();
  }
}
