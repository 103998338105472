import { Component, OnInit,Input } from '@angular/core';
import { Application } from 'src/app/Models/application';
import { ApiService } from 'src/app/Service/api.service';
import { NzNotificationService } from 'ng-zorro-antd';

@Component({
  selector: 'app-application-module-mapping',
  templateUrl: './application-module-mapping.component.html',
  styleUrls: ['./application-module-mapping.component.css']
})
export class ApplicationModuleMappingComponent implements OnInit {

  @Input() drawerClose: Function;
  @Input() data: Application;
  @Input() mappedCount:number;
  @Input() unmappedCount:number;
  @Input() mappedModuleData: string[];
  searchText: string = "";
  columns: string[][] =[["NAME","Name"]]

  ALL_STATUS=false
  footer=true
  isSpinning = false
  loadingRecords = true;
  
  constructor(private api:ApiService,private message:NzNotificationService) { }

  ngOnInit() {
  }

  close(): void {
    this.drawerClose();
  }

  search()
  {
    if (this.searchText != "") {
      var likeQuery = " AND";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
      console.log("likeQuery" + likeQuery);
    }
  }

  save()
  {
    this.isSpinning = true;
    console.log(this.mappedModuleData);
      this.api.addMappingModules(this.data.ID,this.mappedModuleData)
      .subscribe(successCode => {
        if(successCode['code']=="200")
        { 
            this.message.success("Modules added Successfully ...", "");
            this.drawerClose();
            this.isSpinning = false;
            this.ALL_STATUS=false
        }
        else
        {
          this.message.error("Modules assigning Failed...", "");
          this.isSpinning = false;
        }
      });
  }

  selectAll()
  {
    console.log(this.ALL_STATUS)
    this.mappedModuleData.forEach(element => {
      console.log(element['IS_SELECTED'])
      if(this.ALL_STATUS==true)
      element['IS_SELECTED']=true
      else
      element['IS_SELECTED']=false
   });
  }
}