import { Component, OnInit, ViewChild } from '@angular/core';
import { AwstemplateComponent } from '../awstemplate/awstemplate.component';
import { AwsbulksenderComponent } from '../awsbulksender/awsbulksender.component';
import { ApiService } from 'src/app/Service/api.service';
import { CookieService } from 'ngx-cookie-service';
import { AWSTemplets } from 'src/app/Models/AWSTemplets';
import { AWSSendMail } from 'src/app/Models/AWSSendMail';
@Component({
  selector: 'app-awsemails',
  templateUrl: './awsemails.component.html',
  styleUrls: ['./awsemails.component.css']
})
export class AwsemailsComponent implements OnInit {

  formTitle = "Scheduled Emails";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] =[ ["TEMPLATE_NAME","Template Name"],["SHEET_KEY","Sheet"],["TOTAL_RECORDS","Total"],["SENT_COUNT","Sent"],["FAILED_COUNT","Failed"],["PENDING_COUNT","Pending"],["START_TIME","Start Time"],["EXPECTED_END_TIME","Expected End Time"],["COMPLETED_TIME","Actual End Time"],["STATUS","Status"]]
 

  //drawer Variables
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: AWSTemplets = new AWSTemplets();
  drawerVisible1: boolean;
  drawerTitle1: string;
  drawerData1: AWSTemplets = new AWSTemplets();

  applicationId= Number(this.cookie.get('applicationId'))
 // applicationId=1

  constructor(private api: ApiService,private cookie: CookieService) { }
  ngOnInit() {
    this.search();
  }
  // Basic Methods
  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }
  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }
    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }
    console.log("search text:" + this.searchText);
    if (this.searchText != "") {
      var likeQuery = "";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
      console.log("likeQuery" + likeQuery);
    }
    else
    {
      likeQuery=" "
    }
    this.api.getScheduledEmails(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery).subscribe(data => {
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];
    }, err => {
      console.log(err);
    });
  }
  //Drawer Methods
  get closeCallback() {
    return this.drawerClose.bind(this);
  }
  get closeCallback1() {
    return this.drawerClose1.bind(this);
  }
 
  add(): void {
 
 
 
    this.drawerTitle = "Create New Template";
    this.drawerData = new AWSTemplets();
    this.drawerVisible = true;
  }
  edit(data:AWSTemplets): void {
    
    this.drawerTitle = "Update Template";
    this.drawerData = Object.assign({}, data);
    this.drawerVisible = true;
  
  }
  send(data:AWSTemplets): void {
    
    this.drawerTitle1 = "Send Emails";
    this.drawerData1 = Object.assign({}, data);
    this.drawerVisible1 = true;
  
  }
 

  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }
  drawerClose1(): void {
    this.search();
    this.drawerVisible1 = false;
  }
 
}