export class Roledetails {
    ID: number
    ROLE_ID: number
    FORM_ID:number
    SEQ_NO:number
    IS_ALLOWED:boolean
    FORM_NAME:string
    PARENT_ID:number
    PARENT_FORM_NAME:string
    LINK:string
    CLIENT_ID:number
}

