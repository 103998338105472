import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/Service/api.service';
import { ExportService } from 'src/app/Service/export.service';
import * as XLSX from 'xlsx';
import { differenceInCalendarDays, setHours } from 'date-fns';

@Component({
  selector: 'app-ticket-group-wise-time-taken-to-close',
  templateUrl: './ticket-group-wise-time-taken-to-close.component.html',
  styleUrls: ['./ticket-group-wise-time-taken-to-close.component.css']
})

export class TicketGroupWiseTimeTakenToCloseComponent implements OnInit {
  formTitle = "Ticket Group Wise Time Taken To Close";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  fileName = 'DeptWise.xlsx';
  dataList = [];
  dataListForExport = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] = [["TICKET_GROUP_VALUE", "Ticket Group"], ["CLOSED_BEFORE_24", "Closed Before 24 hrs"], ["CLOSED_BETWEEN_24_48", "Closed Between 24 To 48 hrs"], ["CLOSED_BETWEEN_48_72", "Closed Between 48 To 72 hrs"], ["CLOSED_AFTER_72", "Closed After 72 hrs"]];
  TICKET_GROUP = [];
  isSpinning = false;
  filterClass: string = "filter-visible";
  applicationId = Number(this.cookie.get('applicationId'));
  departmentId = Number(this.cookie.get('departmentId'));
  selectedDate: Date[] = [];
  dateFormat = 'dd/MM/yyyy';
  date: Date[] = [];
  data1 = [];
  index = 0;
  ticketQuestion = {};
  value1: string = "";
  value2: string = "";
  ticketGroups = [];
  userId = Number(this.cookie.get('userId'));
  roleId = Number(this.cookie.get('roleId'));
  orgId = Number(this.cookie.get('orgId'));
  deptId = Number(this.cookie.get('deptId'));
  branchId = Number(this.cookie.get('branchId'));
  designationId = Number(this.cookie.get('designationId'));
  date1 = new Date();
  date2 = new Date();
  today = new Date();
  orgName: string = this.api.ORGANIZATION_NAME;

  constructor(private api: ApiService, private datePipe: DatePipe, private cookie: CookieService, private _exportService: ExportService, private message: NzNotificationService) { }

  disabledToDate = (current: Date): boolean =>
    differenceInCalendarDays(current, this.date1 == null ? this.today : this.date1) < 0;

  onFromDateChange(fromDate) {
    if (fromDate == null)
      this.date2 = new Date();

    else
      this.date2 = new Date(fromDate);
  }

  setDateForDeptWiseFilter() {
    this.date = [];
    let currentDate = new Date();
    let previous30thDayDate = currentDate.setDate(currentDate.getDate() + (-30));
    this.date1 = new Date(previous30thDayDate);
    this.date2 = new Date();
  }

  ngOnInit() {
    this.setDateForDeptWiseFilter();

    this.api.getAllTicketGroups(0, 0, 'VALUE', 'ASC', " AND ORG_ID= " + this.cookie.get('orgId')).subscribe(data => {
      if (data['code'] == 200) {
        this.ticketGroups = data['data'];
      }

    }, err => {
      console.log(err);
    });

    if (this.roleId == 6)
      this.getDepartmentToShowReport();

    if (this.roleId == 4)
      this.getDepartmentSupportAgentWise();

    if ((this.roleId != 4) && (this.roleId != 6))
      this.search(true);

    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
  }

  supportAgentWiseDeptArray = [];

  getDepartmentSupportAgentWise() {
    this.supportAgentWiseDeptArray = [];

    this.api.gettickdeskSupportUserMapping(0, 0, 'ID', "ASC", ' AND EMPLOYEE_ID=' + this.userId).subscribe(data => {
      if (data['code'] == 200) {
        var supportAgentWiseDept = data['data'];

        for (var i = 0; i < supportAgentWiseDept.length; i++) {
          this.supportAgentWiseDeptArray.push(supportAgentWiseDept[i]['DEPARTMENT_ID']);
        }

        if (this.roleId == 4) {
          this.search(true);
        }
      }
    });
  }

  deptWiseReport = [];

  getDepartmentToShowReport() {
    this.deptWiseReport = [];

    this.api.gettickdeskDepartmentAdminMapping(0, 0, 'ID', "ASC", ' AND EMPLOYEE_ID=' + this.userId).subscribe(data => {
      if (data['code'] == 200) {
        var departments = data['data'];

        for (var i = 0; i < departments.length; i++) {
          this.deptWiseReport.push(departments[i]['DEPARTMENT_ID']);
        }

        if (this.roleId == 6) {
          this.search(true);
        }
      }
    });
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }

  exportexcel(): void {
    let element = document.getElementById('summer');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, this.fileName);
  }

  changeDate(value) {
    this.value1 = this.datePipe.transform(value[0], "yyyy-MM-dd")
    this.value2 = this.datePipe.transform(value[1], "yyyy-MM-dd");
  }

  search(reset: boolean = false, exportToExcel: boolean = false, exportToPDF: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    if (this.searchText != "") {
      likeQuery = " AND (";

      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ')';
    }

    var ticketGrroupFilter = "";
    if (this.TICKET_GROUP.length > 0)
      ticketGrroupFilter = " AND TICKET_GROUP_ID IN (" + this.TICKET_GROUP + ")";

    var supportAgentWiseDept = "";
    if (this.roleId == 4) {
      if (this.supportAgentWiseDeptArray.length > 0)
        supportAgentWiseDept = " AND DEPARTMENT_ID IN (" + this.supportAgentWiseDeptArray + ")";

      else
        supportAgentWiseDept = "";
    }

    var deptAdminWiseDept = "";
    if (this.roleId == 6) {
      if (this.deptWiseReport.length > 0)
        deptAdminWiseDept = " AND DEPARTMENT_ID IN (" + this.deptWiseReport + ")";

      else
        deptAdminWiseDept = "";
    }

    var dateFilter = "";
    if ((this.date1 != undefined) && (this.date2 != undefined)) {
      dateFilter = " AND (DATE BETWEEN '" + this.datePipe.transform(this.date1, 'yyyy-MM-dd 00:00:00') + "' AND '" + this.datePipe.transform(this.date2, 'yyyy-MM-dd 23:59:59') + "')";
    }

    if (exportToExcel) {
      this.exportLoading = true;

      this.api.getTicketGroupWiseTimeTakenToCloseReport(0, 0, this.sortKey, sort, likeQuery + ticketGrroupFilter + supportAgentWiseDept + deptAdminWiseDept + dateFilter + ' AND ORG_ID=' + this.cookie.get('orgId')).subscribe(data => {
        if (data['code'] == 200) {
          this.exportLoading = false;
          this.dataListForExport = data['data'];
          this.convertInExcel();
        }

      }, err => {
        if (err['ok'] == false)
          this.message.error("Server Not Found", "");
      });

    } else if (exportToPDF) {
      this.exportInPDFLoading = true;

      this.api.getTicketGroupWiseTimeTakenToCloseReport(0, 0, this.sortKey, sort, likeQuery + ticketGrroupFilter + supportAgentWiseDept + deptAdminWiseDept + dateFilter + ' AND ORG_ID=' + this.cookie.get('orgId')).subscribe(data => {
        if (data['code'] == 200) {
          this.exportInPDFLoading = false;
          this.dataListForExport = data['data'];
          this.isPDFModalVisible = true;
        }

      }, err => {
        if (err['ok'] == false)
          this.message.error("Server Not Found", "");
      });

    } else {
      this.loadingRecords = true;

      this.api.getTicketGroupWiseTimeTakenToCloseReport(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery + ticketGrroupFilter + supportAgentWiseDept + deptAdminWiseDept + dateFilter + ' AND ORG_ID=' + this.cookie.get('orgId')).subscribe(data => {
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.totalRecords = data['count'];
          this.dataList = data['data'];
        }

      }, err => {
        if (err['ok'] == false)
          this.message.error("Server Not Found", "");
      });
    }
  }

  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";

    else
      this.filterClass = "filter-visible";
  }

  clearFilter() {
    this.TICKET_GROUP = [];
    this.date = [];
    this.filterQuery = "";
    this.selectedDate = null;
    this.value1 = '';
    this.value2 = '';
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
    this.setDateForDeptWiseFilter();
    this.search(true);
  }

  applyFilter() {
    if (((this.date1 != null) && (this.date2 != null)) || (this.TICKET_GROUP.length > 0))
      this.isFilterApplied = "primary";

    else
      this.isFilterApplied = "default";

    this.search(true);
    this.filterClass = "filter-invisible";
  }

  exportLoading: boolean = false;

  importInExcel() {
    this.search(true, true);
  }

  convertInExcel() {
    var arry1 = [];
    var obj1: any = new Object();

    for (var i = 0; i < this.dataListForExport.length; i++) {
      obj1['Ticket Group'] = this.dataListForExport[i]['TICKET_GROUP_VALUE'];
      obj1['Closed Before 24 hrs'] = this.dataListForExport[i]['CLOSED_BEFORE_24'];
      obj1['Closed Between 24 To 48 hrs'] = this.dataListForExport[i]['CLOSED_BETWEEN_24_48'];
      obj1['Closed Between 48 To 72 hrs'] = this.dataListForExport[i]['CLOSED_BETWEEN_48_72'];
      obj1['Closed After 72 hrs'] = this.dataListForExport[i]['CLOSED_AFTER_72'];

      arry1.push(Object.assign({}, obj1));

      if (i == this.dataListForExport.length - 1) {
        this._exportService.exportExcel(arry1, 'Summary of Ticket Group Wise Time Taken To Close ' + this.datePipe.transform(new Date(), 'dd-MMM-yy, hh mm ss a'));
      }
    }
  }

  isPDFModalVisible: boolean = false;
  PDFModalTitle: string = "Export in PDF";
  exportInPDFLoading: boolean = false;
  ticketGroupsToPrint: string = "";

  importInPDF(departmentID) {
    this.ticketGroupsToPrint = "";
    this.search(false, false, true);
    let tempTicketGroupName = "";

    for (var i = 0; i < departmentID.length; i++) {
      let ticketGroups = this.ticketGroups.filter(obj1 => {
        return obj1.ID == departmentID[i];
      });

      tempTicketGroupName = tempTicketGroupName + ticketGroups[0]["VALUE"] + ", ";
    }

    this.ticketGroupsToPrint = tempTicketGroupName.substring(0, tempTicketGroupName.length - 2);
  }

  handlePDFModalCancel() {
    this.isPDFModalVisible = false;
  }

  getCurrentDateTime() {
    return new Date();
  }

  getUserName() {
    return this.api.userName;
  }

  getTicketGroups() {
    if (this.ticketGroupsToPrint == "")
      return "All";

    else
      return this.ticketGroupsToPrint;
  }
}
