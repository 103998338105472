import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './pages/CommonModule/Dashboard/dashboard/dashboard.component';
import { ApplicationsComponent } from './pages/CommonModule/application/applications/applications.component';
import { ModulesComponent } from './pages/CommonModule/module/modules/modules.component';
import { UsersComponent } from './pages/CommonModule/user/users/users.component';
import { RolesComponent } from './pages/CommonModule/role/roles/roles.component';
import { SelectpageComponent } from './pages/CommonModule/selectpage/selectpage/selectpage.component';
import { LoginComponent } from './login/login.component';
import { FaqHeadsComponent } from './pages/SupportModule/FaqHead/faq-heads/faq-heads.component';
import { FaqsComponent } from './pages/SupportModule/FAQ/faqs/faqs.component';
import { FormsComponent } from './pages/CommonModule/form/forms/forms.component';
import { DepartmentsComponent } from './pages/SupportModule/Department/departments/departments.component';
import { EmailsComponent } from './pages/CommunicationModule/EmailServer/emails/emails.component';
import { SmssComponent } from './pages/CommunicationModule/SMSServer/smss/smss.component';
import { FoldersComponent } from './pages/DocumentModule/folder/folders/folders.component';
import { TicketsComponent } from './pages/SupportModule/Tickets/tickets/tickets.component';
import { UserapplicationmappingComponent } from './pages/CommonModule/user/userapplicationmapping/userapplicationmapping.component';
import { UsermodulemappingComponent } from './pages/CommonModule/user/usermodulemapping/usermodulemapping.component';
import { UseraccessmappingComponent } from './pages/CommonModule/user/useraccessmapping/useraccessmapping.component';
import { UserrolemappingComponent } from './pages/CommonModule/user/userrolemapping/userrolemapping.component';
import { TasksComponent } from './pages/SchedularModule/Task/tasks/tasks.component';
import { ViewhistoryComponent } from './pages/SchedularModule/History/viewhistory/viewhistory.component';
import { LogmoduleComponent } from './pages/LogModule/logmodule/logmodule.component';
import { UserdepartmentmappingComponent } from './pages/CommonModule/user/userdepartmentmapping/userdepartmentmapping.component';
import { TicketgroupComponent } from './pages/SupportModule/Tickets/ticketgroup/ticketgroup.component';
import { TestComponent } from './pages/CommonModule/test/test.component';
import { MyticketComponent } from './pages/UserModule/myticket/myticket.component';
import { TicketreportComponent } from './pages/Reports/ticketreport/ticketreport.component';
import { SummaryreportComponent } from './pages/Reports/summaryreport/summaryreport.component';
import { UserwisesummaryComponent } from './pages/Reports/userwisesummary/userwisesummary.component';
import { DashboardreportComponent } from './pages/Report/dashboardreport/dashboardreport.component';
import { OrganizationsComponent } from './pages/Organizations/organizations/organizations.component';
import { BranchesComponent } from './pages/BranchMaster/branches/branches.component';
import { DesignationsComponent } from './pages/DesignationMaster/designations/designations.component';
import { TickRolesComponent } from './pages/RoleMaster/tick-roles/tick-roles.component';
import { FeaturesComponent } from './pages/FeatureMaster/features/features.component';
import { EmployeemasterlistComponent } from './pages/employeemaster/employeemasterlist/employeemasterlist.component';
import { ClustermasterlistComponent } from './pages/clustermaster/clustermasterlist/clustermasterlist.component';
import { EmployeerolemaplistComponent } from './pages/employeerolemap/employeerolemaplist/employeerolemaplist.component';
import { EmploginComponent } from './emplogin/emplogin.component';
import { SearchfaqComponent } from './pages/UserModule/searchfaq/searchfaq.component';
import { FolderMasterComponent } from './pages/FolderAndFileModule/Folder/folder-master/folder-master.component';
import { SharedFolderMasterComponent } from './pages/FolderAndFileModule/SharedFolder/shared-folder-master/shared-folder-master.component';
import { FaqresponsereportComponent } from './pages/Reports/faqresponsereport/faqresponsereport.component';
import { OptionreportComponent } from './pages/Reports/optionreport/optionreport.component';
import { BranchWiseSummaryComponent } from './pages/Reports/branch-wise-summary/branch-wise-summary.component';
import { TicketGroupWiseSummaryComponent } from './pages/Reports/ticket-group-wise-summary/ticket-group-wise-summary.component';
import { SupportAgentWiseSummaryComponent } from './pages/Reports/support-agent-wise-summary/support-agent-wise-summary.component';
import { DepartmentWiseTicketDetailsComponent } from './pages/Reports/department-wise-ticket-details/department-wise-ticket-details.component';
import { BranchWiseTicketDetailsComponent } from './pages/Reports/branch-wise-ticket-details/branch-wise-ticket-details.component';
import { TicketGroupWiseTicketDetailsComponent } from './pages/Reports/ticket-group-wise-ticket-details/ticket-group-wise-ticket-details.component';
import { SupportAgentWiseTicketDetailsComponent } from './pages/Reports/support-agent-wise-ticket-details/support-agent-wise-ticket-details.component';
import { SupportUserWiseTicketDetailsComponent } from './pages/Reports/support-user-wise-ticket-details/support-user-wise-ticket-details.component';
import { DeptWiseTimeTakenToCloseComponent } from './pages/Reports/dept-wise-time-taken-to-close/dept-wise-time-taken-to-close.component';
import { BranchWiseTimeTakenToCloseComponent } from './pages/Reports/branch-wise-time-taken-to-close/branch-wise-time-taken-to-close.component';
import { DesignationWiseTimeTakenToCloseComponent } from './pages/Reports/designation-wise-time-taken-to-close/designation-wise-time-taken-to-close.component';
import { TicketGroupWiseTimeTakenToCloseComponent } from './pages/Reports/ticket-group-wise-time-taken-to-close/ticket-group-wise-time-taken-to-close.component';
import { SupportAgentWiseTimeTakenToCloseComponent } from './pages/Reports/support-agent-wise-time-taken-to-close/support-agent-wise-time-taken-to-close.component';
import { BankListComponent } from './pages/BankMaster/bank-list/bank-list.component';

export const routes: Routes = [
  { path: 'login', redirectTo: 'login' },
  { path: 'login', component: LoginComponent },
  { path: 'adminlogin', component: EmploginComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'admindashboard', component: DashboardreportComponent },

  // { path: 'applications', component: ApplicationsComponent },
  // { path: 'modules', component: ModulesComponent },
  // { path: 'startpage', component: SelectpageComponent },
  // { path: 'supportmodule/departments', component: DepartmentsComponent },
  // { path: 'communicationmodule/emailserver', component: EmailsComponent },
  // { path: 'communicationmodule/smsserver', component: SmssComponent },

  { path: 'users', component: UsersComponent },
  { path: 'roles', component: RolesComponent },
  { path: 'forms', component: FormsComponent },
  { path: 'faqheads', component: FaqHeadsComponent },
  { path: 'faqs', component: FaqsComponent },
  { path: 'documentmodule/folders', component: FoldersComponent },
  { path: 'mytickets', component: MyticketComponent },
  { path: 'tickets', component: TicketsComponent },

  { path: 'department-wise-details', component: DepartmentWiseTicketDetailsComponent },
  { path: 'user-wise-details', component: SupportUserWiseTicketDetailsComponent },
  { path: 'branch-wise-details', component: BranchWiseTicketDetailsComponent },
  { path: 'ticket-group-wise-details', component: TicketGroupWiseTicketDetailsComponent },
  { path: 'support-agent-wise-details', component: SupportAgentWiseTicketDetailsComponent },
  { path: 'ticket-details', component: TicketreportComponent },

  { path: 'option-wise-summary', component: OptionreportComponent },
  { path: 'department-wise-summary', component: SummaryreportComponent },
  { path: 'user-wise-summary', component: UserwisesummaryComponent },
  { path: 'branch-wise-summary', component: BranchWiseSummaryComponent },
  { path: 'ticket-group-wise-summary', component: TicketGroupWiseSummaryComponent },
  { path: 'support-agent-wise-summary', component: SupportAgentWiseSummaryComponent },

  { path: 'department-wise-time-taken-to-close', component: DeptWiseTimeTakenToCloseComponent },
  { path: 'branch-wise-time-taken-to-close', component: BranchWiseTimeTakenToCloseComponent },
  { path: 'designation-wise-time-taken-to-close', component: DesignationWiseTimeTakenToCloseComponent },
  { path: 'ticket-group-wise-time-taken-to-close', component: TicketGroupWiseTimeTakenToCloseComponent },
  { path: 'support-agent-wise-time-taken-to-close', component: SupportAgentWiseTimeTakenToCloseComponent },

  // { path: 'userapplicationmapping', component: UserapplicationmappingComponent },
  // { path: 'usermodulemapping', component: UsermodulemappingComponent },
  // { path: 'useraccessmapping', component: UseraccessmappingComponent },
  // { path: 'userrolemapping', component: UserrolemappingComponent },
  // { path: 'userdepartmentmapping', component: UserdepartmentmappingComponent },
  // { path: 'Schedularmodule/tasks', component: TasksComponent },
  // { path: 'Schedularmodule/taskshistory', component: ViewhistoryComponent },
  // { path: 'loggermodule/loghistory', component: LogmoduleComponent },
  // { path: 'test', component: TestComponent },

  { path: 'ticketgroups', component: TicketgroupComponent },
  { path: 'organizations', component: OrganizationsComponent },
  { path: 'departments', component: DepartmentsComponent },
  { path: 'branch', component: BranchesComponent },
  { path: 'designation', component: DesignationsComponent },
  { path: 'tickrole', component: TickRolesComponent },
  { path: 'features', component: FeaturesComponent },
  { path: 'employee', component: EmployeemasterlistComponent },
  { path: 'cluster', component: ClustermasterlistComponent },
  { path: 'employeeRoleMapping', component: EmployeerolemaplistComponent },
  { path: 'help', component: SearchfaqComponent },
  { path: 'folders', component: FolderMasterComponent },
  { path: 'sharedfolders', component: SharedFolderMasterComponent },
  { path: 'faqresponses', component: FaqresponsereportComponent },
  { path: 'bank', component: BankListComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
