import { Component, OnInit, Input } from '@angular/core';
import { Emailhistory } from 'src/app/Models/emailhistory';

@Component({
  selector: 'app-viewbody',
  templateUrl: './viewbody.component.html',
  styleUrls: ['./viewbody.component.css']
})
export class ViewbodyComponent implements OnInit {

  @Input() drawerClose: Function;
  @Input() data: Emailhistory;
  constructor() { }

  ngOnInit() {
  }

}
