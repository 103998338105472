import { Component, OnInit, ViewChild } from '@angular/core';
import { Designationmaster } from 'src/app/Models/Designationmaster';
import { ApiService } from 'src/app/Service/api.service';
// import { DepartmentcalenderComponent } from '../departmentcalender/departmentcalender/departmentcalender.component';
import { CookieService } from 'ngx-cookie-service';
import { DepartmentworkingDetails } from 'src/app/Models/departmentworking-details';
import { TickRole } from 'src/app/Models/tickrole';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-tick-roles',
  templateUrl: './tick-roles.component.html',
  styleUrls: ['./tick-roles.component.css']
})

export class TickRolesComponent implements OnInit {
  formTitle = "Manage Roles";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";
  orgId = this.cookie.get('orgId')
  isFilterApplied: string = "default";
  columns: string[][] = [["NAME", "Roles Name"], ["SHORT_CODE", "Short Code"], ["SEQUENCE_NO", "Sequence No."], ["START_PAGE", "Start Page"]];
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: TickRole = new TickRole();
  applicationId = Number(this.cookie.get('applicationId'));
  drawerVisible1: boolean;
  drawerTitle1: string;
  drawerData1: TickRole = new TickRole();
  listOfData: DepartmentworkingDetails[] = [];
  OPEN_TIME2 = null;
  CLOSE_TIME2 = null;
  DAYS = false;

  constructor(private api: ApiService, private cookie: CookieService) { }

  ngOnInit() {
    this.search();
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }

  search(reset: boolean = false) {
    var filter = ""
    if (reset) {
      this.pageIndex = 1;
    }

    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    console.log("search text:" + this.searchText);
    if (this.searchText != "") {
      var likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ')'
    }

    console.log(likeQuery);

    if (likeQuery)
      filter += likeQuery;

    this.api.getAllRoles(this.pageIndex, this.pageSize, this.sortKey, sort, filter).subscribe(data => {
      console.log(data['data']);
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];

    }, err => {
      console.log(err);
    });
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  get closeCallback1() {
    return this.drawerClose1.bind(this);
  }

  add(): void {
    this.drawerTitle = "Create New Role";
    this.drawerData = new TickRole();

    this.listOfData = []
    this.api.getAllRoles(1, 1, 'SEQUENCE_NO', 'desc', '').subscribe(data => {
      if (data['count'] == 0) {
        this.drawerData.SEQUENCE_NO = 1;

      } else {
        this.drawerData.SEQUENCE_NO = Number(data['data'][0]['SEQUENCE_NO']) + 1;
      }

    }, err => {
      console.log(err);
    })

    for (let i = 0; i < 7; i++) {
      this.listOfData.push({
        ID: 0,
        DAY: i,
        IS_HOLIDAY: false,
        OPEN_TIME: null,
        CLIENT_ID: this.api.clientId,
        DEPARTMENT_ID: 0,
        DATE: "",
        CLOSE_TIME: null
      });
    }

    this.OPEN_TIME2 = null
    this.CLOSE_TIME2 = null
    this.DAYS = false;
    this.drawerVisible = true;
  }

  edit(data: TickRole): void {
    this.drawerTitle = "Update Role";
    this.drawerData = Object.assign({}, data);
    this.drawerVisible = true;
  }

  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }

  drawerClose1(): void {
    this.drawerVisible1 = false;
  }
}