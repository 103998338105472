
export class OrganizationMaster {
    ID: number;
    NAME: string;
    ADDRESS_LINE_1: string;
    ADDRESS_LINE_2: string;
    LONGITUTE: string;
    LATITUTE: string;
    ADMIN_NAME: string;
    EMAIL_ID: string;
    PASSWORD: any;
    CITY: string;
    TEHSIL: string;
    DISTRICT: string;
    STATE: string;
    COUNTRY: string;
    MOBILE_NUMBER: number;
    PINCODE: number;
    CLIENT_ID: number;
    STATUS: Boolean = true;
    USER_ID = 0
    ROLE_ID = 0
}
