import { Component, OnInit,Input } from '@angular/core';
import { User } from 'src/app/Models/user';
import { ApiService } from 'src/app/Service/api.service';
import { NzNotificationService } from 'ng-zorro-antd';

@Component({
  selector: 'app-user-module-mapping',
  templateUrl: './user-module-mapping.component.html',
  styleUrls: ['./user-module-mapping.component.css']
})
export class UserModuleMappingComponent implements OnInit {

  @Input() drawerClose: Function;
  @Input() data: User;
  @Input() mappedApplicationModuleData: string[];

  isSpinning = false
  loadingRecords = true;
  
  constructor(private api:ApiService,private message:NzNotificationService) { }

  ngOnInit() {
  }

  close(): void {
    this.drawerClose();
  }

  save()
  {
    this.isSpinning = true;
    console.log(this.mappedApplicationModuleData);
      this.api.addApplicationModuleMapping(this.data.ID,this.mappedApplicationModuleData)
      .subscribe(successCode => {
        if(successCode['code']=="200")
        { 
            this.message.success("Aplication Modules added Successfully ...", "");
            this.drawerClose();
            this.isSpinning = false;
        }
        else
        {
          this.message.error("Aplication Modules assigning Failed...", "");
          this.isSpinning = false;
        }
      });
  }
}