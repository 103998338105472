import { Component, OnInit,Input } from '@angular/core';
import { Smsserver } from 'src/app/Models/smsserver';
import { Smshistory } from 'src/app/Models/smshistory';
import { NzNotificationService } from 'ng-zorro-antd';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-smshistory',
  templateUrl: './smshistory.component.html',
  styleUrls: ['./smshistory.component.css']
})
export class SMSHistoryComponent implements OnInit {

  @Input() drawerClose: Function;
  @Input() data: Smsserver;
  emilHistoryData:Smshistory
  dateMode = 'time';

  
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterClass: string = "filter-visible";
  initFilter = true;
  isFilterApplied: string = "default";
  isSpinning:boolean
  columns: string[][] =[ ["SENDER_ID","Sender Name"],["TO","To"],["SMS","SMS"],["STATUS","Status"],["SCHEDULED_DATE_TIME","Scheduled Date Time"]]
  
 SMS_SERVER_ID:Number
  STATUS="S"
  filterQuery:string=""

  constructor(private api: ApiService,private message: NzNotificationService) { }

  ngOnInit() {
    this.search();
  }

  edit(data:Smshistory)
  {
    if(data.STATUS=="P")
    {
    this.api.updateSmsHistory(data)
    .subscribe(successCode => {
      if (successCode['code'] == "200") {
        this.message.success("SMS History information updated Successfully...", "");
      }
      else {
        this.message.error("Failed to update SMS History information...", "");
      }
    });
  }
  }

  
  visible(status)
  {
    if(status=="P")
    return "update"
    else
    return ""
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }
  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }
    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }
    console.log(sort)
    console.log("search text:" + this.searchText);
    if (this.searchText != "") {
      
      var likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " `" + column[0] + "` like ('%" + this.searchText + "%') OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 3) + ")";
    }
else
{
  if(this.SMS_SERVER_ID!=undefined)
  {
    this.filterQuery="AND SMS_SERVER_ID=" + this.SMS_SERVER_ID +" AND STATUS='"+this.STATUS+"' "
    this.applyFilter(this.SMS_SERVER_ID)
  }

}
    var filter=""

    if (likeQuery)
      filter = this.filterQuery + likeQuery
    else
      filter = this.filterQuery

   console.log(filter)
   if(filter!="")
   {
    this.api.getAllSmsHistory(0, 0, this.sortKey, sort,filter).subscribe(data => {
      console.log(data)
       this.loadingRecords = false;
       this.totalRecords = data['count'];
       this.dataList = data['data'];
     }, err => {
       console.log(err);
     });
   }

  }

  showFilter()
  {
    if (this.filterClass === "filter-visible")
    this.filterClass = "filter-invisible";
  else
    this.filterClass = "filter-visible";

  }

  applyFilter(id?)
{
 this.SMS_SERVER_ID=id
  this.isSpinning=true
  var sort: string;
  try {
    sort = this.sortValue.startsWith("a") ? "asc" : "desc";
  } catch (error) {
    sort = "";
  }
  this.filterQuery="AND SMS_SERVER_ID=" + this.SMS_SERVER_ID +" AND STATUS='"+this.STATUS+"' "
this.api.getAllSmsHistory(0, 0, this.sortKey, sort, this.filterQuery).subscribe(data => {
  console.log(data)
   this.totalRecords = data['count'];
   this.dataList = data['data'];
   this.loadingRecords = false;
   this.filterClass = "filter-invisible";
   this.isFilterApplied="primary"
   this.isSpinning=false
 }, err => {
   console.log(err);
 });
}


  clearFilter()
  {
    this.STATUS="P"
    this.isFilterApplied="default"
    this.filterClass = "filter-invisible";
    this.applyFilter(this.SMS_SERVER_ID)
  }

  handleDateOpenChange(open: boolean): void {
    if (open) {
      this.dateMode = 'time';
    }
  }

  handleDatePanelChange(mode: string): void {
    console.log('handleDatePanelChange: ', mode);
  }

  getStatus(status)
  {
if(status=="P")
return "Pending"
else if(status=="S")
return "Sent"
else if(status=="F")
return "Failed"
else if(status=="D")
return "Delivered"
else
return "Rejected"
  }
}
