export class User {
    ID:number;
    CLIENT_ID:number;
    NAME:string;
    EMAIL_ID:string;
    MOBILE:string;
    IS_ACTIVE:boolean;
    WHATS_APP_NO:string
    PASSWORD:string;
    ROLE_ID=0
}
