import { Component, OnInit,Input } from '@angular/core';
import { Emailserver } from 'src/app/Models/emailserver';
import { ApiService } from 'src/app/Service/api.service';
import { NzNotificationService } from 'ng-zorro-antd';


@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.css']
})
export class EmailComponent implements OnInit {
  
  @Input() drawerClose: Function;
  @Input() data: Emailserver;
  isSpinning = false
  passwordVisible = false;
  password: string;

  constructor(private api: ApiService, private message: NzNotificationService) {
  }

  ngOnInit() {
  
  }
  


  close(): void {
    this.drawerClose();
  }

  save(addNew: boolean): void {


    this.isSpinning = true;
    if (this.data.SENDER_NAME != undefined && this.data.SENDER_NAME != "")
    {
      if (this.data.ID) {
        this.api.updateEmailServer(this.data)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
              this.message.success("EmailServer information updated Successfully...", "");
              if (!addNew)
                this.drawerClose();
              this.isSpinning = false;
            }
            else {
              this.message.error("Failed to update emailServer information...", "");
              this.isSpinning = false;
            }
          });
      }
      else {

        this.api.createEmailServer(this.data)
          .subscribe(successCode => {
            console.log(successCode)
            if (successCode['code'] == "200") {
              this.message.success("EmailServer information added successfully...", "");
              if (!addNew)
                this.drawerClose();
              else {
                this.data = new Emailserver();
              }
              this.isSpinning = false;
            }
            else {
              this.message.error("Failed to add emailServer information...", "");
              this.isSpinning = false;
            }
          });
      }
    }
    else
    {
      this.message.error("Please Fill All Required Fields...", "");
      this.isSpinning = false;
    }
  }


}