import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ticketgroups',
  templateUrl: './ticketgroups.component.html',
  styleUrls: ['./ticketgroups.component.css']
})
export class TicketgroupsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
