import { Component, OnInit, Input } from '@angular/core';
import { Designationmaster } from 'src/app/Models/Designationmaster';
import { ApiService } from 'src/app/Service/api.service';
import { NzNotificationService } from 'ng-zorro-antd';
import { DepartmentworkingDetails } from 'src/app/Models/departmentworking-details';
import { DatePipe } from '@angular/common';
import { TickRole } from 'src/app/Models/tickrole';
import { NgForm } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-tick-role',
  templateUrl: './tick-role.component.html',
  styleUrls: ['./tick-role.component.css']
})

export class TickRoleComponent implements OnInit {
  @Input() drawerClose: Function;
  @Input() data: TickRole;
  isSpinning = false
  @Input() listOfData: DepartmentworkingDetails[] = [];
  OPEN_TIME
  totalRecords = 1;
  CLOSE_TIME
  @Input() OPEN_TIME2
  @Input() CLOSE_TIME2
  fKey = ""
  date = new Date();
  orgId = this.cookie.get('orgId')
  date1 = this.datePipe.transform(this.date, 'yyyyMMddHHmmss')
  fileDataLOGO_URL: File = null
  folderName = "departmentIcon"
  listdata2 = []
  listdata1 = []
  @Input() DAYS = false;
  namepatte = /^[A-Za-z ]*$/
  namepattern = /[a-zA-Z][a-zA-Z ]+/
  regexp = /^\S/
  spatt = /^[a-zA-Z0-9 ]+$/
  isOk = true;
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "ID";
  pageIndex = 1;
  pageSize = 10;
  dataList = [];

  constructor(private api: ApiService, private datePipe: DatePipe, private message: NzNotificationService, private cookie: CookieService) { }

  ngOnInit() {
    this.getallorg1();
    this.getallorg2();
    this.CreateData();

    this.api.getAllRoles(0, 0, this.sortKey, '', ' AND STATUS=1').subscribe(data => {
      console.log(data['data'])
      this.loadingRecords = false;
      this.dataList = data['data'];

    }, err => {
      console.log(err);
    });
  }

  designation() {
    this.api.getAllRoles(0, 0, this.sortKey, '', ' AND STATUS=1').subscribe(data => {
      console.log(data['data'])
      this.loadingRecords = false;
      this.dataList = data['data'];

    }, err => {
      console.log(err);
    });
  }

  close(accountMasterPage: NgForm) {
    this.drawerClose();
    this.resetDrawer(accountMasterPage);
  }

  resetDrawer(accountMasterPage: NgForm) {
    accountMasterPage.form.reset();
    this.add();
  }

  setAllDays(event) {
    this.DAYS = event;
  }

  setOpenTime(event) {
    this.OPEN_TIME2 = event;
    console.log(this.OPEN_TIME2);

    for (let i = 0; i < 7; i++) {
      if (this.listOfData[i]['IS_HOLIDAY'] == false) {
        this.listOfData[i]['OPEN_TIME'] = this.OPEN_TIME2;
        this.listOfData[i]['CLOSE_TIME'] = this.CLOSE_TIME2;
      }
      if (this.listOfData[i]['IS_HOLIDAY'] == true) {
        this.listOfData[i]['OPEN_TIME'] = this.OPEN_TIME;
        this.listOfData[i]['CLOSE_TIME'] = this.CLOSE_TIME;
      }
    }
  }

  setCloseTime(event) {
    this.CLOSE_TIME2 = event;
    for (let i = 0; i < 7; i++) {
      if (this.listOfData[i]['IS_HOLIDAY'] == false) {
        this.listOfData[i]['OPEN_TIME'] = this.OPEN_TIME2;
        this.listOfData[i]['CLOSE_TIME'] = this.CLOSE_TIME2;
      }

      if (this.listOfData[i]['IS_HOLIDAY'] == true) {
        this.listOfData[i]['OPEN_TIME'] = this.OPEN_TIME;
        this.listOfData[i]['CLOSE_TIME'] = this.CLOSE_TIME;
      }
    }
  }

  CreateData() {
    for (let i = 0; i < 7; i++) {
      this.listOfData.push({
        ID: 0,
        DAY: i,
        IS_HOLIDAY: false,
        OPEN_TIME: this.OPEN_TIME,
        CLIENT_ID: this.api.clientId,
        DEPARTMENT_ID: 0,
        DATE: "",
        CLOSE_TIME: this.CLOSE_TIME
      });
    }
  }

  getDay(day: number) {
    switch (day) {
      case 0:
        return "Sunday"
        break;
      case 1:
        return "Monday"
        break;
      case 2:
        return "Tuesday"
        break;
      case 3:
        return "Wednesday"
        break;
      case 4:
        return "Thursday"
        break;
      case 5:
        return "Friday"
        break;
      case 6:
        return "Saturday"
        break;
    }
  }

  ///Allow only characters
  alphaOnly(event) {
    event = (event) ? event : window.event;
    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 32 && (charCode < 65 || charCode > 90) && (charCode < 97 || charCode > 122)) {
      return false;
    }

    return true;
  }

  ////only number and character accept
  clsAlphaNoOnly(event) {
    var regex = new RegExp("^[a-zA-Z0-9 ]+$");
    var str = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (regex.test(str)) {
      return true;
    }

    event.preventDefault();
    return false;
  }

  onFileSelectedLOGO_URL(event) {
    this.fileDataLOGO_URL = <File>event.target.files[0];
    console.log(this.fileDataLOGO_URL)
    var fileExt = this.fileDataLOGO_URL.name.split('.').pop();
  }

  getallorg1() {
    this.api.getAllRoles(0, 0, 'ID', 'desc', "").subscribe(data => {
      if (data['code'] == 200) {
        this.listdata1 = data['data'];
        console.log(this.listdata1)
      }

    }, err => {
      console.log(err);
    });
  }

  getallorg2() {
    this.api.getAllRoles(0, 0, 'ID', 'desc', "").subscribe(data => {
      if (data['code'] == 200) {
        this.listdata2 = data['data'];
        console.log(this.listdata2)
      }
    }, err => {
      console.log(err);
    });
  }

  add(): void {
    this.api.getAllRoles(1, 1, 'SEQUENCE_NO', 'desc', '').subscribe(data => {
      if (data['count'] == 0) {
        this.data.SEQUENCE_NO = 1;

      } else {
        this.data.SEQUENCE_NO = Number(data['data'][0]['SEQUENCE_NO']) + 1;
        this.data.STATUS = true;
      }
    }, err => {
      console.log(err);
    })
  }

  save(addNew: boolean, accountMasterPage: NgForm): void {
    this.isSpinning = false;
    this.isOk = true;

    if (
      this.data.PARENT_ROLE_ID == null &&
      this.data.NAME.trim() == '' &&
      this.data.SHORT_CODE.trim() == ''
    ) {
      this.isOk = false;
      this.message.error('Please Fill All Required Information', '');

    } else if (this.data.PARENT_ROLE_ID == null || this.data.PARENT_ROLE_ID == undefined) {
      this.isOk = false;
      this.message.error("Please Select Parent Role", '');

    } else if (this.data.NAME.trim() == '' || this.data.NAME == null) {
      this.isOk = false;
      this.message.error("Please Enter Roles Name", '');

    } else if (!this.namepatte.test(this.data.NAME.toString())) {
      this.isOk = false;
      this.message.error("Please Enter Valid Roles Name", '');

    } else if (this.data.SHORT_CODE == null || this.data.SHORT_CODE.trim() == '') {
      this.isOk = false;
      this.message.error("Please Enter Short Code", '');

    } else if (!this.spatt.test(this.data.SHORT_CODE.toString())) {
      this.isOk = false;
      this.message.error("Please Enter Valid Short Code", '');

    } else if (this.isOk) {
      this.isSpinning = true;

      if (this.data.START_PAGE.trim() == "")
        this.data.START_PAGE = "/dashboard";

      if (this.data.ID) {
        var emailData2 = this.listdata2.filter(obj => {
          return (obj.SHORT_CODE == this.data.SHORT_CODE && obj.ID != this.data.ID);
        });

        if (emailData2.length == 0) {
          this.api.updateRole(this.data).subscribe(successCode => {
            if (successCode['code'] == "200") {
              this.message.success("Role Information Updated Successfully...", "");

              if (!addNew)
                this.drawerClose();

              this.resetDrawer(accountMasterPage);
              this.isSpinning = false;

            } else {
              this.message.error("Role Information Updation Failed...", "");
              this.isSpinning = false;
            }
          });

        } else {
          this.message.error("Shortcode Exist Please Enter Other Shortcode...", "");
          this.isSpinning = false;
        }

      } else {
        var emailData = this.listdata1.filter(obj => {
          return obj.SHORT_CODE == this.data.SHORT_CODE;
        });

        console.log(this.data);

        if (emailData.length == 0) {
          this.api.createRole(this.data).subscribe(successCode => {
            if (successCode['code'] == "200") {
              this.isSpinning = false;
              this.message.success("Role Information Created Successfully...", "");
              this.designation();

              if (!addNew) {
                this.drawerClose();
                this.resetDrawer(accountMasterPage);

              } else {
                this.data = new TickRole();
                this.resetDrawer(accountMasterPage);
                this.api.getAllRoles(1, 1, 'SEQUENCE_NO', 'desc', '').subscribe(data => {
                  if (data['count'] == 0) {
                    this.data.SEQUENCE_NO = 1;

                  } else {
                    this.data.SEQUENCE_NO = data['data'][0]['SEQUENCE_NO'] + 1;
                  }
                }, err => {
                  console.log(err);
                })
              }

            } else {
              this.message.error("Role Information Creation Failed...", "");
              this.isSpinning = false;
            }
          });

        } else {
          this.message.error("Shortcode Exist Please Enter Other Shortcode", "");
          this.isSpinning = false;
        }
      }
    }

  }

}
