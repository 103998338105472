import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/Service/api.service';
import { CookieService } from 'ngx-cookie-service';
import { Ticketgroup } from 'src/app/Models/ticketgroup';
import { DatePipe } from '@angular/common';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-optionreport',
  templateUrl: './optionreport.component.html',
  styleUrls: ['./optionreport.component.css']
})

export class OptionreportComponent implements OnInit {
  formTitle = "Option Wise Summary";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  fileName = 'OptionWise.xlsx';
  dataList = [];
  userId = this.cookie.get('userId')
  roleId = this.cookie.get('roleId')
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] = [["TICKET_GROUP_VALUE", "Option"], ["TOTAL", "Total Tickets Loged"], ["ASSIGNED", "Assigned"], ["BANNED", "Banned"], ["CLOSED", "Closed"], ["CREATED", "Created"], ["ON_HOLD", "On-Hold"], ["RE_OPEN", "Re-Open"]];
  STATUS = "AL";
  DEPARTMENT = 'AL';
  SUPPORT_USER = 'AL';
  isSpinning = false
  filterClass: string = "filter-visible";
  applicationId = Number(this.cookie.get('applicationId'));
  departmentId = Number(this.cookie.get('departmentId'));
  selectedDate: Date[] = [];
  dateFormat = 'dd/MM/yyyy';
  data1 = [];
  ticketGroups: Ticketgroup[] = [];
  index = 0;
  ticketQuestion = {};
  value1: string = "";
  value2: string = "";
  departments = [];
  supportusers = [];
  orgName: string = this.api.ORGANIZATION_NAME;

  constructor(private api: ApiService, private datePipe: DatePipe, private cookie: CookieService) { }

  ngOnInit() {
    this.api.getOptionwiseReport(0, 0, 'NAME', 'ASC', ' AND ORG_ID= ' + this.cookie.get('orgId'), '', '').subscribe(data => {
      if (data['code'] == 200) {
        this.departments = data['data'];
      }

    }, err => {
      console.log(err);
    });

    this.getDepartmentToShowReport();
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
  }

  deptWiseReport = [];

  getDepartmentToShowReport() {
    this.deptWiseReport = [];

    this.api.gettickdeskDepartmentAdminMapping(0, 0, 'ID', "ASC", ' AND EMPLOYEE_ID=' + this.userId).subscribe(data => {
      if (data['code'] == 200) {
        var departments = data['data'];

        for (var i = 0; i < departments.length; i++) {
          this.deptWiseReport.push(departments[i]['DEPARTMENT_ID']);
        }

        this.search(true);
      }
    });
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    console.log("search text : " + this.searchText);

    if (this.searchText != "") {
      likeQuery = " AND (";

      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ')';
    }

    var deptFilter = "";
    if (this.DEPARTMENT == 'AL') {
      deptFilter = "";

    } else {
      deptFilter = " AND DEPARTMENT_ID='" + this.DEPARTMENT + "'";
    }

    var deptWiseReport = "";
    if (this.deptWiseReport.length > 0)
      deptWiseReport = " AND DEPARTMENT_ID IN (" + this.deptWiseReport + ")";

    else
      deptWiseReport = "";

    this.api.getOptionwiseReport(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery + deptFilter + deptWiseReport, this.applicationId, 0).subscribe(data => {
      if (data['code'] == 200) {
        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];
      }

    }, err => {
      console.log(err);
    });
  }

  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";

    else
      this.filterClass = "filter-visible";
  }

  applyFilter() {
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
    this.search(true);
  }

  clearFilter() {
    this.DEPARTMENT = "AL";
    this.filterQuery = "";
    this.selectedDate = null;
    this.value1 = '';
    this.value2 = '';
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
    this.search(true);
  }

  exportexcel(): void {
    let element = document.getElementById('summer');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, this.fileName);
  }

  changeDate(value) {
    this.value1 = this.datePipe.transform(value[0], "yyyy-MM-dd");
    this.value2 = this.datePipe.transform(value[1], "yyyy-MM-dd");
  }
}