import { DatePipe } from '@angular/common';
import { HttpEventType } from '@angular/common/http';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd';
import { fileMaster } from 'src/app/Models/fileMaster';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-file-drawer',
  templateUrl: './file-drawer.component.html',
  styleUrls: ['./file-drawer.component.css'],
  providers: [DatePipe]
})

export class FileDrawerComponent implements OnInit {
  @Input() drawerClose: Function;
  @Input() data: fileMaster;
  @Input() folderID: any;

  isSpinning = false;
  forms: fileMaster[];

  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe) { }

  ngOnInit() {
    this.getFolders();
  }

  parentFolder = [];

  getFolders() {
    this.api.getAllMyFolders(0, 0, 'ID', 'desc', '').subscribe(data => {
      this.parentFolder = data['data'];

    }, err => {
      console.log(err);
    });
  }

  close(myForm: NgForm): void {
    this.fileURL = null;
    this.tags = undefined;
    this.percent = 0;

    this.data.FOLDER_ID = String(this.folderID);

    if (this.timer != undefined)
      this.timer.unsubscribe();

    this.drawerClose();
    this.stopInterval();
    this.progressBar = false;
    this.getFolders();
    this.resetDrawer(myForm);
  }

  save(addNew: boolean, myForm: NgForm): void {
    var isOk = true;

    if (this.data.FOLDER_ID == undefined) {
      isOk = false;
      this.message.error("Please Select Valid Folder", "");
    }

    // if (this.data.NAME != undefined) {
    //   if (this.data.NAME.trim() != '') {
    //     if (!this.api.checkTextBoxIsValid(this.data.NAME)) {
    //       isOk = false;
    //       this.message.error("Please Enter Valid File Name", "");
    //     }
    //   } else {
    //     isOk = false;
    //     this.message.error("Please Enter Valid File Name", "");
    //   }
    // } else {
    //   isOk = false;
    //   this.message.error("Please Enter Valid File Name", "");
    // }

    // if (this.data.DESCRIPTION != undefined) {
    //   if (this.data.DESCRIPTION.trim() == '') {
    //     isOk = false;
    //     this.message.error("Please Enter Valid Description", "");
    //   }
    // } else {
    //   isOk = false;
    //   this.message.error("Please Enter Valid Description", "");
    // }

    // this.data.URL = this.uploadedAttachmentStr;

    if (this.fileURL != undefined) {
      if (this.fileURL == null) {
        isOk = false;
        this.message.error("Please Select Valid Attachment", "");
      }
    } else {
      isOk = false;
      this.message.error("Please Select Valid Attachment", "");
    }

    if (isOk) {
      this.isSpinning = true;

      this.data.CREATED_DATE_TIME = this.datePipe.transform(new Date(), "yyyy-MM-dd HH:mm") + ":00";
      this.data.OWNER_ID = this.api.userId;
      this.data.TAGS = this.tags.toString();
      if (this.data.DESCRIPTION == undefined) {
        this.data.DESCRIPTION = ' ';
      }

      console.log(this.data);
      this.imageUpload(myForm, addNew);
    }
  }

  fileURL: File = null;
  fileSizeType: String = "";

  onFileSelected(event) {
    this.fileSizeType = "";
    this.fileURL = <File>event.target.files[0];
    let typeArry = event.target.files[0].name.split('.');
    this.data.TYPE = event.target.files[0].name.split('.')[typeArry.length - 1];

    let fileSize = Number(parseFloat(String((event.target.files[0].size / 1024) / 1024)).toFixed(0));
    if (fileSize != 0) {
      this.data.SIZE_KB = Number(parseFloat(String((event.target.files[0].size / 1024) / 1024)).toFixed(2));
      this.fileSizeType = " MB";

    } else {
      this.data.SIZE_KB = Number(parseFloat(String((event.target.files[0].size / 1024))).toFixed(0));
      this.fileSizeType = " KB";
    }

    if (event.target.files[0].name.length > 60) {
      this.message.error("File Name is too Long, Please Check", "");
      this.clear();
    }
  }

  clear() {
    this.fileURL = null;
    this.data.TYPE = null;
    this.data.SIZE_KB = null;
  }

  tags = [];

  showTags(tagStr) {
    if (tagStr != "") {
      this.tags = tagStr.split(',');
    }
  }

  inputVisible = false;
  inputValue = '';
  @ViewChild('inputElement', { static: false }) inputElement?: ElementRef;

  handleClose(removedTag: {}): void {
    this.tags = this.tags.filter(tag => tag !== removedTag);
  }

  sliceTagName(tag: string): string {
    const isLongTag = tag.length > 20;
    return isLongTag ? `${tag.slice(0, 20)}...` : tag;
  }

  showInput(): void {
    this.inputVisible = true;
    setTimeout(() => { this.inputElement.nativeElement.focus(); }, 10);
  }

  handleInputConfirm(): void {
    if (this.inputValue && this.tags.indexOf(this.inputValue) === -1) {
      this.tags = [...this.tags, this.inputValue];
    }
    this.inputValue = '';
    this.inputVisible = false;
  }

  uploadedAttachmentStr: string;
  progressBar = false;

  imageUpload(myForm, addNew) {
    this.uploadedAttachmentStr = '';
    this.progressBar = true;

    if (!this.data.ID) {
      if (this.fileURL) {
        this.timer = this.api.onFileUpload1(this.data.FOLDER_ID, this.fileURL, this.api.userId, this.tags.toString(), this.data.DESCRIPTION).subscribe(res => {
          if (res.type === HttpEventType.Response) {
            // console.log('Upload complete');
            this.start(myForm, addNew);
          }

          if (res.type === HttpEventType.UploadProgress) {
            const percentDone = Math.round(100 * res.loaded / res.total);
            // console.log('Progress ' + percentDone + '%');
            this.percent = percentDone;
          }

        }, err => {
          if (err['ok'] == false)
            this.message.error("Failed to Upload the File", "");
        });
      }

    } else {
      if (!this.fileURL) {
        this.uploadedAttachmentStr = this.data.URL;

      } else {
        this.timer = this.api.onFileUpload1(this.data.FOLDER_ID, this.fileURL, this.api.userId, this.tags.toString(), this.data.DESCRIPTION).subscribe(res => {
          if (res.type === HttpEventType.Response) {
            // console.log('Upload complete');
            this.start(myForm, addNew);
          }

          if (res.type === HttpEventType.UploadProgress) {
            const percentDone = Math.round(100 * res.loaded / res.total);
            // console.log('Progress ' + percentDone + '%');
            this.percent = percentDone;
          }

        }, err => {
          if (err['ok'] == false)
            this.message.error("Failed to Upload the File", "");
        });
      }
    }
  }

  percent = 0;

  increase(myForm, addNew): void {
    this.percent = this.percent + 10;

    if (this.percent > 100) {
      this.percent = 100;
      this.isSpinning = false;
      this.message.success("File Uploaded Successfully", "");

      if (!addNew) {
        this.close(myForm);

      } else {
        this.data = new fileMaster();
        this.resetDrawer(myForm);

        this.fileURL = null;
        this.tags = [];
        this.percent = 0;
        this.stopInterval();
        this.progressBar = false;

        this.data.FOLDER_ID = String(this.folderID);
      }
    }
  }

  timer: any;

  start(myForm, addNew) {
    // this.timer = setInterval(() => {
    //   this.increase(myForm, addNew);
    // }, 500);

    this.isSpinning = false;
    this.message.success("File Uploaded Successfully", "");

    if (!addNew) {
      this.close(myForm);

    } else {
      this.data = new fileMaster();
      this.resetDrawer(myForm);

      this.fileURL = null;
      this.tags = [];
      this.percent = 0;
      this.stopInterval();
      this.progressBar = false;

      this.data.FOLDER_ID = String(this.folderID);
    }
  }

  stopInterval() {
    // clearInterval(this.timer);
  }

  resetDrawer(myForm: NgForm) {
    // myForm.form.reset();

    this.fileURL = null;
    this.data.TYPE = null;
    this.data.SIZE_KB = null;
    this.tags = [];
    this.percent = 0;
  }
}
