import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd';
import { FeatureMaster } from 'src/app/Models/Feature';
import { ApiService } from 'src/app/Service/api.service';
import { OrganizationMaster } from '../../../Models/organization-master';
import { EmmService } from '../../../Service/emm.service';
import { OrganizationComponent } from '../organization/organization.component';

@Component({
  selector: 'app-organizations',
  templateUrl: './organizations.component.html',
  styleUrls: ['./organizations.component.css']
})

export class OrganizationsComponent implements OnInit {
  formTitle = "Manage Organization";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  // data:FeatureMaster[]=[];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "id";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] = [["NAME", "Organization Name"], ["EMAIL_ID", "Email"], ["MOBILE_NUMBER", "Mobile"], ["CITY", "City"], ["TEHSIL", "Tehsil"], ["DISTRICT", "District"], ["STATE", "State"], ["COUNTRY", "Country"]]
  time = new Date()
  features = []

  //drawer Variables
  visible = false;

  // drawerData1: Department = new Department();
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: OrganizationMaster = new OrganizationMaster();
  data: FeatureMaster = new FeatureMaster();
  drawerVisible1: boolean;
  drawerTitle1: string;
  drawerData1: OrganizationMaster = new OrganizationMaster();
  ROLES = [];
  isSpinning = false
  constructor(private api: ApiService, private message: NzNotificationService) { }

  ngOnInit() {
    this.search();
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }
  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
      //this.sortKey = "id";
      // this.sortValue = "descend";
    }
    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }
    console.log("search text:" + this.searchText);
    if (this.searchText != "") {
      var likeQuery = " AND";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
      console.log("likeQuery" + likeQuery);
    }
    this.api.getAllOrganizations(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery).subscribe(data => {
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];
    }, err => {
      console.log(err);
    });
  }


  //Drawer Methods
  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  add(): void {
    this.drawerTitle = "Create New Organization";
    this.drawerData = new OrganizationMaster();
    this.drawerVisible = true;
  }

  @ViewChild(OrganizationComponent, { static: false }) OrganizationComponentVar: OrganizationComponent;

  edit(data: OrganizationMaster): void {
    this.drawerTitle = "Update Organization Details";
    // console.log("start time:"+data.DAY_START_TIME);
    this.drawerData = Object.assign({}, data);
    this.drawerVisible = true;

    this.OrganizationComponentVar.getallorg2(this.drawerData.ID);
  }
  // open(): void {
  //   this.drawerVisible1 = true;
  // }
  open(data: OrganizationMaster): void {
    this.drawerTitle1 = "Organisation Features Mapping";
    // console.log("start time:"+data.DAY_START_TIME);
    this.drawerData1 = Object.assign({}, data);
    this.drawerVisible1 = true;
    this.api.getAllFeature(this.pageIndex, this.pageSize, this.sortKey, '', '').subscribe(data => {
      this.loadingRecords = false;

      this.features = data['data'];
    }, err => {
      console.log(err);
    });
  }
  close(): void {
    this.visible = false;
  }
  // close1(): void {
  //   this.drawerVisible1 = false;
  // }
  close1(accountMasterPage: NgForm) {
    this.drawerVisible1 = false;
    this.resetDrawer(accountMasterPage);
  }

  resetDrawer(accountMasterPage: NgForm) {
    accountMasterPage.form.reset();

  }
  save1(accountMasterPage: NgForm): void {
    var d = []
    if (this.ROLES != undefined && this.ROLES.length > 0) {
      for (var i = 0; i < this.ROLES.length; i++) {
        d.push({
          CLIENT_ID: 1,
          ORG_ID: this.drawerData1.ID,
          FEATURE_ID: this.ROLES[i],
          STATUS: true
        })
      }

      this.isSpinning = true;
      this.api.organisationFeatureMapping(d, this.drawerData1.ID)
        .subscribe(successCode => {
          this.isSpinning = false;
          if (successCode['code'] == "200") {

            this.message.success("Feature Mapping Updated Successfully...", "");
            accountMasterPage.form.reset();
            this.drawerClose1();
          }
          else {
            this.message.error("Feature Mapping Updation Failed...", "");

          }
        });
    } else {

      this.message.error("Please Select Roles...", "");

    }

  }
  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }
  drawerClose1(): void {

    this.drawerVisible1 = false;
  }

}
