import { Component, OnInit, Input } from '@angular/core';
import { Designationmaster } from 'src/app/Models/Designationmaster';
import { ApiService } from 'src/app/Service/api.service';
import { NzNotificationService } from 'ng-zorro-antd';
import { DepartmentworkingDetails } from 'src/app/Models/departmentworking-details';
import { DatePipe } from '@angular/common';
import { NgForm } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-designation',
  templateUrl: './designation.component.html',
  styleUrls: ['./designation.component.css']
})

export class DesignationComponent implements OnInit {
  drawerData: Designationmaster = new Designationmaster();
  @Input() drawerClose: Function;
  @Input() data: Designationmaster;
  isSpinning = false
  @Input() listOfData: DepartmentworkingDetails[] = [];
  OPEN_TIME
  CLOSE_TIME
  @Input() OPEN_TIME2
  @Input() CLOSE_TIME2
  fKey = ""
  orgId = this.cookie.get('orgId')
  namepattern = /[a-zA-Z][a-zA-Z ]+/
  regexp = /^\S/
  namepatt = /^[A-Za-z ]*$/
  spatt = /^[a-zA-Z0-9 ]+$/
  date = new Date();
  date1 = this.datePipe.transform(this.date, 'yyyyMMddHHmmss')
  fileDataLOGO_URL: File = null
  folderName = "departmentIcon"
  @Input() DAYS = false;
  isOk = true;
  roles = []
  fileList = []
  org = []
  listdata2 = []
  listdata1 = []
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";

  constructor(private api: ApiService, private cookie: CookieService, private datePipe: DatePipe, private message: NzNotificationService) {
  }

  ngOnInit() {
    this.getallorg1();
    this.getallorg2();
    this.CreateData()

    this.api.getAllDesignation(this.pageIndex, this.pageSize, this.sortKey, '', ' and STATUS=1   AND  ORG_ID =' + this.orgId).subscribe(data => {
      console.log(data['data'])
      this.loadingRecords = false;
      this.dataList = data['data'];

    }, err => {
      console.log(err);
    });

    // this.api.getAllOrganizations(this.pageIndex, this.pageSize, this.sortKey, '','').subscribe(data => {
    //   this.loadingRecords = false;

    //   this.org = data['data'];
    //   }, err => {
    //   console.log(err);
    // });

  }

  designation() {
    this.api.getAllDesignation(this.pageIndex, this.pageSize, this.sortKey, '', ' and STATUS=1   AND  ORG_ID =' + this.orgId).subscribe(data => {
      console.log(data['data']);
      this.loadingRecords = false;
      this.dataList = data['data'];

    }, err => {
      console.log(err);
    });
  }

  // close(): void {
  //   this.OPEN_TIME2 = null
  //   this.CLOSE_TIME2 = null
  //   this.drawerClose();
  // }

  close(accountMasterPage: NgForm) {
    this.OPEN_TIME2 = null
    this.CLOSE_TIME2 = null
    this.drawerClose();
    this.resetDrawer(accountMasterPage);
  }

  resetDrawer(accountMasterPage: NgForm) {
    accountMasterPage.form.reset();
    this.add();
  }

  setAllDays(event) {
    this.DAYS = event;
  }

  setOpenTime(event) {
    this.OPEN_TIME2 = event;
    console.log(this.OPEN_TIME2);

    for (let i = 0; i < 7; i++) {
      if (this.listOfData[i]['IS_HOLIDAY'] == false) {
        this.listOfData[i]['OPEN_TIME'] = this.OPEN_TIME2;
        this.listOfData[i]['CLOSE_TIME'] = this.CLOSE_TIME2;
      }

      if (this.listOfData[i]['IS_HOLIDAY'] == true) {
        this.listOfData[i]['OPEN_TIME'] = this.OPEN_TIME;
        this.listOfData[i]['CLOSE_TIME'] = this.CLOSE_TIME;
      }
    }
  }

  setCloseTime(event) {
    this.CLOSE_TIME2 = event;
    for (let i = 0; i < 7; i++) {
      if (this.listOfData[i]['IS_HOLIDAY'] == false) {
        this.listOfData[i]['OPEN_TIME'] = this.OPEN_TIME2;
        this.listOfData[i]['CLOSE_TIME'] = this.CLOSE_TIME2;
      }
      if (this.listOfData[i]['IS_HOLIDAY'] == true) {
        this.listOfData[i]['OPEN_TIME'] = this.OPEN_TIME;
        this.listOfData[i]['CLOSE_TIME'] = this.CLOSE_TIME;
      }
    }
  }

  CreateData() {
    for (let i = 0; i < 7; i++) {
      this.listOfData.push({
        ID: 0,
        DAY: i,
        IS_HOLIDAY: false,
        OPEN_TIME: this.OPEN_TIME,
        CLIENT_ID: this.api.clientId,
        DEPARTMENT_ID: 0,
        DATE: "",
        CLOSE_TIME: this.CLOSE_TIME
      });
    }
  }

  getDay(day: number) {
    switch (day) {
      case 0:
        return "Sunday"
        break;
      case 1:
        return "Monday"
        break;
      case 2:
        return "Tuesday"
        break;
      case 3:
        return "Wednesday"
        break;
      case 4:
        return "Thursday"
        break;
      case 5:
        return "Friday"
        break;
      case 6:
        return "Saturday"
        break;
    }
  }

  onFileSelectedLOGO_URL(event) {
    this.fileDataLOGO_URL = <File>event.target.files[0];
    console.log(this.fileDataLOGO_URL)
    var fileExt = this.fileDataLOGO_URL.name.split('.').pop();
  }

  getallorg1() {
    this.api.getAllDesignation(0, 0, 'ID', 'desc', ' and STATUS=1   AND  ORG_ID =' + this.orgId).subscribe(data => {
      if (data['code'] == 200) {
        this.listdata1 = data['data'];
        console.log(this.listdata1)
      }

    }, err => {
      console.log(err);
    });
  }

  getallorg2() {
    this.api.getAllDesignation(0, 0, 'ID', 'desc', ' and STATUS=1   AND  ORG_ID =' + this.orgId).subscribe(data => {
      if (data['code'] == 200) {
        this.listdata2 = data['data'];
        console.log(this.listdata2)
      }

    }, err => {
      console.log(err);
    });
  }

  add(): void {
    this.api.getAllDesignation(1, 1, 'SEQUENCE_NO', 'desc', ' and STATUS=1   AND  ORG_ID =' + this.orgId).subscribe(data => {
      if (data['count'] == 0) {
        this.data.SEQUENCE_NO = 1;
      } else {
        this.data.SEQUENCE_NO = Number(data['data'][0]['SEQUENCE_NO']) + 1;
        this.data.STATUS = true;
      }
    }, err => {
      console.log(err);
    })
  }

  save(addNew: boolean, accountMasterPage: NgForm): void {
    this.isOk = true;
    this.data.ORG_ID = Number(this.cookie.get('orgId'));
    if (this.data.PARENT_ID == undefined || this.data.PARENT_ID == null) {
      this.isOk = false;
      this.message.error("Please Enter Parent Designation", '')
    } else

      if (this.data.NAME == undefined || this.data.NAME.trim() == '') {
        this.isOk = false;
        this.message.error("Please Enter Designation Name", '')
      } else
        if (!this.namepatt.test(this.data.NAME.toString())) {
          this.isOk = false;
          this.message.error("please Enter Valid Name", '')
        } else
          if (this.data.SHORT_CODE == null || this.data.SHORT_CODE.trim() == '') {
            this.isOk = false;
            this.message.error("Please Enter Short Code", '')
          } else
            if (!this.spatt.test(this.data.SHORT_CODE.toString())) {
              this.isOk = false;
              this.message.error("Please Enter Valid Short Code", '')
            } else
              if (this.isOk) {
                this.isSpinning = true;

                if (this.data.ID) {
                  var emailData2 = this.listdata2.filter(obj => {
                    return (obj.SHORT_CODE == this.data.SHORT_CODE && obj.ID != this.data.ID);
                  });

                  if (emailData2.length == 0) {
                    this.api.updateDesignation(this.data)
                      .subscribe(successCode => {
                        if (successCode['code'] == "200") {
                          console.log("successcode: " + JSON.stringify(successCode))
                          this.message.success("Designation Information Updated Successfully...", "");
                          if (!addNew)
                            this.drawerClose();
                          this.resetDrawer(accountMasterPage);
                          this.isSpinning = false;
                        }
                        else {
                          this.message.error("Designation Information Updation Failed...", "");
                          this.isSpinning = false;
                        }
                      });
                  } else {
                    this.message.error("Shortcode Exist Please Enter Other Shortcode...", "");
                    this.isSpinning = false;
                  }
                }
                else {
                  var emailData = this.listdata1.filter(obj => {
                    return obj.SHORT_CODE == this.data.SHORT_CODE;
                  });

                  console.log(this.data);

                  if (emailData.length == 0) {
                    this.api.createDesignation(this.data).subscribe(successCode => {
                      if (successCode['code'] == "200") {
                        this.isSpinning = false;
                        console.log("successcode: " + JSON.stringify(successCode))
                        this.message.success("Designation Information Created Successfully...", "");
                        this.designation()
                        if (!addNew) {
                          this.drawerClose();
                          this.resetDrawer(accountMasterPage);
                        }
                        else {
                          this.data = new Designationmaster();
                          this.resetDrawer(accountMasterPage);

                        }
                      }
                      else {
                        this.message.error("Designation Information Creation Failed...", "");
                        this.isSpinning = false;
                      }
                    });

                  } else {
                    this.message.error("Shortcode Exist Please Enter Other Shortcode", "");
                    this.isSpinning = false;
                  }
                }
              }

  }

}
