import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/Service/api.service';
import { User } from 'src/app/Models/user';
import { Role } from 'src/app/Models/role';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { Roledetails } from 'src/app/Models/roledetails';

@Component({
  selector: 'app-usermodulemapping',
  templateUrl: './usermodulemapping.component.html',
  styleUrls: ['./usermodulemapping.component.css']
})
export class UsermodulemappingComponent implements OnInit {

  formTitle="Assign"
  users: User[];
  roles:Role[]
  values
  STATUS=true
  filterQuery=""
  userLoading = false;
  roleLoading = false;
  isSpinning=false;
  filterRoleValue
  USER_ID:number
  userId=this.cookie.get('userId')
  roleId=this.cookie.get('roleId')
  applicationId=this.cookie.get('applicationId')
  data=this.cookie.get('data')


  constructor(private api: ApiService,private cookie:CookieService,private message:NzNotificationService) { }

  ngOnInit() {
    
    this.loadData()
    console.log("dataa")

  //   this.detailsData=  Object.assign({}, this.data);
  //  console.log(this.detailsData)
  }

loadData()
{
console.log(this.data)

var roleFilterQuery="AND PARENT_ROLE_ID="+this.roleId
  this.api.getAllRoles(0, 0, '', '', roleFilterQuery).subscribe(roles => {
    this.roles = roles['data'];
    this.roleLoading = false;
  }, err => {
    console.log(err);
    this.roleLoading = false;
  });

  // this.api.getAllModuleRoles(0, 0, '', '', roleFilterQuery).subscribe(roles => {
  //   this.roles = roles['data'];
  //   this.roleLoading = false;
  // }, err => {
  //   console.log(err);
  //   this.roleLoading = false;
  // });


  var userFilterQuery="AND ID NOT IN ( "+this.userId +" )"
  this.api.getAllUsers(0, 0, '', '', userFilterQuery).subscribe(users => {
    this.users = users['data'];
    this.USER_ID = users['data'][0]['ID']
    if(this.USER_ID)
    this.userSelectChange(this.USER_ID)
    this.userLoading = false;
  }, err => {
    console.log(err);
    this.userLoading = false;
  });
}

  save()
  {
    this.isSpinning = true;
    var userModuleMapping = []

    console.log(this.filterRoleValue)

    this.filterRoleValue.map((item) => {
      var v = { "USER_ID": this.USER_ID, "ROLE_ID": item, "APPLICATION_ID": this.applicationId, "DEPARTMENT_ID": 0, "MODULE_ID": 0, "STATUS": this.STATUS, "CLIENT_ID": this.api.clientId }
      userModuleMapping.push(v)
    })

    console.log(this.filterRoleValue)

    this.api.AssignApplicationsData(userModuleMapping)
      .subscribe(successCode => {
        if (successCode['code'] == "200") {
          this.message.success("Application Assigned Successfully ...", "");
          this.isSpinning = false;
        }
        else {
          this.message.error("Failed to Assign Applications...", "");
          this.isSpinning = false;
        }
      });
  }


userSelectChange(userId:number)
{
  var filterQuery = " AND USER_ID=" + userId + " "
  this.api.getAllUserAccessMapping(0, 0, '', '', filterQuery).subscribe(data => {
    console.log(data['data'])
    this.values = ""
    data['data'].forEach(element => {
      this.values = element['ROLE_ID'] + "," + this.values
    });

    if (this.values.match("undefined")) {
      this.values = this.values.substring(0, this.values.length - 10)
      console.log(this.values)
    }
    else {
      this.values = this.values.substring(0, this.values.length - 1)
      console.log(this.values)
    }
    if (this.values != []) {
      var roleIds = this.values.split(',').map(function (item) {
        return parseInt(item, 10);
      });
    }
    this.filterRoleValue = roleIds
    if (this.filterRoleValue == [NaN]) {
      this.filterRoleValue = undefined
    }

  }, err => {
    console.log(err);
  });
}



}
