import { Component, OnInit,Input } from '@angular/core';
import { Folder } from 'src/app/Models/folder';
import { ApiService } from 'src/app/Service/api.service';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { Application } from 'src/app/Models/application';

@Component({
  selector: 'app-folder',
  templateUrl: './folder.component.html',
  styleUrls: ['./folder.component.css']
})
export class FolderComponent implements OnInit {

  @Input() drawerClose: Function;
  @Input() data: Folder;
  isSpinning = false
  userId=this.cookie.get('userId')
  applicationId=this.cookie.get('applicationId')

  loadingApplications=false
  applications: Application[];
  constructor(private api: ApiService,private cookie:CookieService, private message: NzNotificationService) {
  }

  ngOnInit() {
    this.loadApplications() 
  }
  


  close(): void {
    this.drawerClose();
   
  }

  loadApplications() {
    console.log("called")
    this.loadingApplications = true;
    this.api.getAllApplications(0, 0, '', '', '').subscribe(data => {
      console.log(data)
      this.applications = data['data'];
      this.loadingApplications = false;
    }, err => {
      console.log(err);
      this.loadingApplications = false;
    });
  }

  save(addNew: boolean): void {
   
    this.isSpinning = true;

    if (this.data.NAME != undefined && this.data.NAME != "")
     {
      this.data.APPLICATION_ID=Number(this.applicationId)
      if (this.data.ID) {

        console.log(this.data)
        this.api.updateFolder(this.data)
          .subscribe(successCode => {
            console.log(successCode)
            if (successCode['code'] == "200") {
              this.message.success("Folder information updated Successfully...", "");
              if (!addNew)
                this.drawerClose();
              this.isSpinning = false;
            }
            else {
              this.message.error("Failed to update folder information...", "");
              this.isSpinning = false;
            }
          });
      }
      else {
        console.log(this.data)
        this.data.CREATOR_ID=+this.userId
        this.api.createFolder(this.data)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
              this.message.success("Folder information added successfully...", "");
              if (!addNew)
                this.drawerClose();
              else {
                this.data = new Folder();
              }
              this.isSpinning = false;
            }
            else {
              this.message.error("Failed to add folder information...", "");
              this.isSpinning = false;
            }
          });
      }
    }
    else {
      this.message.error("Please Fill All Required Fields...", "");
      this.isSpinning = false;
    }
  }


}