import { Component, OnInit, Input } from '@angular/core';
import { Faq } from 'src/app/Models/faq'
import { ApiService } from 'src/app/Service/api.service'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { Faqhead } from 'src/app/Models/faqhead';
import { DatePipe } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { NgForm } from '@angular/forms';
@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css'],
  providers: [DatePipe]
})
export class FaqComponent implements OnInit {

  @Input() drawerClose: Function;
  @Input() data: Faq;

  isSpinning = false
  faqHeads: Faqhead[];
  date = new Date();
  date1 = this.datePipe.transform(this.date, 'yyyyMMdd')
  isOk = true;
  fileURL: any;

  fileDataURL: File = null
  listOfOption: Array<{ label: string; value: string }> = [];
  f_key = "VwKkXFiw"
  applicationId = Number(this.cookie.get('applicationId'))

  constructor(private api: ApiService, private cookie: CookieService, private datePipe: DatePipe, private message: NzNotificationService) {
  }

  ngOnInit() {
    this.loadFaqHeads();

  }
  loadFaqHeads() {
    this.isSpinning = true;
    // AND APPLICATION_ID=+this.applicationId
    this.api.getAllFaqHeads(0, 0, '', '', ' AND IS_PARENT=0 AND STATUS=1  AND  ORG_ID =' + this.cookie.get('orgId')).subscribe(localName => {
      this.faqHeads = localName['data'];
      this.isSpinning = false;
    }, err => {
      console.log(err);
      this.isSpinning = false;
    });
  }

  resetDrawer(accountMasterPage: NgForm) {
    accountMasterPage.form.reset();
  }
  close(accountMasterPage: NgForm): void {
    this.resetDrawer(accountMasterPage);
    this.drawerClose();
  }

  save2(addNew: boolean, accountMasterPage: NgForm) {
    if (this.data.ID) {
      // if (this.fileDataURL) {
      //   if (this.data.URL == "") {
      //     this.data.URL = this.genarateKey();
      //   }
      //   else {
      //     console.log("updatefile")
      //     var str = this.data.URL.substring(this.data.URL.lastIndexOf('/') + 1).split('.')
      //     var fileExt = this.fileDataURL.name.split('.').pop();
      //     var url = str[0] + "." + fileExt;
      //     this.api.onUpload("faq", this.fileDataURL, url)
      //     this.data.URL = this.api.retriveimgUrl + "faq/" + url;
      //   }
      // }
      // else {
      //   this.data.URL = ""
      // }
      this.data.TAGS = this.data.TAGS_STRING.toString()

      this.api.updateFaq(this.data)
        .subscribe(successCode => {
          console.log(successCode)
          if (successCode['code'] == "200") {
            this.message.success("Information Updated Successfully...", "");
            if (!addNew)
              this.drawerClose();
            this.resetDrawer(accountMasterPage);
            this.isSpinning = false;
          }
          else {
            this.message.error("Failed To Update Information...", "");
            this.isSpinning = false;
          }
        });
    }
    else {
      // console.log("POSITIVE_HELPFUL_COUNT " + this.data.POSITIVE_HELPFUL_COUNT)
      // if (this.fileDataURL) {
      //   this.data.URL = this.genarateKey()
      // }
      // else {
      //   this.data.URL = "";
      // }
      this.data.POSITIVE_HELPFUL_COUNT = 0
      this.data.NEGATIVE_HELPFUL_COUNT = 0
      this.data.TAGS = this.data.TAGS_STRING.toString()
 
      this.api.createFaq(this.data)
        .subscribe(successCode => {
          if (successCode['code'] == "200") {
            this.message.success("Information Save Successfully...", "");
            if (!addNew) {
              this.drawerClose();
              this.resetDrawer(accountMasterPage);
            }
            else {
              this.data = new Faq();
              this.resetDrawer(accountMasterPage);
              this.api.getAllFaqs(1, 1, 'SEQ_NO', 'desc', ' AND  ORG_ID =' + this.cookie.get('orgId')).subscribe(data => {
                if (data['count'] == 0) {
                  this.data.SEQ_NO = 1;
                } else {
                  this.data.SEQ_NO = data['data'][0]['SEQ_NO'] + 1;
                }
              }, err => {
                console.log(err);
              })
            }
            this.isSpinning = false;
          }
          else {
            this.message.error("Failed To Save Information...", "");
            this.isSpinning = false;
          }
        });
    }



  }
  save(addNew: boolean, accountMasterPage: NgForm): void {
    this.isSpinning = false;
    this.isOk = true;
    if (
      this.data.FAQ_HEAD_ID == null &&
      this.data.TAGS_STRING == null &&
      this.data.QUESTION.trim() == '' &&
      this.data.ANSWER.trim() == ''

    ) {
      this.isOk = false;
      this.message.error('Please Fill All Required Information', '');

    } else if (this.data.FAQ_HEAD_ID == undefined || this.data.FAQ_HEAD_ID == null) {
      this.isOk = false;
      this.message.error('Please Select FAQ Head Name', '');

    } else if (this.data.TAGS_STRING == undefined || this.data.TAGS_STRING == null) {
      this.isOk = false;
      this.message.error('Please Enter Tags', '');

    } else if (this.data.QUESTION.trim() == '' || this.data.QUESTION == null) {
      this.isOk = false;
      this.message.error('Please Enter Question', '');

    } else if (this.data.ANSWER.trim() == '' || this.data.ANSWER == null) {
      this.isOk = false;
      this.message.error('Please Enter Answer', '');

    }
    if (this.isOk) {
      // this.data.ORG_ID = Number(this.cookie.get('orgId'));
      this.isSpinning = true;

      if (this.fileDataURL) {
        var number = Math.floor(100000 + Math.random() * 900000)
        var fileExt = this.fileDataURL.name.split('.').pop();
        var Dates = new Date();
        var formatedDate = this.datePipe.transform(Dates, 'yyyyMMddHHmmss')
        var url = formatedDate + number + "." + fileExt

        this.api.onUpload2("ticket", this.fileDataURL, url)
          .subscribe(successCode => {

            if (successCode['code'] == "200") {
              this.data.URL = url;
              this.fileDataURL = null;

              this.save2(addNew, accountMasterPage);

            }
            else {
              this.isSpinning = false;
              this.message.error("Failed to upload file", "");
            }
          });

      } else {
        this.data.URL = ''
        this.save2(addNew, accountMasterPage);
      }
    }
    // else
    // {
    //   this.message.error("Please Fill All Required Fields...","");
    //   this.isSpinning = false;
    // }
  }

  genarateKey() {
    var number = Math.floor(100000 + Math.random() * 900000)
    var fileExt = this.fileDataURL.name.split('.').pop();
    var url = this.date1 + number + "." + fileExt
    this.api.onUpload("faq", this.fileDataURL, url)
    this.data.URL = this.api.retriveimgUrl + "faq/" + url;
    return this.data.URL
  }
  onFileSelectedURL(event) {
    console.log(event.target.files[0])
    this.fileDataURL = <File>event.target.files[0];
    var fileExt = this.fileDataURL.name.split('.').pop();
  }

  response(status, data) {
    console.log(data)
    this.isSpinning = true;
    data.STATUS = status
    console.log(data)
    this.api.updateFaqResponse(data)
      .subscribe(successCode => {
        console.log(successCode)
        if (successCode['code'] == "200") {
          this.message.success("Information Saved Successfully...", "");
          this.isSpinning = false;
          this.drawerClose();
        }
        else {
          this.message.error("Failed to Reject", "");
        }
      });
  }

  getUrl(url) {
    if (url)
      return this.api.baseUrl + "static/ticket/" + url
    else
      return ""
  }
}