import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from './Service/api.service';
import { CookieService } from 'ngx-cookie-service';
import { NzNotificationService } from 'ng-zorro-antd';
import { Roledetails } from './Models/roledetails';
import { ViewNotificationDrawerComponent } from './pages/NotificationModule/view-notification-drawer/view-notification-drawer.component';
import { ChangePasswordDrawerComponent } from './pages/CommonModule/change-password-drawer/change-password-drawer.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  menus = []
  isCollapsed = false;
  isLogedIn = false;
  message1;
  formsParent: Roledetails[];
  forms: Roledetails[];
  detailsData: Roledetails = new Roledetails();
  EMAIL_ID = "";
  PASSWORD = "";
  supportKey = "";
  ORGANIZATION_ID: number;
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData = [];
  userId = this.cookie.get('userId');
  USERNAME = this.cookie.get('userName');
  lastLoginDateTime = this.cookie.get('lastLoginDateTime');
  roleId = Number(this.cookie.get('roleId'));
  pageName: string = "";
  pageName2: string = "";
  dataList = [];
  ROLE_ID = Number(this.cookie.get('roleId'));
  org = [];
  shownotify = false;
  currentApplicationVersionForDevelopment: string = environment.firebase.appVersion1;
  currentApplicationVersionForProduction: string = environment.firebase.appVersion2;

  constructor(private router: Router, private api: ApiService, private cookie: CookieService, private message: NzNotificationService) {
    this.loggerInit();
  }

  loggerInit() {
    // console.log("before init");

    if (this.cookie.get('supportKey') === '' || this.cookie.get('supportKey') === null) {
      // console.log("after device id");
      this.api.loggerInit().subscribe(data => {
        // console.log("data from api logger init");
        // console.log(data);

        if (data['code'] == "200") {
          this.cookie.set('supportKey', data["data"][0]["supportkey"], 365, "", "", false, "Strict");
          // console.log("after init");
        }
      }, err => {
        // console.log(err);
      });

    } else {
      // console.log("present device id");
      // console.log(this.cookie);
    }
  }

  setUserId(event) {
    this.ROLE_ID = event;
    this.roleId = event;
    sessionStorage.setItem('roleId', this.roleId.toString());

    this.cookie.set('roleId', this.roleId.toString(), 365, "", "", false, "Strict");
    this.router.navigateByUrl('/dashboard');

    this.router.navigate(['dashboard']).then(() => {
      window.location.reload();
    });
  }

  ngOnInit() {
    let url = window.location.href;
    var arr = url.split("/");
    // this.pageName2 = arr[3];
    this.pageName2 = arr[(arr.length) - 1];
    console.log(this.pageName2);

    if (this.cookie.get('token') === '' || this.cookie.get('token') === null) {
      this.isLogedIn = false;
      let url = window.location.href;
      var arr = url.split("/");

      // if (arr[3] == "adminlogin") {
      if (arr[(arr.length) - 1] == "adminlogin") {
        this.pageName2 = 'adminlogin';
        console.log("Page : " + this.pageName2);
        this.router.navigateByUrl('/adminlogin');

      } else {
        this.pageName2 = 'login';
        console.log("Page : " + this.pageName2);
        this.router.navigateByUrl('/login');
      }

    } else {
      // Get Org Name
      this.api.getAllOrganizations(0, 0, "", "", " AND ID=" + Number(this.cookie.get('orgId'))).subscribe(data => {
        if (data['code'] == 200) {
          this.api.ORGANIZATION_NAME = data['data'][0]["NAME"];
        }

      }, err => {
        console.log(err);
      });

      if (this.userId || this.roleId != 0) {
        this.isLogedIn = true;
        this.accessPageForRedirect();
        this.loadForms();

        this.api.getAllEmpRoleMap(0, 0, 'ID', 'ASC', ' AND STATUS=1 AND EMPLOYEE_ID=' + this.userId).subscribe(data => {
          this.dataList = data['data'];

        }, err => {
          console.log(err);
        });

      } else {
        this.cookie.deleteAll();
      }
    }

    this.api.requestPermission(this.userId);
    this.api.receiveMessage();
    var fullName = this.USERNAME.split(' ');

    if (fullName.length > 1)
      this.USERNAME = fullName[0] + ' ' + fullName[fullName.length - 1];

    else
      this.USERNAME = fullName[0];
  }

  accessPageForRedirect() {
    // if (this.roleId != 0) {
    //   let url = window.location.href;
    //   var arr = url.split("/");
    //   let validPage = "/" + arr[3];

    //   this.api.getCheckAccessOfForm(this.roleId, validPage).subscribe(data => {
    //     if (data['data'] == true) {
    //       console.log(data['data']);
    //       this.router.navigateByUrl(validPage);
    //       this.pageName = arr[3];

    //     } else {
    //       if ((validPage != "/adminlogin") && (validPage != "/login") && (validPage != "/dashboard")) {
    //         this.api.logoutForSessionValues();
    //       }
    //     }
    //   });
    // }

    if (this.roleId != 0) {
      let url = window.location.href;
      var arr = url.split("/");
      // let validPage = "/" + arr[3];
      let validPage = "/" + arr[(arr.length) - 1];

      if ((validPage != "/adminlogin") && (validPage != "/login") && (validPage != "/dashboard")) {
        this.router.navigateByUrl('/dashboard');

        this.router.navigate(['dashboard']).then(() => {
          window.location.reload();
        });
      }
    }
  }

  loadForms() {
    this.api.getForms(this.userId, this.roleId,).subscribe(data => {
      if (data['code'] == 200) {
        data['data'].forEach(element => {
          element['children'].sort(this.sortFunction)

          if (element['children'].length == 0)
            delete element['children']
        });

        this.menus = data['data'].sort(this.sortFunction);
      }
    });
  }

  sortFunction(a, b) {
    var dateA = a.SEQ_NO;
    var dateB = b.SEQ_NO;
    return dateA > dateB ? 1 : -1;
  };

  getData(form) {
    this.cookie.set('roleId', form.ROLE_ID);
  }

  isSpinning = false;

  logout() {
    this.isSpinning = true;

    this.api.logout().subscribe(forms => {
      if (this.roleId != 1) {
        this.api.unsubscribeTokenToTopic(this.api.cloudID);
      }

      setTimeout(() => {
        window.location.reload();
      }, 1000);

      this.cookie.deleteAll();
      sessionStorage.clear();

    }, err => {
      setTimeout(() => {
        window.location.reload();
      }, 1000);

      this.cookie.deleteAll();
      sessionStorage.clear();
      this.isSpinning = false;
      this.message.error("Failed to Logout", "");
    });
  }

  @ViewChild(ViewNotificationDrawerComponent, { static: false }) ViewNotificationDrawerComponentVar: ViewNotificationDrawerComponent;

  add(): void {
    this.drawerTitle = "Notifications";
    this.drawerVisible = true;
    this.ViewNotificationDrawerComponentVar.pageSize = 8;
    this.ViewNotificationDrawerComponentVar.getNotifications();
  }

  drawerClose(): void {
    this.drawerVisible = false;
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  changePasswordDrawerVisible: boolean = false;
  changePasswordDrawerTitle: string;

  @ViewChild(ChangePasswordDrawerComponent, { static: false }) ChangePasswordDrawerComponentVar: ChangePasswordDrawerComponent;

  showChangePasswordDrawer(): void {
    this.changePasswordDrawerTitle = "Reset Password";
    this.changePasswordDrawerVisible = true;

    this.ChangePasswordDrawerComponentVar.getInfo();
  }

  changePasswordDrawerClose(): void {
    this.changePasswordDrawerVisible = false;
  }

  get changePasswordDrawerCloseCallback() {
    return this.changePasswordDrawerClose.bind(this);
  }
}
