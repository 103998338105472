export class Ticketgroup {
    ID: number
    CLIENT_ID: number
    PARENT_ID: number
    TYPE: string
    VALUE: string
    URL: string
    SEQ_NO: number = 0
    IS_LAST: any = false
    ALERT_MSG: string
    STATUS: boolean
    PRIORITY: string="M"
    DEPARTMENT_ID: number
}
