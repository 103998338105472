import { Component, OnInit, Input } from '@angular/core';
import { User } from 'src/app/Models/user';
import { ApiService } from 'src/app/Service/api.service';
import { NzNotificationService } from 'ng-zorro-antd';
import { Role } from 'src/app/Models/role';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})

export class UserComponent implements OnInit {
  @Input() drawerClose: Function;
  @Input() data: User;
  passwordVisible = false;
  password: string;
  isSpinning = false;
  dataList: User;
  roleLoading = false;
  roles: Role[];

  constructor(private api: ApiService, private message: NzNotificationService) { }

  ngOnInit() {
    this.api.getAllRoles(0, 0, '', '', '').subscribe(roles => {
      this.roles = roles['data'];
    }, err => {
    });
  }

  close(): void {
    this.drawerClose();
  }

  save(addNew: boolean): void {
    this.isSpinning = true;

    if (this.data.ID) {
      this.api.updateUser(this.data).subscribe(successCode => {
        if (successCode['code'] == "200") {
          this.message.success("User information updated Successfully...", "");
          if (!addNew)
            this.drawerClose();
          this.isSpinning = false;

        } else {
          this.message.error("Failed to update user information...", "");
          this.isSpinning = false;
        }
      });

    } else {
      this.api.createUser(this.data).subscribe(successCode => {
        if (successCode['code'] == "200") {
          this.message.success("User information added successfully...", "");

          if (!addNew)
            this.drawerClose();
          else {
            this.data = new User();
          }
          this.isSpinning = false;

        } else {
          this.message.error("Failed to add user information...", "");
          this.isSpinning = false;
        }
      });
    }
  }

  searchEmail(emailId): void {
    var likeQuery = "EMAIL_ID=" + emailId + "";

    this.api.getAllUsers(0, 0, "", "", likeQuery).subscribe(data => {
      console.log(data);
      this.dataList = data['data'];

    }, err => {
      console.log(err);
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }
}
