import { Component, OnInit,Input } from '@angular/core';
import { Role } from 'src/app/Models/role';
import { ApiService } from 'src/app/Service/api.service';
import { NzNotificationService } from 'ng-zorro-antd';
import { DatePipe } from '@angular/common';
import { Module } from 'src/app/Models/module';

@Component({
  selector: 'app-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.css'],
  providers: [DatePipe]
})
export class RoleComponent implements OnInit {

  @Input() drawerClose: Function;
  @Input() data: Role;
  isSpinning = false
  roleLoading=false
  moduleLoading=false
  @Input() drawerVisible: boolean;
  roles: Role[];
  modules: Module[];
  date = new Date();
  date1 = this.datePipe.transform(this.date, 'yyyyMMddHHmmss')
  fileDataLOGO_URL: File = null
  folderName = "roleIcon"
  fKey=""
  constructor(private api: ApiService,private datePipe: DatePipe, private message: NzNotificationService) { }

  ngOnInit() {
    this.loadRoles()
  }

  close(): void {
    this.drawerClose();
  }

  loadRoles() {
    this.roleLoading = true;
    this.api.getAllRoles(0, 0, '', '', '').subscribe(roles => {
      this.roles = roles['data'];
      this.roleLoading = false;
    }, err => {
      console.log(err);
      this.roleLoading = false;
    });

  
  }
 

  onFileSelectedLOGO_URL(event) {
    this.fileDataLOGO_URL = <File>event.target.files[0];
    console.log(this.fileDataLOGO_URL)
    var fileExt = this.fileDataLOGO_URL.name.split('.').pop();
  }
  genarateKeyLOGO_URL() {
    var number = Math.floor(100000 + Math.random() * 900000)
    var fileExt = this.fileDataLOGO_URL.name.split('.').pop();
    var url = this.date1 + number + "." + fileExt
    console.log("dsf")
    console.log(this.fileDataLOGO_URL)
    this.api.onUpload(this.folderName, this.fileDataLOGO_URL, url)
    this.data.LOGO_URL = this.api.retriveimgUrl + this.folderName + "/" + url;
    return this.data.LOGO_URL
  }
 
  save(addNew: boolean): void {
    this.isSpinning = true;

    if (this.data.NAME != undefined && this.data.NAME != "")
     {
      if (this.data.ID) {

       console.log(this.data)
       if (this.fileDataLOGO_URL) {
        if (this.data.LOGO_URL == "") {
          console.log("blank")
          this.data.LOGO_URL = this.genarateKeyLOGO_URL();
        }
        else {
          console.log("update")
          var str = this.data.LOGO_URL.substring(this.data.LOGO_URL.lastIndexOf('/') + 1).split('.')
          var fileExt = this.fileDataLOGO_URL.name.split('.').pop();
          var url = str[0] + "." + fileExt;
          console.log(this.fileDataLOGO_URL)
          this.api.onUpload(this.folderName,this.fileDataLOGO_URL, url)
          this.data.LOGO_URL = this.api.retriveimgUrl + this.folderName + "/" + url;
        }
      }
      else {
        this.data.LOGO_URL = "C:/AngularProjects/GenericModule/src/assets/img/angular.png"
      }
        this.api.updateRole(this.data)
          .subscribe(successCode => {
            console.log(successCode)
            if(successCode['code'] == "200") 
            {
              this.message.success("Role Updated Successfully...", "");
              if (!addNew)
                this.drawerClose();
              this.isSpinning = false;
              console.log(successCode)
            }
            else 
            {
              console.log(successCode)
              this.message.error("Role Updation Failed...", "");
              this.isSpinning = false;
            }
          });
      }
      else {
       
        if (this.fileDataLOGO_URL) {
          this.data.LOGO_URL = this.genarateKeyLOGO_URL()
          console.log(this.data.LOGO_URL)
        }
        else {
          this.data.LOGO_URL = "";
        }
        console.log(this.data)
        this.api.createRole(this.data)
          .subscribe(successCode => {
            if(successCode['code'] == "200") 
            {
            this.message.success("Role Created Successfully...", "");
            if (!addNew)
              this.drawerClose();
            else {
              this.data = new Role();
            }
            this.loadRoles()
            this.isSpinning = false;
           }
           else
           {
            this.message.error("Role Creation Failed...", "");
            this.isSpinning = false;
           }
       });
      }
    }
    else {
      this.message.error("Please Fill All Required Fields...", "");
      this.isSpinning = false;
    }
  }

  
}