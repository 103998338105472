import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from 'src/app/Service/api.service';
import { NzNotificationService } from 'ng-zorro-antd';
import { log } from 'console';

@Component({
  selector: 'app-map-branches',
  templateUrl: './map-branches.component.html',
  styleUrls: ['./map-branches.component.css']
})
export class MapBranchesComponent implements OnInit {
  @Input() drawerClose1: Function;
  @Input() BRANCH_ID = []
  @Input() Branch
  @Input() data
  @Input() BranchIDS1 = []

  constructor(private api: ApiService, private message: NzNotificationService) { }

  ngOnInit() {


  }
  addBranch(event) {
    this.BRANCH_ID = event;
    this.BranchIDS1.forEach((item, index) => {
      this.BranchIDS1[index]['STATUS'] = 0;
    })
  }
  masterdata = []

  save() {
    this.masterdata = this.BRANCH_ID;
    var newBranches = [];
    if (this.BRANCH_ID != undefined && this.BRANCH_ID.length > 0) {
      if (this.BranchIDS1.length > 0) {
        for (var j = 0; j < this.BRANCH_ID.length; j++) {
          var isFounded = 0;
          for (var i = 0; i < this.BranchIDS1.length; i++) {


            if (this.BranchIDS1[i]['BRANCH_ID'] == this.BRANCH_ID[j]) {
              this.BranchIDS1[i]['STATUS'] = 1
              this.BranchIDS1[i]['CLIENT_ID'] = 1
              this.BranchIDS1[i]['BRANCH_ID'] = this.BRANCH_ID[i],
                isFounded = 1
            }
            if ((i + 1) == this.BranchIDS1.length) {
              if (isFounded == 0) {
                newBranches.push({
                  CLIENT_ID: 1,
                  BRANCH_ID: this.BRANCH_ID[j],
                  STATUS: 1
                })
              }
            }
          }
          if ((j + 1) == this.BRANCH_ID.length) {
             
            this.BranchIDS1=[...this.BranchIDS1,...newBranches];
          }
        }
      } else if (this.BranchIDS1.length == 0) {
        for (let f = 0; f < this.BRANCH_ID.length; f++) {
          this.BranchIDS1.push({
            CLIENT_ID: 1,
            BRANCH_ID: this.BRANCH_ID[f],
            STATUS: 1
          })
        }
      }

      console.log(this.BranchIDS1);


      this.api.mapbranches(this.BranchIDS1, this.data.ID).subscribe(successCode => {
        if (successCode['code'] == 200) {
          this.message.success("Branch Mapping Updated Successfully...", "");
          this.drawerClose1()
          this.BRANCH_ID = []
        }
        else {
          this.message.error("Branch Mapping update failed", '')
        }
      });

    } else {
      this.message.error("Please Select Branches...", "");
    }

  }




  close() {
    this.drawerClose1()
  }

  handleCancel() {
    this.drawerClose1()
  }

}
