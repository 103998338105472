import { Component, OnInit, Input } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Application } from 'src/app/Models/application';
import { ApiService } from 'src/app/Service/api.service';
import { NzNotificationService } from 'ng-zorro-antd';
@Component({
  selector: 'app-application',
  templateUrl: './application.component.html',
  styleUrls: ['./application.component.css'],
  providers: [DatePipe]
})
export class ApplicationComponent implements OnInit {

  @Input() drawerClose: Function;
  @Input() data: Application;
  isSpinning = false

  date = new Date();
  date1 = this.datePipe.transform(this.date, 'yyyyMMddHHmmss')
  fileDataLOGO_URL: File = null
  folderName = "applicationLogo"
  fKey="W7weNPNJ"
  L_KEY:string
previewVisible = true;
  constructor(private api: ApiService, private datePipe: DatePipe, private message: NzNotificationService) {
  }

  ngOnInit() {
   
  }


  close(): void {
    this.drawerClose();
  }

  save(addNew: boolean): void{
    
    this.isSpinning = true;

    if (this.data.NAME != undefined && this.data.NAME != "")
     {
    if (this.data.ID) {

      if (this.fileDataLOGO_URL) {
        if (this.data.LOGO_URL == "") {
          console.log("blank")
          this.data.LOGO_URL= this.genarateKeyLOGO_URL();
        }
        else {
          console.log("update")
          var str = this.data.LOGO_URL.substring(this.data.LOGO_URL.lastIndexOf('/') + 1).split('.')
          var fileExt = this.fileDataLOGO_URL.name.split('.').pop();
          var url = str[0] + "." + fileExt;
          console.log(this.fileDataLOGO_URL)
          this.api.onUploadNewMethod(this.fileDataLOGO_URL,fileExt,this.fKey)
          this.data.LOGO_URL = this.api.retriveimgUrl + this.folderName + "/" + url;
        }
      }
      else {
        this.data.LOGO_URL = ""
      }
   
      this.api.updateApplication(this.data)
        .subscribe(successCode => {
          if (successCode['code'] == "200") {
            this.message.success("Application information updated Successfully...", "");
            if (!addNew)
              this.drawerClose();
            this.isSpinning = false;
          }
          else {
            this.message.error("Failed to update application information...", "");
            this.isSpinning = false;
          }
        });
    }
    else {
      if (this.fileDataLOGO_URL) {
        console.log("called")
        this.data.LOGO_URL= this.genarateKeyLOGO_URL()
        console.log("logo url Final "+this.data.LOGO_URL)
      }
      else {
        this.data.LOGO_URL = "";
      }
    
      let applicationKey = this.api.randomstring(16);
      this.data.APPLICATION_KEY=applicationKey
      let apiKey = this.api.randomstring(32);
      this.data.API_KEY=apiKey
      console.log(this.data)
      this.api.createApplication(this.data)
        .subscribe(successCode => {
          if (successCode['code'] == "200") {
            this.message.success("Application information added successfully...", "");
            if (!addNew)
              this.drawerClose();
            else {
              this.data = new Application();
            }
            this.isSpinning = false;
          }
          else {
            this.message.error("Failed to add application information...", "");
            this.isSpinning = false;
          }
        });


    }

  }
  else {
    this.message.error("Please Fill All Required Fields...", "");
    this.isSpinning = false;
  }
}


  genarateKeyLOGO_URL() {
    var fileExt = this.fileDataLOGO_URL.name.split('.').pop();
    var temp=""
    this.api.onUploadNewMethod(this.fileDataLOGO_URL,fileExt,this.fKey)
    .subscribe(successCode => {
      console.log(successCode)
      if(successCode['code']==200)
      {
        console.log("URL "+successCode['data'][0]['L_KEY'])
        temp=successCode['data'][0]['L_KEY']
        //localStorage.setItem('L_KEY',successCode['data'][0]['L_KEY']);
      }
      else
      {
        console.log(successCode)
      }
    });
    this.L_KEY=temp
    console.log("genearated L_KEY : "+this.L_KEY)
    return this.L_KEY
  }

  onFileSelectedLOGO_URL(event) {
    this.fileDataLOGO_URL = <File>event.target.files[0];
    console.log(this.fileDataLOGO_URL)
    var fileExt = this.fileDataLOGO_URL.name.split('.').pop();
  }
}