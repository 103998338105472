import { Component, OnInit,Input,ViewChild } from '@angular/core';
import { Emailserver } from 'src/app/Models/emailserver';
import { Emailhistory } from 'src/app/Models/emailhistory';
import { ApiService } from 'src/app/Service/api.service';
import { NzNotificationService } from 'ng-zorro-antd';
import { WebdriverWebElement } from 'protractor/built/element';
import { hostViewClassName } from '@angular/compiler';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.css']
})
export class HistoryComponent implements OnInit {

  @Input() drawerClose: Function;
  @Input() data: Emailserver;
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: Emailhistory = new Emailhistory();
  emilHistoryData:Emailhistory
  dateMode = 'time';
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterClass: string = "filter-visible";
  initFilter = true;
  isFilterApplied: string = "default";
  isSpinning:boolean
  columns: string[][] =[ ["SEND_TO","To"],["SUBJECT","Subject"],["ATTACHMENT_COUNT","Attachment Count"],["STATUS","Status"],["SCHEDULED_DATE_TIME","Scheduled Date Time"],["UNIQUE_KEY","Unique Key"]]
  
  EMAIL_SERVER_ID:Number
  STATUS="S"
  filterQuery:string=""

  constructor(private api: ApiService,private message: NzNotificationService) { }

  ngOnInit() {
    this.search();
  }

  edit(data:Emailhistory)
  {
    if(data.STATUS=="P")
    {
    this.api.updateEmailHistory(data)
    .subscribe(successCode => {
      if (successCode['code'] == "200") {
        this.message.success("EmailServer information updated Successfully...", "");
      }
      else {
        this.message.error("Failed to update emailServer information...", "");
      }
    });
  }
  }

  
  visible(status)
  {
    if(status=="P")
    return "update"
    else
    return ""
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }
  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }
    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }
    console.log(sort)
    console.log("search text:" + this.searchText);
    if (this.searchText != "") {
      var likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like ('%" + this.searchText + "%') OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 3) + ")";
    }
else
{
  if(this.EMAIL_SERVER_ID!=undefined)
  {
  this.filterQuery="AND EMAIL_SERVER_ID=" + this.EMAIL_SERVER_ID +" AND STATUS='"+this.STATUS+""
  this.applyFilter(this.EMAIL_SERVER_ID)
}
}
var filter=""


if (likeQuery)
      filter = this.filterQuery + likeQuery
    else
      filter = this.filterQuery


   console.log(filter)
   console.log(sort,this.sortKey)

   if(filter!="")
   {
    this.api.getAllEmailHistory(0, 0, this.sortKey, sort,filter).subscribe(data => {
      console.log(data)
       this.loadingRecords = false;
       this.totalRecords = data['count'];
       this.dataList = data['data'];
     }, err => {
       console.log(err);
     });
   }

    
  }

  showFilter()
  {
    if (this.filterClass === "filter-visible")
    this.filterClass = "filter-invisible";
  else
    this.filterClass = "filter-visible";

  }

  applyFilter(id?)
{
  if(id!=undefined)
{
 this.EMAIL_SERVER_ID=id
  this.isSpinning=true
  var sort: string;
  try {
    sort = this.sortValue.startsWith("a") ? "asc" : "desc";
  } catch (error) {
    sort = "";
  }
  this.filterQuery="AND EMAIL_SERVER_ID=" + this.EMAIL_SERVER_ID +" AND STATUS='"+this.STATUS+"' "
console.log(this.filterQuery)




this.api.getAllEmailHistory(0, 0, this.sortKey, sort, this.filterQuery).subscribe(data => {
  console.log(data)
   this.totalRecords = data['count'];
   this.dataList = data['data'];
   this.loadingRecords = false;
   this.filterClass = "filter-invisible";
   this.isFilterApplied="primary"
   this.isSpinning=false
 }, err => {
   console.log(err);
 });
}
}


  clearFilter()
  {
    this.STATUS="P"
    this.isFilterApplied="default"
    this.filterClass = "filter-invisible";
    this.applyFilter(this.EMAIL_SERVER_ID)
  }

  handleDateOpenChange(open: boolean): void {
    if (open) {
      this.dateMode = 'time';
    }
  }

  handleDatePanelChange(mode: string): void {
    console.log('handleDatePanelChange: ', mode);
  }

  getStatus(status)
  {
if(status=="P")
return "Pending"
else if(status=="S")
return "Sent"
else if(status=="F")
return "Failed"
else 
return "Rejected"
  }
  get closeCallback() {
    return this.drawerClose1.bind(this);
  }

  drawerClose1(): void {
    this.drawerVisible = false;
  }

  ViewBodyClick(data:Emailhistory)
  {
    this.drawerTitle = "View Body";
    this.drawerData = Object.assign({}, data);
    this.drawerVisible = true;
  }

}
