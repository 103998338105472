import { Component, OnInit, Input } from '@angular/core';
import { AWSTemplets } from 'src/app/Models/AWSTemplets';
import { ApiService } from 'src/app/Service/api.service';
import { NzNotificationService } from 'ng-zorro-antd';


@Component({
  selector: 'app-awstemplate',
  templateUrl: './awstemplate.component.html',
  styleUrls: ['./awstemplate.component.css']
})
export class AwstemplateComponent implements OnInit {

  @Input() drawerClose: Function;
  @Input() data: AWSTemplets;
  isSpinning = false
  passwordVisible = false;
  password: string;
  

  
  constructor(private api: ApiService, private message: NzNotificationService) {
  }

  ngOnInit() {
  
  }
  


  close(): void {
    this.drawerClose();
  }

  save(addNew: boolean): void {


    this.isSpinning = true;
    if (this.data.TEMPLATE_NAME != undefined && this.data.TEMPLATE_NAME != "")
    {
      if (this.data.ID) {
        this.api.updateAwsTemplates(this.data)
          .subscribe(successCode => {
            if (successCode['code'] == "200") {
              this.message.success("awsTemplates information updated Successfully...", "");
              if (!addNew)
                this.drawerClose();
              this.isSpinning = false;
            }
            else {
              this.message.error("Failed to update awsTemplates information...", "");
              this.isSpinning = false;
            }
          });
      }
      else {

        this.api.createAwsTemplates(this.data)
          .subscribe(successCode => {
            console.log(successCode)
            if (successCode['code'] == "200") {
              this.message.success("awsTemplates information added successfully...", "");
              if (!addNew)
                this.drawerClose();
              else {
                this.data = new AWSTemplets();
              }
              this.isSpinning = false;
            }
            else {
              this.message.error("Failed to add awsTemplates information...", "");
              this.isSpinning = false;
            }
          });
      }
    }
    else
    {
      this.message.error("Please Fill All Required Fields...", "");
      this.isSpinning = false;
    }
  }


}