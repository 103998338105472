import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/Models/user';
import { Role } from 'src/app/Models/role';
import { Department } from 'src/app/Models/department';
import { ApiService } from 'src/app/Service/api.service';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-userdepartmentmapping',
  templateUrl: './userdepartmentmapping.component.html',
  styleUrls: ['./userdepartmentmapping.component.css']
})
export class UserdepartmentmappingComponent implements OnInit {

  formTitle="Assign"
  roleLoading=false
  userLoading=false
  users =[];
  roles: Role[];
  isSpinning=false
  filterDepartmentValue
  STATUS=true
  values
  ROLE_ID:number
  USER_ID:number
  departments: Department[];
  loadingFilterDepartments = false;
  roleId = this.cookie.get('roleId')
  userId = this.cookie.get('userId')
  applicationId = this.cookie.get('applicationId')
  constructor(private api: ApiService,private cookie: CookieService,private message:NzNotificationService) { }

  ngOnInit() {
this.loadData()
  }

  loadData()
  {
    this.roleLoading = true;
    this.userLoading=true
    this.loadingFilterDepartments=true
    var filterQuery="AND PARENT_ROLE_ID= "+ this.roleId
    this.api.getAllRoles(0, 0, '', '', filterQuery).subscribe(roles => {
      this.roles = roles['data'];
      this.ROLE_ID = roles['data'][0]['ID']
      this.roleLoading = false;
    }, err => {
      console.log(err);
      this.roleLoading = false;
    });

    // var userFilterQuery = "AND ID NOT IN ( " + this.userId + " )";
    var filterQuery = " AND PARENT_ROLE_ID=" + this.roleId  
    this.api.getAllUserAccessMapping(0, 0, '', '', filterQuery).subscribe(users => {
      this.users = users['data'];
      this.USER_ID=users['data'][0]['USER_ID']
      this.userSelectChange(this.USER_ID)
      this.userLoading = false;
    }, err => {
      console.log(err);
      this.userLoading = false;
    });

    // let deptFilter="AND APPLICATION_ID="+this.applicationId

     this.api.getAllDepartments(0, 0,'','','').subscribe(applications => {
      this.departments = applications['data'];
      this.loadingFilterDepartments = false;
    }, err => {
      console.log(err);
      this.loadingFilterDepartments = false;
    });
  }

  save()
  {
    this.isSpinning = true;
    var userAccessMapping = []
    this.filterDepartmentValue.map((item) => {
      var v = { "USER_ID": this.USER_ID, "ROLE_ID": this.ROLE_ID, "APPLICATION_ID": this.applicationId, "DEPARTMENT_ID": item, "MODULE_ID": 0, "STATUS": this.STATUS, "CLIENT_ID": this.api.clientId }
      userAccessMapping.push(v)
    })
      console.log(userAccessMapping)
      this.api.AssignApplicationsData(userAccessMapping)
        .subscribe(successCode => {
          if (successCode['code'] == "200") {
            this.message.success("Application Assigned Successfully ...", "");
            this.isSpinning = false;
          }
          else {
            this.message.error("Failed to Assign Applications...", "");
            this.isSpinning = false;
          }
        });
}

userSelectChange(userId:number)
{
    var filterQuery = " AND ROLE_ID=" + this.ROLE_ID + " AND USER_ID= " + userId + " "
    this.api.getAllUserAccessMapping(0, 0, '', '', filterQuery).subscribe(data => {
      console.log(data['data'])
      this.values = ""
      data['data'].forEach(element => {
        this.values = element['DEPARTMENT_ID'] + "," + this.values
      });

      if (this.values.match("undefined")) {
        this.values = this.values.substring(0, this.values.length - 10)
      }
      else {
        this.values = this.values.substring(0, this.values.length - 1)
      }
      if (this.values != []) {
        var departmentIds = this.values.split(',').map(function (item) {
          return parseInt(item, 10);
        });
      }
      this.filterDepartmentValue = departmentIds
      if (this.filterDepartmentValue == [NaN]) {
        this.filterDepartmentValue = undefined
      }
    }, err => {
      console.log(err);
    });
  }
}
