export class Role {
    ID:number;
    CLIENT_ID:number;
    NAME:string;
    DESCRIPTION:string;
    STATUS:boolean;
    PARENT_ROLE_ID:number
    LOGO_URL:string
    MODULE_ID:number
    REQUIRED_APPLICATION_ID:boolean
    REQUIRED_DEPARTMENT_ID:boolean
}
