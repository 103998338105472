export class fileMaster {
    ID: number;
    CLIENT_ID: number;
    FOLDER_ID: any;
    NAME: string;
    CREATED_DATE_TIME: string;
    URL: string;
    TYPE: string;
    SIZE_KB: number;
    OWNER_ID: number;
    TAGS: string;
    DESCRIPTION: string;
    ATTACHMENT_URL = ''
}