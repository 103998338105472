import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { AWSTemplets } from 'src/app/Models/AWSTemplets';
import { ApiService } from 'src/app/Service/api.service';
import { NzNotificationService } from 'ng-zorro-antd';
import { AWSSendMail } from 'src/app/Models/AWSSendMail';
import { Template } from '@angular/compiler/src/render3/r3_ast';
import { DatePipe } from '@angular/common'; 

@Component({
  selector: 'app-awsbulksender',
  templateUrl: './awsbulksender.component.html',
  styleUrls: ['./awsbulksender.component.css'],
  providers: [DatePipe]
})
export class AwsbulksenderComponent implements OnInit {
  @ViewChild('show', { static: false }) d1: ElementRef;
  @Input() drawerClose: Function;
  @Input() data: AWSTemplets;
  isSpinning = false
  passwordVisible = false;
  password: string;
  ROWS_COUNT:number=0;
  SHEET_ID:string="";
  SCHEDULED:boolean=false;
  SCHEDULE_TIME:string="";
  loadingTemplate = false
  templates: AWSTemplets[];
  

  constructor(private api: ApiService, private message: NzNotificationService,private datePipe: DatePipe) {
  }

  ngOnInit() {
    this.loadTemplates();
    
  }
  loadTemplates() {
    this.loadingTemplate = true;
    this.api.getAllAwsTemplates(0, 0, '', '', '').subscribe(data => {
      console.log(data)
      this.templates = data['data'];
      this.loadingTemplate = false;
    }, err => {
      console.log(err);
      //this.loadingForm = false;
    });
  }
  


  close(): void {
    this.drawerClose();
  }

  chng(value):void{
    this.data = this.templates.filter(x => x.ID == value)[0];
    this.d1.nativeElement.innerHTML = this.data.BODY;
  }
 

  sendMail(): void {
    this.SCHEDULE_TIME = this.datePipe.transform(this.SCHEDULE_TIME, "yyyy-MM-dd HH:mm");
    if(this.SHEET_ID=="" || this.ROWS_COUNT==0 )
    {
      this.message.error("Please Fill All Information First", "");
    }
    else
    {
    let mailData = new AWSSendMail();
    mailData.TEMPLATE_ID=this.data.ID;
    mailData.TEMPLATE_NAME=this.data.TEMPLATE_NAME;
    mailData.SHEET_ID=this.SHEET_ID;
    mailData.ROWS_COUNT=this.ROWS_COUNT;
    mailData.START_TIME=this.SCHEDULE_TIME;
    mailData.IS_SCHEDULED=this.SCHEDULED;
    this.api.scheduleMails(mailData).subscribe(data => {
          console.log("success",data);
          this.message.success("Mails Scheduled Successfully...", "");
          this.drawerClose();
          this.isSpinning = false;
     }, err => {
       console.log(err);
     });
    }
  
  }


}