import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-add-new-notification-drawer',
  templateUrl: './add-new-notification-drawer.component.html',
  styleUrls: ['./add-new-notification-drawer.component.css']
})

export class AddNewNotificationDrawerComponent implements OnInit {
  @Input() drawerClose: Function;
  sharingMode = "I";
  USER_IDS = [];
  TITLE = "";
  DESCRIPTION = "";
  employeeList = [];
  userId = Number(this.cookie.get('userId'));
  roleId = Number(this.cookie.get('roleId'));
  orgId = Number(this.cookie.get('orgId'));
  deptId = Number(this.cookie.get('deptId'));
  branchId = Number(this.cookie.get('branchId'));
  designationId = Number(this.cookie.get('designationId'));
  heading = "";
  individualGrid = false;
  deptGrid = false;
  branchGrid = false;
  designationGrid = false;
  entireOrg = false;
  isSpinning = false;
  loadingList: boolean = false;

  constructor(private api: ApiService, private message: NzNotificationService, private cookie: CookieService) { }

  ngOnInit() { }

  reset(myForm: NgForm) {
    myForm.form.reset();
  }

  close(myForm: NgForm): void {
    this.drawerClose();
    this.reset(myForm);
  }

  btnIndividualStatus = false;
  btnDepartmentStatus = false;
  btnBranchStatus = false;
  btnDesignationStatus = false;
  btnEntireOrganisationStatus = false;

  disableRadioButtons() {
    if (this.roleId == 12) {
      this.btnIndividualStatus = true;
      this.btnDepartmentStatus = true;
      this.btnBranchStatus = true;
      this.btnDesignationStatus = true;
      this.btnEntireOrganisationStatus = true;

    } else {
      this.btnIndividualStatus = true;
      this.btnDepartmentStatus = false;
      this.btnBranchStatus = false;
      this.btnDesignationStatus = false;
      this.btnEntireOrganisationStatus = false;

      if (this.deptId == 0) {
        this.btnDepartmentStatus = true;
      }

      if (this.designationId == 0) {
        this.btnDesignationStatus = true;
      }

      if (this.branchId == 0) {
        this.btnBranchStatus = true;
      }
    }
  }

  changeRadioButton(btnValue) {
    this.USER_IDS = [];
    this.employeeList = [];
    this.SELECT_ALL = false;

    if (btnValue == 'I') {
      this.heading = "Select Employee";
      this.individualGrid = true;
      this.deptGrid = false;
      this.branchGrid = false;
      this.designationGrid = false;
      this.entireOrg = false;
      this.loadingList = true;

      this.api.getNotiSubordinateList(this.userId, this.roleId, this.orgId).subscribe(data => {
        if (data['code'] == 200) {
          this.loadingList = false;
          this.employeeList = data['data'];
        }

      }, err => {
        if (err['ok'] == false)
          this.message.error("Server Not Found", "");
      });

    } else if (btnValue == 'D') {
      this.heading = "Select Department";
      this.individualGrid = false;
      this.deptGrid = true;
      this.branchGrid = false;
      this.designationGrid = false;
      this.entireOrg = false;
      this.loadingList = true;

      this.api.getNotiSubordinateDepartmentList(this.userId, this.orgId).subscribe(data => {
        if (data['code'] == 200) {
          this.loadingList = false;
          this.employeeList = data['data'];
        }

      }, err => {
        if (err['ok'] == false)
          this.message.error("Server Not Found", "");
      });

    } else if (btnValue == 'B') {
      this.heading = "Select Branch";
      this.individualGrid = false;
      this.deptGrid = false;
      this.branchGrid = true;
      this.designationGrid = false;
      this.entireOrg = false;
      this.loadingList = true;

      this.api.getNotiSubordinateBranchList(this.userId, this.orgId).subscribe(data => {
        if (data['code'] == 200) {
          this.loadingList = false;
          this.employeeList = data['data'];
        }

      }, err => {
        if (err['ok'] == false)
          this.message.error("Server Not Found", "");
      });

    } else if (btnValue == 'DE') {
      this.heading = "Select Designation";
      this.individualGrid = false;
      this.deptGrid = false;
      this.branchGrid = false;
      this.designationGrid = true;
      this.entireOrg = false;
      this.loadingList = true;

      this.api.getNotiSubordinateDesignationList(this.userId, this.orgId).subscribe(data => {
        if (data['code'] == 200) {
          this.loadingList = false;
          this.employeeList = data['data'];
        }

      }, err => {
        if (err['ok'] == false)
          this.message.error("Server Not Found", "");
      });

    } else if (btnValue == 'EO') {
      this.heading = "Select Employee";
      this.individualGrid = false;
      this.deptGrid = false;
      this.branchGrid = false;
      this.designationGrid = false;
      this.entireOrg = true;
      this.loadingList = false;
    }
  }

  getInitial(empName) {
    let initial: string = empName.charAt(0);
    return initial.trim();
  }

  save(addNew: boolean, myForm: NgForm): void {
    var isOk = true;

    if (!this.entireOrg) {
      if (this.USER_IDS.length == 0) {
        isOk = false;

        if (this.individualGrid) {
          this.message.error("Please Select Valid Employee", "");

        } else if (this.deptGrid) {
          this.message.error("Please Select Valid Department", "");

        } else if (this.branchGrid) {
          this.message.error("Please Select Valid Branch", "");

        } else if (this.designationGrid) {
          this.message.error("Please Select Valid Designation", "");
        }
      }
    }

    if (this.TITLE != undefined) {
      if (this.TITLE.trim() != '') {
        if (!this.api.checkTextBoxIsValid(this.TITLE)) {
          isOk = false;
          this.message.error("Please Enter Valid Notification Title", "");
        }
      } else {
        isOk = false;
        this.message.error("Please Enter Valid Notification Title", "");
      }
    } else {
      isOk = false;
      this.message.error("Please Enter Valid Notification Title", "");
    }

    if (this.DESCRIPTION != undefined) {
      if (this.DESCRIPTION.trim() == '') {
        isOk = false;
        this.message.error("Please Enter Valid Notification Description", "");
      }
    } else {
      isOk = false;
      this.message.error("Please Enter Valid Notification Description", "");
    }

    if (isOk) {
      if (this.individualGrid) {
        this.isSpinning = true;
        var a = [];

        if (this.USER_IDS.length != 0) {
          for (var i = 0; i < this.USER_IDS.length; i++) {
            var obj1 = new Object();
            obj1['EMPLOYEE_ID'] = this.USER_IDS[i];
            a.push(Object.assign({}, obj1));
          }

        } else {
          var obj1 = new Object();
          obj1['EMPLOYEE_ID'] = this.USER_IDS;
          a.push(Object.assign({}, obj1));
        }

        this.api.notiDetailsAddBulk(this.userId, this.TITLE, this.DESCRIPTION, 1, a, this.orgId).subscribe(successCode => {
          if (successCode['code'] == "200") {
            this.isSpinning = false;
            this.message.success("Notification Send Successfully", "");
            this.changeRadioButton('I');
            this.close(myForm);

          } else {
            this.isSpinning = false;
            this.message.error("Failed to Send Notification", "");
          }

        }, err => {
          if (err['ok'] == false) {
            this.isSpinning = false;
            this.message.error("Failed to Send Notification", "");
          }
        });

      } else if (this.deptGrid) {
        this.isSpinning = true;
        var a = [];

        if (this.USER_IDS.length != 0) {
          for (var i = 0; i < this.USER_IDS.length; i++) {
            var obj1 = new Object();
            obj1['DEPARTMENT_ID'] = this.USER_IDS[i];
            a.push(Object.assign({}, obj1));
          }

        } else {
          var obj1 = new Object();
          obj1['DEPARTMENT_ID'] = this.USER_IDS;
          a.push(Object.assign({}, obj1));
        }

        this.api.notiDetailsAddBulk(this.userId, this.TITLE, this.DESCRIPTION, 2, a, this.orgId).subscribe(successCode => {
          if (successCode['code'] == "200") {
            this.isSpinning = false;
            this.message.success("Notification Send Successfully", "");
            this.changeRadioButton('D');
            this.close(myForm);

          } else {
            this.isSpinning = false;
            this.message.error("Failed to Send Notification", "");
          }

        }, err => {
          if (err['ok'] == false) {
            this.isSpinning = false;
            this.message.error("Failed to Send Notification", "");
          }
        });

      } else if (this.branchGrid) {
        this.isSpinning = true;
        var a = [];

        if (this.USER_IDS.length != 0) {
          for (var i = 0; i < this.USER_IDS.length; i++) {
            var obj1 = new Object();
            obj1['BRANCH_ID'] = this.USER_IDS[i];
            a.push(Object.assign({}, obj1));
          }

        } else {
          var obj1 = new Object();
          obj1['BRANCH_ID'] = this.USER_IDS;
          a.push(Object.assign({}, obj1));
        }

        this.api.notiDetailsAddBulk(this.userId, this.TITLE, this.DESCRIPTION, 3, a, this.orgId).subscribe(successCode => {
          if (successCode['code'] == "200") {
            this.isSpinning = false;
            this.message.success("Notification Send Successfully", "");
            this.changeRadioButton('B');
            this.close(myForm);

          } else {
            this.isSpinning = false;
            this.message.error("Failed to Send Notification", "");
          }

        }, err => {
          if (err['ok'] == false) {
            this.isSpinning = false;
            this.message.error("Failed to Send Notification", "");
          }
        });

      } else if (this.designationGrid) {
        this.isSpinning = true;
        var a = [];

        if (this.USER_IDS.length != 0) {
          for (var i = 0; i < this.USER_IDS.length; i++) {
            var obj1 = new Object();
            obj1['DESIGNATION_ID'] = this.USER_IDS[i];
            a.push(Object.assign({}, obj1));
          }

        } else {
          var obj1 = new Object();
          obj1['DESIGNATION_ID'] = this.USER_IDS;
          a.push(Object.assign({}, obj1));
        }

        this.api.notiDetailsAddBulk(this.userId, this.TITLE, this.DESCRIPTION, 4, a, this.orgId).subscribe(successCode => {
          if (successCode['code'] == "200") {
            this.isSpinning = false;
            this.message.success("Notification Send Successfully", "");
            this.changeRadioButton('DE');
            this.close(myForm);

          } else {
            this.isSpinning = false;
            this.message.error("Failed to Send Notification", "");
          }

        }, err => {
          if (err['ok'] == false) {
            this.isSpinning = false;
            this.message.error("Failed to Send Notification", "");
          }
        });

      } else if (this.entireOrg) {
        this.isSpinning = true;
        var a = [];

        var obj1 = new Object();
        obj1['ORG_ID'] = this.orgId;
        a.push(Object.assign({}, obj1));

        this.api.notiDetailsAddBulk(this.userId, this.TITLE, this.DESCRIPTION, 5, a, this.orgId).subscribe(successCode => {
          if (successCode['code'] == "200") {
            this.isSpinning = false;
            this.message.success("Notification Send Successfully", "");
            this.changeRadioButton('EO');
            this.close(myForm);

          } else {
            this.isSpinning = false;
            this.message.error("Failed to Send Notification", "");
          }

        }, err => {
          if (err['ok'] == false) {
            this.isSpinning = false;
            this.message.error("Failed to Send Notification", "");
          }
        });
      }
    }
  }

  SELECT_ALL: boolean = false;

  onSelectAllChecked(switchStatus: boolean) {
    let ids = [];
    if (switchStatus == true) {
      for (var i = 0; i < this.employeeList.length; i++) {
        ids.push(this.employeeList[i]["ID"]);
      }

    } else {
      ids = [];
    }

    this.USER_IDS = ids;
  }
}
