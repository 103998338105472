import { Component, OnInit,Input } from '@angular/core';
import { Smsserver } from 'src/app/Models/smsserver';
import { ApiService } from 'src/app/Service/api.service';
import { NzNotificationService } from 'ng-zorro-antd';

@Component({
  selector: 'app-sms',
  templateUrl: './sms.component.html',
  styleUrls: ['./sms.component.css']
})
export class SmsComponent implements OnInit {

  @Input() drawerClose: Function;
  @Input() data: Smsserver;
  passwordVisible = false;
  password: string;
  isSpinning = false


  constructor(private api: ApiService, private message: NzNotificationService) {
  }

  ngOnInit() {
  }
  


  close(): void {
    this.drawerClose();
  }

  save(addNew: boolean): void {
    this.isSpinning = true;

    if (this.data.ID) {
        this.api.updateSmsServer(this.data)
          .subscribe(successCode => {
            console.log(successCode)
            if (successCode['code'] == "200") {
              this.message.success("SmsServer information updated Successfully...", "");
              if (!addNew)
                this.drawerClose();
              this.isSpinning = false;
            }
            else {
              this.message.error("Failed to update smsServer information...", "");
              this.isSpinning = false;
            }
          });
      }
      else {
        this.api.createSmsServer(this.data)
          .subscribe(successCode => {
            console.log(successCode)
            if (successCode['code'] == "200") {
              
              this.message.success("SmsServer information added successfully...", "");
              if (!addNew)
                this.drawerClose();
              else {
                this.data = new Smsserver();
              }
              this.isSpinning = false;
            }
            else {
              this.message.error("Failed to add smsServer information...", "");
              this.isSpinning = false;
            }
          });
      }
   
  }


}